import {
  Box,
  chakra,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { EmrRoomCard } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { RightArrow } from "icons";
import { formMessages } from "messages";
import React from "react";
import { useState } from "react";
import { Button, InfiniteList, Input, Label, ToolbarHeader } from "shared";
import { debounce } from "throttle-debounce";
import { extractApiErrorMessage, isLastIndex } from "utils";
import { CreateRoomPayload, RoomDto, useCreateRoom, useRooms } from "../api";
import { RoomDetailsModal } from "./RoomDetails";

type RoomListModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const RoomListHeader = ({ onClose }: RoomListModalProps) => {
  return (
    <ModalHeader>
      <ToolbarHeader
        titleText={
          <chakra.span fontSize="17px" fontWeight="600">
            Rooms
          </chakra.span>
        }
        rightButtons={[
          <Button
            key="cancelBtn"
            fontSize="16px"
            fontWeight="500"
            onClick={onClose}
          >
            Close
          </Button>,
        ]}
      />
    </ModalHeader>
  );
};

type RoomItemProps = {
  room: RoomDto;
  showDivider: boolean;
};

const RoomItem = ({ room, showDivider }: RoomItemProps) => {
  const roomDetailsDisclosure = useDisclosure();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        pt={4}
        px={4}
        onClick={roomDetailsDisclosure.onOpen}
        cursor="pointer"
      >
        <Box display="flex" justifyContent="start">
          <Icon as={EmrRoomCard} mr={4} h="24px" w="24px" color="blue" />
          <chakra.span fontSize="17px" fontWeight="500">
            {room.name}
          </chakra.span>
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center">
          <chakra.span fontSize="17px" fontWeight="500" color="gray.700" px={4}>
            {room.beds.length}
          </chakra.span>
          <Icon as={RightArrow} color="gray.450" fontSize="0.875rem" />
        </Box>
      </Box>
      {showDivider && (
        <Divider width={`calc(100% - 56px)`} ml="56px" mt={2} h="0.5px" />
      )}
      <RoomDetailsModal room={{ ...room }} {...roomDetailsDisclosure} />
    </>
  );
};

const RoomListContent = () => {
  const toast = useToast();

  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const onSearch = React.useMemo(() => debounce(1000, setSearch), []);

  const { data, isLoading, fetchNextPage, hasNextPage, error } = useRooms({
    search,
    size: 20,
  });

  const rows = React.useMemo(
    () =>
      data?.pages.reduce<RoomDto[]>(
        (accum, curr) => [...accum, ...curr.data.content],
        []
      ) || [],
    [data]
  );

  const fetchMore = React.useCallback(async () => {
    try {
      await fetchNextPage();
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  }, [fetchNextPage, toast]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  const { mutateAsync: createRoom, isLoading: isCreateRoomLoading } =
    useCreateRoom();

  const handleCreateRoom = async () => {
    try {
      const payload: CreateRoomPayload = {
        room: {
          name: search,
          status: "Open",
        },
        beds: [],
      };
      await createRoom(payload);

      toast({
        status: "success",
        description: formMessages.createSuccess("Room"),
      });
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  const disableAddButton = React.useMemo(() => {
    return (
      !search ||
      rows.some((row) => row.name === search) ||
      isLoading ||
      !!error ||
      isCreateRoomLoading
    );
  }, [search, rows, isLoading, error, isCreateRoomLoading]);

  return (
    <>
      <Label fontSize="15px">Search/Add</Label>
      <Input
        value={searchInput}
        onChange={(e) => {
          onSearch(e.target.value);
          setSearchInput(e.target.value);
        }}
        variant="search"
        bg="white"
        border="1px solid"
        borderColor="gray.450"
      />
      <Flex justifyContent="space-between" mt="20px">
        <Button
          variant="outlineSquared"
          color="gray"
          borderColor="gray.650"
          width="245px"
          borderRadius="10px"
          disabled={!search}
          onClick={() => {
            setSearch("");
            setSearchInput("");
          }}
        >
          Clear Search
        </Button>
        <Button
          variant="outlineSquared"
          width="245px"
          borderRadius="10px"
          disabled={disableAddButton}
          isLoading={isCreateRoomLoading}
          onClick={handleCreateRoom}
        >
          Add New
        </Button>
      </Flex>
      <Box bgColor="white" my={5} pb={4} borderRadius="10px" minH="50px">
        <InfiniteList
          fetchMore={fetchMore}
          hasMore={!!hasNextPage}
          isLoading={isLoading}
          renderRow={(room, index) => (
            <RoomItem room={room} showDivider={!isLastIndex(index, rows)} />
          )}
          rows={rows}
        />
      </Box>
    </>
  );
};

const RoomListModal = (props: RoomListModalProps) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        height="95%"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        <RoomListHeader {...props} />
        <ModalBody p="15px">
          <RoomListContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { RoomListModal };
