import { PaginationPayload, PaginationResponse } from "types";

import { EncounterListItem } from "modules/reception/types";
import { axiosPatientInstance } from "config";
import { useInfiniteQuery } from "react-query";

type UseEncountersProps = PaginationPayload & {
  patientStatus?: string;
  searchDisposition?: boolean;
  search?: string;
};

type UseEncountersResponse = PaginationResponse<EncounterListItem>;

function useEncounters(props: UseEncountersProps) {
  const {
    sort,
    descending,
    page = 1,
    size = 10,
    search,
    patientStatus,
    searchDisposition,
  } = props;

  return useInfiniteQuery(
    [
      "encounters",
      {
        search,
        patientStatus,
        searchDisposition,
        sortDesc: descending || false,
        orderBy: sort || "createdAt",
      },
    ],
    ({ pageParam = page }) =>
      axiosPatientInstance.get<UseEncountersResponse>("v1", {
        params: {
          pageSize: size,
          page: pageParam,
          sortDesc: descending || false,
          orderBy: sort || "createdAt",
          search,
          patientStatus,
          searchDisposition,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
      staleTime: 0,
    }
  );
}

export type { UseEncountersResponse };
export { useEncounters };
