import { axiosChartInstance } from "config";
import { Gender } from "enums";
import {
  ProviderTypes,
  SearchEncountersElement,
} from "modules/open-charts/types";
import { useQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";

type SearchEncountersResponse = PaginationResponse<SearchEncountersElement>;

type EncounterFilter = Partial<{
  firstName: string;
  lastName: string;
  dob: string | Date;
  gender: Gender | null;
  ssn: string;
  mrn: string;
  encounterDate: string | Date;
  dischargeDate: string | Date;
  encounterNumber?: string;
  ProviderId?: string;
}>;

type UseSearchPatientsProps = EncounterFilter &
  PaginationPayload & { providerType?: ProviderTypes };

function UseSearchOpenChartsEncounters(props: UseSearchPatientsProps) {
  const {
    sort,
    descending,
    page = 1,
    size = 10,
    providerType,
    ...filters
  } = props;
  const validFilters = Object.entries(filters).reduce((curr, [k, v]) => {
    if (v) {
      return { ...curr, [k]: v };
    }
    return curr;
  }, {});

  return useQuery(["searchEncounters", props], () =>
    axiosChartInstance.get<SearchEncountersResponse>(
      `v1/open-charts/search/encounters`,
      {
        params: {
          page,
          pageSize: size,
          sortDesc: descending || false,
          orderBy: sort,
          providerType,
          ...validFilters,
        },
      }
    )
  );
}
export { UseSearchOpenChartsEncounters };
export type {
  SearchEncountersResponse,
  UseSearchPatientsProps,
  EncounterFilter,
};
