import { useDisclosure } from "@chakra-ui/react";
import { OrderGroup } from "enums";
import { useOrderChartNotes } from "modules/charts-shared/api";
import { CardProps } from "shared";
import { OrderChartNotesModal } from "../chart-notes";
import {
  ActionBtnsProps,
  ChartSectionHeaderCard,
  NoNotes,
} from "./ChartSectionHeader";

type OrderWithNotes = {
  icon: JSX.Element;
  encounterId: string;
  chartId: string;
  chartCode: string;
  orderType: OrderGroup;
  showNotesBtn?: true;
};
type OrderChartSectionHeaderProps = (OrderWithNotes | NoNotes) &
  ActionBtnsProps &
  CardProps;

function isPropsOrderNoNotes(
  props: OrderChartSectionHeaderProps
): props is NoNotes {
  return (props as NoNotes).showNotesBtn === false;
}

export function OrderChartSectionHeader(props: OrderChartSectionHeaderProps) {
  if (isPropsOrderNoNotes(props)) {
    const { encounterId, chartId, ...rest } = props;
    return <ChartSectionHeaderCard {...rest} />;
  }
  return <OrderChartSectionHeaderWithNotes {...props} />;
}

export function OrderChartSectionHeaderWithNotes(
  props: OrderWithNotes & ActionBtnsProps & CardProps
) {
  const {
    chartId,
    encounterId,
    icon,
    chartCode,
    orderType,
    showNotesBtn = false,
    children,
    ...cardProps
  } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data } = useOrderChartNotes({
    encounterId,
    chartCode,
    orderType,
  });

  const sectionNotesCount = data?.data?.length || 0;

  return (
    <>
      <ChartSectionHeaderCard
        onOpen={onOpen}
        showNotesBtn={showNotesBtn}
        icon={icon}
        sectionNotesCount={sectionNotesCount}
        {...cardProps}
      >
        {children}
      </ChartSectionHeaderCard>
      {isOpen && (
        <OrderChartNotesModal
          isOpen={isOpen}
          onClose={onClose}
          encounterId={encounterId}
          chartCode={chartCode}
          orderType={orderType}
          title={children}
        />
      )}
    </>
  );
}
