import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { OrderFormElementRoute, OrderStatus } from "enums";
import { TriageIVMedicationSection } from "modules/charts-shared";
import { ORDER_STATUS_COLOR } from "modules/orders";
import { ReactNode } from "react";
import { Card } from "shared";
import { MedsAndIvModal } from "./MedsAndIvModals";
import { toDisplay } from "utils/enum-mapper";

type MedsAndIvTypes = "ivStarts" | "ivMedications" | "medications";

type IvAndMedicationsCardProps = {
  item: TriageIVMedicationSection & { index: number };
  onSave: () => void;
};

function IvAndMedicationsCard({ item, onSave }: IvAndMedicationsCardProps) {
  const disclosure = useDisclosure();
  const borderColor = item.status
    ? ORDER_STATUS_COLOR[item.status as OrderStatus]
    : "gray.700";

  const title =
    item.route === "IV"
      ? "IV/Medication Infusion"
      : item.route === "IVP" || item.route === "IVPB"
      ? "IV Push"
      : "Medication";

  const type: MedsAndIvTypes =
    item.route === "IV"
      ? "ivMedications"
      : item.route === "IVP" || item.route === "IVPB"
      ? "ivStarts"
      : "medications";

  return (
    <Card
      w="100%"
      p="10px 20px"
      variant="basic"
      borderLeft="8px solid"
      borderColor={borderColor}
      onClick={() => disclosure.onOpen()}
      cursor="pointer"
    >
      <Flex justify="space-between">
        <Text color="gray">
          {toDisplay(
            "orderFormElementRoute",
            (item.route as OrderFormElementRoute) ?? "PO"
          )}
        </Text>
        {(item.status === "Ordered" || item.status === "Prepared") && (
          <Text color={item.status === "Ordered" ? "blue" : "cyan"}>
            {item.status === "Ordered" ? "Needs Prep" : "Preparing"}
          </Text>
        )}
      </Flex>
      <Text fontWeight="bold">{`${item.medication as string} ${(
        item.solution as string
      ).replace(item.route as string, "")}`}</Text>
      <MedsAndIvModal
        {...disclosure}
        type={type}
        item={item}
        title={title}
        onSave={onSave}
      />
    </Card>
  );
}

type IvAndMedicationsColumnProps = {
  header: ReactNode;
  status: OrderStatus;
  items: (TriageIVMedicationSection & { index: number })[];
  onSave: () => void;
};

function IvAndMedicationsColumn({
  header,
  items,
  onSave,
}: IvAndMedicationsColumnProps) {
  return (
    <Box minW="24%" display="flex" flexDir="column" gap={4}>
      {header}
      {items.map((item, index) => (
        <IvAndMedicationsCard key={index} item={item} onSave={onSave} />
      ))}
    </Box>
  );
}

export { IvAndMedicationsColumn };
