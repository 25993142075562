import {
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useYupValidationResolver } from "hooks";
import { formMessages } from "messages";
import {
  useUpdateOrderNotes,
  useUpdateOrderNotesPayload,
} from "modules/orders/api/mutations";
import { useOrderNotes } from "modules/orders/api/queries";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Card, Loading, ToolbarHeader } from "shared";
import { extractApiErrorMessage } from "utils/helpers";
import * as yup from "yup";

type EditOrderNotesModalProps = {
  orderId: string;
  isOpen: boolean;
  onClose: () => void;
};

type EditOrderNotestModalContentProps = {
  orderId: string;
  onModalClose: () => void;
};

const schema = yup.object({
  notes: yup.string(),
});

function EditOrderNotesModalContent(props: EditOrderNotestModalContentProps) {
  const { orderId, onModalClose } = props;

  const toast = useToast();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: useYupValidationResolver(schema),
    defaultValues: {
      notes: "",
    },
    mode: "onChange",
  });

  const { data, isLoading, error } = useOrderNotes(orderId);

  React.useEffect(() => {
    if (data?.data.notes) setValue("notes", data?.data.notes);
  }, [data, setValue]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  const { mutateAsync: putOrderNotes, isLoading: isPutLoading } =
    useUpdateOrderNotes(orderId);

  const onSubmit: SubmitHandler<useUpdateOrderNotesPayload> = async (
    dataForm
  ) => {
    try {
      const payload: useUpdateOrderNotesPayload = {
        ...dataForm,
      };
      await putOrderNotes({
        ...payload,
      });
      toast({
        status: "success",
        description: formMessages.updateSuccess("Order Notes"),
      });
      onModalClose();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: extractApiErrorMessage(e),
        });
      }
    }
  };

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600">
              Order Notes
            </chakra.span>
          }
          leftButtons={[
            <Button key="cancelBtn" onClick={onModalClose}>
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="doneBtn"
              onClick={handleSubmit(onSubmit)}
              isLoading={isPutLoading || isLoading}
              isDisabled={!isValid}
            >
              Save
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody padding="15px 25px 25px" fontSize="1.0625rem">
        {isLoading || !data ? (
          <Loading padding="16px" minH="100px" />
        ) : (
          <form>
            <Card variant="default" boxShadow="none">
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    rows={3}
                    style={{
                      width: "100%",
                      padding: "8px",
                      borderRadius: "10px",
                      border: "none",
                    }}
                  />
                )}
              />
            </Card>
          </form>
        )}
      </ModalBody>
    </>
  );
}

function EditOrderNotesModal(props: EditOrderNotesModalProps) {
  const { orderId, isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="gray.200" maxWidth="542px">
        {isOpen && (
          <EditOrderNotesModalContent
            onModalClose={onClose}
            orderId={orderId}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { EditOrderNotesModal };
