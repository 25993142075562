import { Box, chakra, Divider, Icon, useToast } from "@chakra-ui/react";
import { EmrInProcess, EmrRegistration } from "@medstonetech/slate-icons";
import { PillsBottle, SquaredChecked } from "icons";
import { formMessages } from "messages";
import { ChartSection, SharedChartSectionHeader } from "modules";
import {
  ChartRouteBaseParams,
  nextRoutesNurse,
  nextRoutesProvider,
  TriageIVMedicationForm,
  TriageIVMedicationSection,
} from "modules/charts-shared";
import { OrderHeaderCard } from "modules/orders";
import {
  useIvAndMedication,
  useUpdateIvAndMedication,
} from "modules/triage/api";
import {
  formToPayload,
  responseToForm,
} from "modules/triage/utils/iv-medications";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { IvAndMedicationsColumn } from "./IvAndMedicationColumn";
import {
  IvMedicationsTypeContent,
  IvStartsTypeContent,
  MedicationTypeContent,
} from "./MedsAndIvModals";

type MedsAndIvTypes = "ivStarts" | "ivMedications" | "medications";

function IvAndMedications(props: { chartCode: string; isReadOnly?: boolean }) {
  const { encounterId = "", "*": chartName = "" } =
    useParams<ChartRouteBaseParams>();
  const toast = useToast();
  const navigate = useNavigate();
  const useFormContext = useForm<TriageIVMedicationForm>({
    defaultValues: {
      ivMedications: [],
      ivStarts: [],
      medications: [],
      treatments: [],
    },
  });
  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext;
  const { data: ivMedicationData, isLoading: getIsLoading } =
    useIvAndMedication(encounterId);
  const { mutateAsync: updateIvMedication, isLoading: updateIsLoading } =
    useUpdateIvAndMedication(encounterId);
  const { chartCode, isReadOnly } = props;

  const [nextRoute, setNextRoute] = useState<string | null>(null);

  const location = useLocation();

  const fromSummary = location.pathname.includes("medical-records");

  useEffect(() => {
    if (ivMedicationData) {
      reset(responseToForm(ivMedicationData.data));
    }
  }, [ivMedicationData, reset]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [nextRoute, navigate, isDirty]);

  if (getIsLoading) {
    return <Loading />;
  }

  const ivStarts = watch("ivStarts");
  const ivMedications = watch("ivMedications");
  const medications = watch("medications");

  const onSubmit = async (formData: TriageIVMedicationForm) => {
    const payload = formToPayload(formData);
    try {
      await updateIvMedication(payload);
      toast({ description: formMessages.updateSuccess("IV and Medications") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const handleSaveAndNext = () => {
    const nextRoutes =
      chartCode[0] === "T" ? nextRoutesNurse : nextRoutesProvider;
    const route = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];
    handleSubmit(onSubmit)().then(() => {
      setNextRoute(`./../${route}`);
    });
  };

  const filteredItems: (TriageIVMedicationSection & { index: number })[] = [
    ...ivStarts.map((x, index) => ({
      ...x,
      index,
    })),
    ...ivMedications.map((x, index) => ({
      ...x,
      index,
    })),
    ...medications.map((x, index) => ({
      ...x,
      index,
    })),
  ];

  return (
    <FormProvider {...useFormContext}>
      <ChartSection
        minWidth="100%"
        onSubmit={handleSubmit(onSubmit)}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          handleSubmit(onSubmit)();
          onComplete();
        }}
      >
        <SharedChartSectionHeader
          icon={<PillsBottle />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Meds & IV {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>

        <Box w="100%" display="flex" justifyContent="space-between">
          <IvAndMedicationsColumn
            header={
              <OrderHeaderCard
                title="Ordered"
                icon={<Icon as={EmrRegistration} />}
                bg="orange"
                counter={
                  filteredItems.filter((x) => x?.status === "Ordered").length
                }
              />
            }
            status="Ordered"
            items={filteredItems.filter(
              (x) => x.status === "Ordered" || x.status === "Prepared"
            )}
            onSave={handleSubmit(onSubmit)}
          />

          <Divider orientation="vertical" minH="90vh" />

          <IvAndMedicationsColumn
            header={
              <OrderHeaderCard
                title="In Process"
                icon={<Icon as={EmrInProcess} />}
                bg="blue"
                counter={
                  filteredItems.filter((x) => x.status === "InProcess").length
                }
              />
            }
            status="InProcess"
            items={filteredItems.filter((x) => x.status === "InProcess")}
            onSave={handleSubmit(onSubmit)}
          />

          <Divider orientation="vertical" minH="90vh" />

          <IvAndMedicationsColumn
            header={
              <OrderHeaderCard
                title="Completed"
                icon={<Icon as={EmrInProcess} />}
                bg="green"
                counter={
                  filteredItems.filter((x) => x.status === "Completed").length
                }
              />
            }
            status="Completed"
            items={filteredItems.filter((x) => x.status === "Completed")}
            onSave={handleSubmit(onSubmit)}
          />

          <Divider orientation="vertical" minH="90vh" />

          <IvAndMedicationsColumn
            header={
              <OrderHeaderCard
                title="Administered"
                icon={<Icon as={SquaredChecked} />}
                bg="purple"
                counter={
                  filteredItems.filter((x) => x.status === "Administered")
                    .length
                }
              />
            }
            status="Administered"
            items={filteredItems.filter((x) => x.status === "Administered")}
            onSave={handleSubmit(onSubmit)}
          />
        </Box>

        {fromSummary &&
          filteredItems.map((x) => {
            const type: MedsAndIvTypes =
              x.route === "IV"
                ? "ivMedications"
                : x.route === "IVP" || x.route === "IVPB"
                ? "ivStarts"
                : "medications";

            const title =
              x.route === "IV"
                ? "IV/Medication Infusion"
                : x.route === "IVP" || x.route === "IVPB"
                ? "IV Push"
                : "Medication";

            return (
              <Box
                justifySelf="center"
                alignItems="center"
                display="flex"
                flexDir="column"
                color="blue"
              >
                <chakra.span fontWeight="600" mb="1.5rem">
                  {title}
                </chakra.span>
                {type === "ivStarts" && (
                  <IvStartsTypeContent item={x} type={type} />
                )}
                {type === "ivMedications" && (
                  <IvMedicationsTypeContent item={x} type={type} />
                )}
                {type === "medications" && (
                  <MedicationTypeContent item={x} type={type} />
                )}
                <Divider my="1.5rem" />
              </Box>
            );
          })}
      </ChartSection>
    </FormProvider>
  );
}

export { IvAndMedications };
