import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S008Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q008.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q008`}
          subsectionLabel="Eye Procedure"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q008`
            )
          }
        >
          Eye Procedure
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q008A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q008A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Anesthesia:"
                name={`${sectionDelimiter}Q008A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="Alcaine"
                name={`${sectionDelimiter}Q008A004`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="Opthaine"
                name={`${sectionDelimiter}Q008A005`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q008A006`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Foreign body removed:"
                type="option"
                name={`${sectionDelimiter}Q008A007`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="with cotton-tip swab"
                type="option"
                name={`${sectionDelimiter}Q008A008`}
              />
              <ChartRowElement
                label="irrigation"
                type="option"
                name={`${sectionDelimiter}Q008A009`}
              />
              <ChartRowElement
                label="needle"
                type="option"
                name={`${sectionDelimiter}Q008A010`}
              />
              <ChartRowElement
                label="burr"
                type="option"
                name={`${sectionDelimiter}Q008A011`}
              />
              <ChartRowElement
                label="residual material after foreign body removal"
                type="option"
                name={`${sectionDelimiter}Q008A012`}
              />
              <ChartRowElement
                label="none"
                type="option"
                name={`${sectionDelimiter}Q008A013`}
              />
              <ChartRowElement
                label="debris"
                type="option"
                name={`${sectionDelimiter}Q008A014`}
              />
              <ChartRowElement
                label="rust ring"
                type="option"
                name={`${sectionDelimiter}Q008A015`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q008A016`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="cornea curettaged for residual material with:"
                name={`${sectionDelimiter}Q008A018`}
                type="option"
              />
              <ChartRowElement
                label="burr"
                fontStyle="italic"
                name={`${sectionDelimiter}Q008A019`}
                type="option"
              />
              <ChartRowElement
                label="drill"
                fontStyle="italic"
                name={`${sectionDelimiter}Q008A020`}
                type="option"
              />
              <ChartRowElement
                label="residual material after foreign body removal"
                name={`${sectionDelimiter}Q008A021`}
                type="option"
              />
              <ChartRowElement
                label="none"
                name={`${sectionDelimiter}Q008A022`}
                type="option"
              />
              <ChartRowElement
                label="debris"
                name={`${sectionDelimiter}Q008A023`}
                type="option"
              />
              <ChartRowElement
                label="rust ring"
                name={`${sectionDelimiter}Q008A024`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q008A025`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Irrigation:"
                type="option"
                name={`${sectionDelimiter}Q008A026`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="L"
                type="option"
                name={`${sectionDelimiter}Q008A027`}
              />
              <ChartRowElement
                label="R"
                type="option"
                name={`${sectionDelimiter}Q008A028`}
              />
              <ChartRowElement
                label="eye(s) with:"
                type="option"
                name={`${sectionDelimiter}Q008A029`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q008A030`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="mL"
                type="option"
                name={`${sectionDelimiter}Q008A031`}
              />
              <ChartRowElement
                label="NS"
                type="option"
                name={`${sectionDelimiter}Q008A032`}
              />
              <ChartRowElement
                label="LR"
                type="option"
                name={`${sectionDelimiter}Q008A033`}
              />
              <ChartRowElement
                label="water"
                type="option"
                name={`${sectionDelimiter}Q008A034`}
              />
              <ChartRowElement
                label="Morgan Lens"
                type="option"
                name={`${sectionDelimiter}Q008A035`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q008A036`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Intraocular Pressure:"
                type="option"
                name={`${sectionDelimiter}Q008A037`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="RIGHT EYE:"
                type="option"
                name={`${sectionDelimiter}Q008A038`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q008A039`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="mm"
                type="option"
                name={`${sectionDelimiter}Q008A040`}
              />
              <ChartRowElement
                label="Hg"
                type="option"
                name={`${sectionDelimiter}Q008A041`}
              />
              <ChartRowElement
                label="LEFT EYE:"
                type="option"
                name={`${sectionDelimiter}Q008A042`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q008A043`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="mm"
                type="option"
                name={`${sectionDelimiter}Q008A044`}
              />
              <ChartRowElement
                label="Hg"
                type="option"
                name={`${sectionDelimiter}Q008A045`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q008A046`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Measured by::"
                type="option"
                name={`${sectionDelimiter}Q008A047`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="schiotz tonometry"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q008A048`}
              />
              <ChartRowElement
                label="tonopen"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q008A049`}
              />
              <ChartRowElement
                label="applanation"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q008A050`}
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q008A051`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S008Procedure };
