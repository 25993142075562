import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

export type SignatureDto = {
  id: string;
  fullName?: string;
  pictureUrl?: string;
  teams?: string;
  teamTypes?: string;
  signature?: string;
};

function useGetUserSignature(userId?: string) {
  return useAxiosQuery(
    ["getUserSignature", { userId }],
    () =>
      axiosIdentityInstance.get<SignatureDto>(
        `/v1/user/signature${userId ? `?userId=${userId}` : ""}`
      ),
    {
      enabled: true,
    }
  );
}

export { useGetUserSignature };
