import { axiosOrderInstance } from "config/axios";
import { useAxiosQuery } from "hooks";
import { Notes } from "modules/orders/types";

function useOrderNotes(orderId: string) {
  return useAxiosQuery(["orderNotes", { orderId }], () =>
    axiosOrderInstance.get<Notes>(`v1/notes/${orderId}`)
  );
}

export { useOrderNotes };
