type PROCEDURE_SELECT_ITEM = {
  id: string;
  label: string;
  value: string;
};

type PROCEDURE_SELECT_ITEM_NO_ID = Omit<PROCEDURE_SELECT_ITEM, "id">;

const PROCEDURES_SELECT_OPTIONS: PROCEDURE_SELECT_ITEM_NO_ID[] = [
  {
    label: "Apgar Score (One Minute)",
    value: "S001",
  },
  {
    label: "Apgar Score (Five Minute)",
    value: "S002",
  },
  {
    label: "Burn Care",
    value: "S003",
  },
  {
    label: "Cardioversion",
    value: "S004",
  },
  {
    label: "Central Line",
    value: "S005",
  },
  {
    label: "Chest Tube Insertion",
    value: "S006",
  },
  {
    label: "Culdocentesis",
    value: "S007",
  },
  {
    label: "Eye Procedure",
    value: "S008",
  },
  {
    label: "Feeding Tube Insertion",
    value: "S009",
  },
  {
    label: "Fracture / Dislocation Care",
    value: "S010",
  },
  {
    label: "Incision & Drainage of Abscess",
    value: "S011",
  },
  {
    label: "Intubation (Long)",
    value: "S012",
  },
  {
    label: "Intubation (Short)",
    value: "S013",
  },
  {
    label: "LP",
    value: "S014",
  },
  {
    label: "Parkland Formula",
    value: "S015",
  },
  {
    label: "Psych Disorder/Overdose",
    value: "S016",
  },
  {
    label: "Recheck Post-Reduction",
    value: "S017",
  },
  {
    label: "Reduction of Shoulder Dislocation",
    value: "S018",
  },
  {
    label: "Reduction / Splinting",
    value: "S019",
  },
  {
    label: "Suture Removal / Wound or Burn Recheck",
    value: "S020",
  },
  {
    label: "Treatment / Procedure",
    value: "S021",
  },
  {
    label: "Treatment / Resuscitation",
    value: "S022",
  },
  {
    label: "Wound Description / Repair",
    value: "S023",
  },
  {
    label: "Vaginal Delivery",
    value: "S024",
  },
  {
    label: "Intraosseous Line",
    value: "S025",
  },
];

export { PROCEDURES_SELECT_OPTIONS };
export type { PROCEDURE_SELECT_ITEM, PROCEDURE_SELECT_ITEM_NO_ID };
