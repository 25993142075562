import {
  Box,
  BoxProps,
  Button,
  HStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import { EditOrderNotesModal } from "./EditOrderNotesModal";
import { useOrderNotes } from "../api";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";

type OrderDetailsEntryProps = Omit<BoxProps, "children"> & {
  label: string;
  value: React.ReactNode;
};

function OrderDetailsEntryTextBox(props: BoxProps) {
  return <Box fontSize="1.0625rem" lineHeight="1.25rem" {...props} />;
}

function OrderDetailsEntryDateBox(props: BoxProps) {
  return (
    <OrderDetailsEntryTextBox
      whiteSpace="pre-line"
      textAlign="right"
      {...props}
    />
  );
}

function OrderDetailsEntry(props: OrderDetailsEntryProps) {
  const { label, value, ...restProps } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="60px"
      minWidth="445px"
      padding="0 20px 0 0"
      {...restProps}
    >
      <Box fontSize="1.0625rem" color="gray.700">
        {label}
      </Box>
      {value}
    </Box>
  );
}

function OrderDetailsNotes(
  props: OrderDetailsEntryProps & { orderId: string }
) {
  const { label, orderId, ...restProps } = props;

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, error } = useOrderNotes(orderId);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <>
      <Box
        height="144px"
        minWidth="445px"
        padding="8px 20px 0 0"
        marginBottom="10px"
        {...restProps}
      >
        <HStack
          justifyContent="space-between"
          marginBottom="10px"
          paddingRight="17px"
        >
          <Box fontSize="1.0625rem" color="gray.700">
            {label}
          </Box>
          <Button
            variant="label"
            color="blue"
            fontSize="1rem"
            minW="0px"
            onClick={onOpen}
            disabled={isLoading || !data}
          >
            Edit
          </Button>
        </HStack>

        {isLoading && !error ? (
          <Loading padding="16px" minH="100px" />
        ) : (
          <Box
            p="4px"
            borderRadius="10px"
            minW="425px"
            bgColor="gray.200"
            border="1px solid"
            borderColor="gray.450"
            h="100px"
          >
            {data?.data.notes ?? ""}
          </Box>
        )}
      </Box>

      <EditOrderNotesModal
        isOpen={isOpen}
        onClose={onClose}
        orderId={orderId}
      />
    </>
  );
}

export type { OrderDetailsEntryProps };
export {
  OrderDetailsEntry,
  OrderDetailsEntryTextBox,
  OrderDetailsEntryDateBox,
  OrderDetailsNotes,
};
