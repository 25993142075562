import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type AddSignaturePayload = {
  signature: string;
  assignTo?: string;
};

function useAddUserSignature(userId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["addUserSignature", { userId }],
    (input: AddSignaturePayload) =>
      axiosIdentityInstance.post(`/v1/user/signature`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getUserSignature", { userId }]);
        queryClient.invalidateQueries(["getUser", { userId }]);
      },
    }
  );
}

export { useAddUserSignature };
