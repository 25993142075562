import * as React from "react";

import { FullPageLoading, UnauthorizedPage } from "pages";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { extractApiErrorData, useEffectOnce } from "utils";

import { Onboarding } from "modules";
import { useCheckWhitelistAccess } from "modules/whitelist/api/queries/useCheckWhitelistAccess";
import { usePermissions } from "contexts";

type RoutesByPermissions = {
  path: string;
  element: JSX.Element;
  canAccess: boolean;
};

const Reception = React.lazy(
  () => import(/* webpackChunkName: "Reception" */ "../../modules/reception")
);

const Charts = React.lazy(
  () => import(/* webpackChunkName: "Charts" */ "../../modules/charts")
);

const InProcess = React.lazy(
  () => import(/* webpackChunkName: "InProcess" */ "../../modules/in-process")
);

const Labs = React.lazy(
  () => import(/* webpackChunkName: "Labs" */ "../../modules/labs")
);

const Radiology = React.lazy(
  () => import(/* webpackChunkName: "Radiology" */ "../../modules/radiology")
);

const Permissions = React.lazy(
  () =>
    import(/* webpackChunkName: "Permissions" */ "../../modules/permissions/")
);

const Audits = React.lazy(
  () => import(/* webpackChunkName: "Audits" */ "../../modules/audits/")
);

const Billings = React.lazy(
  () => import(/* webpackChunkName: "Audits" */ "../../modules/billings/")
);

const StpVip = React.lazy(
  () => import(/* webpackChunkName: "StpVip" */ "../../modules/stp-vip/")
);

const MedicalRecords = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MedicalRecords" */ "../../modules/medical-records/"
    )
);

const OrderList = React.lazy(
  () => import(/* webpackChunkName: "OrderList" */ "../../modules/order-list/")
);

const RadiologyOrderList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RadiologyOrderList" */ "../../modules/order-list/components/RadiologyOrderList"
    )
);

const Trackers = React.lazy(
  () => import(/* webpackChunkName: "Trackers" */ "../../modules/trackers/")
);

const OpenCharts = React.lazy(
  () => import(/* webpackChunkName: "OpenCharts" */ "../../modules/open-charts")
);

const InsurancePayers = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsurancePayers" */ "../../modules/insurance-payers"
    )
);

const RoomList = React.lazy(
  () =>
    import(/* webpackChunkName: "RoomListModal" */ "../../modules/room-list/")
);

const RoomManagement = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RoomManagementModal" */ "../../modules/room-management/"
    )
);

const Whitelist = React.lazy(
  () => import(/* webpackChunkName: "Whitelist" */ "../../modules/whitelist")
);

const Integrations = React.lazy(
  () =>
    import(/* webpackChunkName: "Integrations" */ "../../modules/integrations")
);

const DuplicatePatients = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Duplicate Patients" */ "../../modules/duplicate-patients"
    )
);

const Faq = React.lazy(
  () => import(/* webpackChunkName: "Faq" */ "../../modules/faq")
);

const TreatmentList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TreamentList" */ "../../modules/treatment-list/"
    )
);

function AuthenticatedRoutes() {
  const { scope } = usePermissions();
  const navigate = useNavigate();

  const { data: isWhitelisted, mutateAsync: check } = useCheckWhitelistAccess();

  useEffectOnce(() => {
    const handleRetrieveIpAddress = async () => {
      try {
        const response = await fetch("https://geolocation-db.com/json/");
        const data: { IPv4: string } = await response.json();
        await check(data?.IPv4);
      } catch (error) {
        extractApiErrorData(error);
      }
    };

    handleRetrieveIpAddress();
  });

  const ROUTES_BY_PERMISSIONS: RoutesByPermissions[] = [
    {
      path: "/reception/*",
      element: <Reception />,
      canAccess: scope("reception").isAccessible,
    },
    {
      path: "/in-process/*",
      element: <InProcess />,
      canAccess: scope("inprocess").isAccessible,
    },
    {
      path: "/in-process/charts/:encounterId/*",
      element: <Charts />,
      canAccess: scope("inprocess").isAccessible,
    },
    {
      path: "/audits/charts/:encounterId/*",
      element: <Charts />,
      canAccess: scope("audits").isAccessible,
    },
    {
      path: "/labs/*",
      element: <Labs />,
      canAccess: scope("labs").isAccessible,
    },
    {
      path: "/labs/charts/:encounterId/*",
      element: <Charts />,
      canAccess: scope("labs").isAccessible,
    },
    {
      path: "/radiology/*",
      element: <Radiology />,
      canAccess: scope("radiology").isAccessible,
    },
    {
      path: "/radiology/charts/:encounterId/*",
      element: <Charts />,
      canAccess: scope("radiology").isAccessible,
    },
    {
      path: "/permissions/*",
      element: <Permissions />,
      canAccess: scope("permission").isAccessible,
    },
    {
      path: "/audits/*",
      element: <Audits />,
      canAccess: scope("audits").isAccessible,
    },
    {
      path: "/stp-vip/*",
      element: <StpVip />,
      canAccess: scope("stpvip").isAccessible,
    },
    {
      path: "/medical-records/*",
      element: <MedicalRecords />,
      canAccess: scope("medicalrecords").isAccessible,
    },
    {
      path: "/open-charts/*",
      element: <OpenCharts />,
      canAccess: scope("opencharts").isAccessible,
    },
    {
      path: "/open-charts/charts/:encounterId/*",
      element: <Charts />,
      canAccess: scope("opencharts").isAccessible,
    },
    {
      path: "/billings/*",
      element: <Billings />,
      canAccess: scope("billings").isAccessible,
    },
    {
      path: "/billings/charts/:encounterId/*",
      element: <Charts />,
      canAccess: scope("billings").isAccessible,
    },
    {
      path: "/insurance-payers/*",
      element: <InsurancePayers />,
      canAccess: scope("insurancepayers").isAccessible,
    },
    {
      path: "/whitelist/*",
      element: <Whitelist />,
      canAccess: scope("insurancepayers").isAccessible,
    },
    {
      path: "/integrations/*",
      element: <Integrations />,
      canAccess: scope("integrations").isAccessible,
    },
    {
      path: "/duplicate-patients/*",
      element: <DuplicatePatients />,
      canAccess: scope("duplicatepatients").isAccessible,
    },
  ];

  if (typeof isWhitelisted?.data !== "undefined" && !isWhitelisted?.data)
    return (
      <React.Suspense fallback={<FullPageLoading />}>
        <Routes>
          <Route path="*" element={<UnauthorizedPage />} />
        </Routes>
      </React.Suspense>
    );

  return (
    <React.Suspense fallback={<FullPageLoading />}>
      <Routes>
        <Route path="/home" element={<Onboarding />}>
          <Route path="labs-list" element={<OrderList type="Lab" />} />
          <Route path="radiology-list" element={<RadiologyOrderList />} />
          <Route
            path="medication-list"
            element={<OrderList type="Medication" />}
          />
          <Route path="logs" element={<Trackers />} />
          <Route
            path="room-list"
            element={
              <RoomList
                isOpen
                onClose={() => {
                  navigate("/home", { replace: true });
                }}
              />
            }
          />
          <Route
            path="room-management"
            element={
              <RoomManagement
                isOpen
                onClose={() => {
                  navigate("/home", { replace: true });
                }}
              />
            }
          />
          <Route
            path="faq"
            element={
              <Faq
                isOpen
                onClose={() => {
                  navigate("/home", { replace: true });
                }}
              />
            }
          />
          <Route path="treatments-list" element={<TreatmentList />} />
        </Route>
        {ROUTES_BY_PERMISSIONS.map((route) => (
          <Route
            path={route.path}
            element={route.canAccess ? route.element : <Navigate to="/home" />}
            key={route.path}
          />
        ))}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </React.Suspense>
  );
}

export { AuthenticatedRoutes };
