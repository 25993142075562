import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C036x";

function T36ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      getValues,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="CV"
            >
              CV
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Acute MI:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                />
                <ChartRowElement
                  label="STEMI"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="NSTEMI"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="anterior"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="inferior"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="lateral"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="post"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Angina:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                />
                <ChartRowElement
                  label="stable"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unstable"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Aortic dissection:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                />
                <ChartRowElement
                  label="thoracic"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Aortic aneurysm:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                />
                <ChartRowElement
                  label="thoracic"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abd"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ rupture"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ischemic chest pain"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ischemic colitis"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Mesenteric ischemia:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="GI"
            >
              GI
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Amebiasis, acute"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Appendictis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A063`}
                />
                <ChartRowElement
                  label="acute:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A064`}
                />
                <ChartRowElement
                  label="w/ peritonitis:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A065`}
                />
                <ChartRowElement
                  label="local"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="general"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bowel obstruction"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Chrohn's dz:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A066`}
                />
                <ChartRowElement
                  label="small bowel"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="large bowel"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A067`}
                />
                <ChartRowElement
                  label="bleeding"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="fistula"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="obstruction"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abscess"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Clostridium difficile enterocolitis"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Constipation"
                  name={`Q003.${chartCode}${sectionId}Q003A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Diverticulitis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A068`}
                />
                <ChartRowElement
                  label="small bowel"
                  name={`Q003.${chartCode}${sectionId}Q003A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="large bowel"
                  name={`Q003.${chartCode}${sectionId}Q003A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A069`}
                />
                <ChartRowElement
                  label="bleeding"
                  name={`Q003.${chartCode}${sectionId}Q003A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="perf"
                  name={`Q003.${chartCode}${sectionId}Q003A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abscess"
                  name={`Q003.${chartCode}${sectionId}Q003A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Enteritis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A070`}
                />
                <ChartRowElement
                  label="viral"
                  name={`Q003.${chartCode}${sectionId}Q003A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bacterial"
                  name={`Q003.${chartCode}${sectionId}Q003A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Fecal Impaction"
                  name={`Q003.${chartCode}${sectionId}Q003A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Gastritis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A071`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q003.${chartCode}${sectionId}Q003A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  bleeding"
                  name={`Q003.${chartCode}${sectionId}Q003A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alcoholic"
                  name={`Q003.${chartCode}${sectionId}Q003A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Gastroenteritis"
                  name={`Q003.${chartCode}${sectionId}Q003A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="infectious"
                  name={`Q003.${chartCode}${sectionId}Q003A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral"
                  name={`Q003.${chartCode}${sectionId}Q003A036`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A037`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="GERD"
                  name={`Q003.${chartCode}${sectionId}Q003A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  esophagitis"
                  name={`Q003.${chartCode}${sectionId}Q003A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A040`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Irritable bowel"
                  name={`Q003.${chartCode}${sectionId}Q003A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ diarrhea"
                  name={`Q003.${chartCode}${sectionId}Q003A042`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A043`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Paralytic ileus"
                  name={`Q003.${chartCode}${sectionId}Q003A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Peptic ulcer dz:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A072`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q003.${chartCode}${sectionId}Q003A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  perforation"
                  name={`Q003.${chartCode}${sectionId}Q003A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/  hemorrhage"
                  name={`Q003.${chartCode}${sectionId}Q003A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A049`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Perforated intestine"
                  name={`Q003.${chartCode}${sectionId}Q003A050`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A051`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ulcerative colitis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A073`}
                />
                <ChartRowElement
                  label="involving:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A074`}
                />
                <ChartRowElement
                  label="rectum"
                  name={`Q003.${chartCode}${sectionId}Q003A052`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sigmoid"
                  name={`Q003.${chartCode}${sectionId}Q003A053`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="colon"
                  name={`Q003.${chartCode}${sectionId}Q003A054`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A075`}
                />
                <ChartRowElement
                  label="bleeding"
                  name={`Q003.${chartCode}${sectionId}Q003A055`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="fistula"
                  name={`Q003.${chartCode}${sectionId}Q003A056`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="obstruction"
                  name={`Q003.${chartCode}${sectionId}Q003A057`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="abscess"
                  name={`Q003.${chartCode}${sectionId}Q003A058`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A059`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Volvulus"
                  name={`Q003.${chartCode}${sectionId}Q003A060`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A061`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A062`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="GU"
            >
              GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Ovarian cyst:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A035`}
                />
                <ChartRowElement
                  label="follicular"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="simple"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="PID:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A036`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="GC"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="chlamydia"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pregnancy:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A037`}
                />
                <ChartRowElement
                  label="1st"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="2nd"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="3rd"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="trimester"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="+preg test"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ectopic-tubal"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="labor:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A038`}
                />
                <ChartRowElement
                  label="preterm"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="term"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="false:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A039`}
                />
                <ChartRowElement
                  label="<37 wk"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label=">37 wk"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pyelonephritis:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A040`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Torsion:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A041`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="testicular"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="ovarian"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Ureterolithiasis"
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="location:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A042`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="UTI:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A043`}
                />
                <ChartRowElement
                  label="cystitis"
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute"
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ hematuria"
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Liver / GB / Pancreas"
            >
              Liver / GB / Pancreas
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Biliary colic:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A020`}
                />
                <ChartRowElement
                  label="w/ gallstones"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cholecystitis:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A021`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A022`}
                />
                <ChartRowElement
                  label="gallstones"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="obstruction"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hepatitis:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A023`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A024`}
                />
                <ChartRowElement
                  label="A"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="B"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="C"
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alcoholic"
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="drug induced"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pancreatitis:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A025`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="idiopathic"
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="biliary"
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alcoholic"
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A019`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Dehydration"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="DKA:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A038`}
                />
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A039`}
                />
                <ChartRowElement
                  label="type 1 diabetes"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="coma"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Drug reaction - adverse:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A040`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypovolemia"
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypotension:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A041`}
                />
                <ChartRowElement
                  label="orthostatic"
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ hypovolemia"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Meningitis / Encephalitis"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="aseptic"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bacterial"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="organism:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A042`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Peritonitis, acute"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumonia:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A043`}
                />
                <ChartRowElement
                  label="interstitial"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="atypical"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bronchopneumonia"
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="lobar"
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="aspiration"
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A044`}
                />
                <ChartRowElement
                  label="RSV"
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="Influenza:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A045`}
                />
                <ChartRowElement
                  label="A"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="B"
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="bacterial:"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A046`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="SIRS"
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Sepsis, severe"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q006.${chartCode}${sectionId}Q006A047`}
                />
                <ChartRowElement
                  label="hypotension"
                  name={`Q006.${chartCode}${sectionId}Q006A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="shock"
                  name={`Q006.${chartCode}${sectionId}Q006A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="DIC"
                  name={`Q006.${chartCode}${sectionId}Q006A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alt. mental"
                  name={`Q006.${chartCode}${sectionId}Q006A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hypoxia"
                  name={`Q006.${chartCode}${sectionId}Q006A034`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="resp. distress"
                  name={`Q006.${chartCode}${sectionId}Q006A035`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A037`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Abd pain:"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A026`}
                />
                <ChartRowElement
                  label="LUQ"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RUQ"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="LLQ"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="RLQ"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute abd"
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q007.${chartCode}${sectionId}Q007A027`}
                />
                <ChartRowElement
                  label="rebound"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="tenderness"
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Fever"
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Flank pain"
                  name={`Q007.${chartCode}${sectionId}Q007A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="L"
                  name={`Q007.${chartCode}${sectionId}Q007A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="R"
                  name={`Q007.${chartCode}${sectionId}Q007A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="acute"
                  name={`Q007.${chartCode}${sectionId}Q007A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A016`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q007.${chartCode}${sectionId}Q007A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A018`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q007.${chartCode}${sectionId}Q007A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A020`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Nausea"
                  name={`Q007.${chartCode}${sectionId}Q007A021`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Vomiting"
                  name={`Q007.${chartCode}${sectionId}Q007A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Diarrhea"
                  name={`Q007.${chartCode}${sectionId}Q007A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A024`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A025`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T36ClinicalImpression };
