import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S025Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q025.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q025`}
          subsectionLabel="Intraosseous Line"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q025`
            )
          }
        >
          Intraosseous Line
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q025A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q025A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Intraosseus Line placed:"
                name={`${sectionDelimiter}Q025A003`}
                type="option"
              />
              <ChartRowElement type="empty" />c
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q025A004`}
                w="220px"
                type="input"
              />
              <ChartRowElement
                label="gauge"
                name={`${sectionDelimiter}Q025A005`}
                type="option"
                fontStyle="italic"
              />
              <ChartRowElement
                label="fr line"
                name={`${sectionDelimiter}Q025A006`}
                type="option"
                fontStyle="italic"
              />
              <ChartRowElement
                label="Betadine prep"
                name={`${sectionDelimiter}Q025A007`}
                type="option"
                fontStyle="italic"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q025A008`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Anesthesia:"
                name={`${sectionDelimiter}Q025A009`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q025A010`}
                w="220px"
                type="input"
              />
              <ChartRowElement
                label="mL"
                name={`${sectionDelimiter}Q025A011`}
                type="option"
              />
              <ChartRowElement
                label="local"
                name={`${sectionDelimiter}Q025A012`}
                type="option"
              />
              <ChartRowElement
                label="lidocaine"
                name={`${sectionDelimiter}Q025A013`}
                type="option"
              />
              <ChartRowElement
                label="bupivacaine"
                name={`${sectionDelimiter}Q025A014`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q025A015`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q025A016`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S025Procedure };
