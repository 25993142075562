import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseGetUserDetailsResponse = {
  id: string;
  fullName: string;
  teams: string;
  teamTypes: string;
  pictureUrl: string;
  signature?: string;
};

function useGetUserDetails(
  userId: string,
  options?: { enabled: boolean; currentUser?: boolean }
) {
  const isCurrentUser = !!options?.currentUser;
  const staleTime = isCurrentUser ? { staleTime: 10 * 60 * 1000 } : {};

  return useAxiosQuery(
    ["getUserDetails", { userId }],
    () =>
      axiosIdentityInstance.get<UseGetUserDetailsResponse>(
        `/v1/user/${userId}/details`
      ),
    { ...options, ...staleTime }
  );
}

export type { UseGetUserDetailsResponse };
export { useGetUserDetails };
