import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";

function S016Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q016.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q016`}
          subsectionLabel="Psych Disorder/Overdose"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q016`
            )
          }
        >
          Psych Disorder/Overdose
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="Restraints."
              name={`${sectionDelimiter}Q016A001`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q016A002`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="Intubated"
              name={`${sectionDelimiter}Q016A003`}
              type="option"
            />
            <ChartRowElement
              label="RSI"
              name={`${sectionDelimiter}Q016A004`}
              type="option"
            />
            <ChartRowElement
              label="nasal"
              name={`${sectionDelimiter}Q016A005`}
              type="option"
            />
            <ChartRowElement
              label="oral"
              name={`${sectionDelimiter}Q016A006`}
              type="option"
            />
            <ChartRowElement
              label="#"
              name={`${sectionDelimiter}Q016A007`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q016A008`}
              type="input"
              w="350px"
            />
            <ChartRowElement
              label="ET tube"
              name={`${sectionDelimiter}Q016A009`}
              type="option"
            />
            <ChartRowElement
              label="by ED physician"
              name={`${sectionDelimiter}Q016A010`}
              type="option"
            />
            <ChartRowElement
              label="breah sounds equal"
              fontStyle="italic"
              name={`${sectionDelimiter}Q016A011`}
              type="option"
            />
            <ChartRowElement
              label="confirmed with bulb aspiration"
              fontStyle="italic"
              name={`${sectionDelimiter}Q016A012`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q016A013`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="Gastric lavage"
              name={`${sectionDelimiter}Q016A014`}
              type="option"
            />
            <ChartRowElement
              label="pill fragments recovered"
              fontStyle="italic"
              name={`${sectionDelimiter}Q016A015`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q016A016`}
              type="input"
            />
          </ChartRow>

          <ChartRow>
            <ChartRowElement
              label="Charcoal"
              name={`${sectionDelimiter}Q016A017`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q016A018`}
              type="input"
              w="200px"
            />
            <ChartRowElement
              label="gm given"
              fontStyle="italic"
              name={`${sectionDelimiter}Q016A019`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q016A020`}
              type="input"
            />
          </ChartRow>

          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q016A021`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S016Procedure };
