import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const RequestDisposition = createIcon({
  path: (
    <path
      d="M4.21639 24C1.43974 24 0 22.5731 0 19.8222V4.17783C0 1.42689 1.43974 0 4.21639 0H19.7836C22.5603 0 24 1.42689 24 4.17783V19.8222C24 22.5603 22.5603 24 19.7836 24H4.21639ZM8.6256 17.9325H11.9936C15.76 17.9325 17.8811 15.7729 17.8811 11.865C17.8811 7.99572 15.7858 5.8361 11.9936 5.8361H8.6256C7.75147 5.8361 7.30155 6.40171 7.30155 7.30155V16.4799C7.30155 17.3798 7.75147 17.9325 8.6256 17.9325ZM9.76968 15.9786V7.79004H11.6593C14.0375 7.79004 15.2973 9.20407 15.2973 11.8907C15.2973 14.6288 14.0503 15.9786 11.6593 15.9786H9.76968Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 24",
});

export { RequestDisposition };
