import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S019Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q019.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q019`}
          subsectionLabel="Reduction / Splinting"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q019`
            )
          }
        >
          Reduction / Splinting
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q019A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q019A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Procedural Sedation:"
                name={`${sectionDelimiter}Q019A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="lidocaine"
                name={`${sectionDelimiter}Q019A004`}
                type="option"
              />
              <ChartRowElement
                label="bupivacaine"
                name={`${sectionDelimiter}Q019A005`}
                type="option"
              />
              <ChartRowElement
                label="reduced with manipulation"
                name={`${sectionDelimiter}Q019A006`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A007`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Applied:"
                name={`${sectionDelimiter}Q019A008`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="splint:"
                name={`${sectionDelimiter}Q019A009`}
                type="option"
              />
              <ChartRowElement
                label="stirrup"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A010`}
                type="option"
              />
              <ChartRowElement
                label="posterior"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A011`}
                type="option"
              />
              <ChartRowElement
                label="OCL"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A012`}
                type="option"
              />
              <ChartRowElement
                label="Ortho-glass"
                name={`${sectionDelimiter}Q019A013`}
                type="option"
              />
              <ChartRowElement
                label="Plaster"
                name={`${sectionDelimiter}Q019A014`}
                type="option"
              />
              <ChartRowElement
                label="neoprene"
                name={`${sectionDelimiter}Q019A015`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A016`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="ace wrap"
                name={`${sectionDelimiter}Q019A017`}
                type="option"
              />
              <ChartRowElement
                label="ace tape"
                name={`${sectionDelimiter}Q019A018`}
                type="option"
              />
              <ChartRowElement
                label="boot orthosis"
                name={`${sectionDelimiter}Q019A019`}
                type="option"
              />
              <ChartRowElement
                label="crutches"
                name={`${sectionDelimiter}Q019A020`}
                type="option"
              />
              <ChartRowElement
                label="post-op shoe"
                name={`${sectionDelimiter}Q019A021`}
                type="option"
              />
              <ChartRowElement
                label="air cast"
                name={`${sectionDelimiter}Q019A022`}
                type="option"
              />
              <ChartRowElement
                label="Digital Block:"
                name={`${sectionDelimiter}Q019A023`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A024`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="toe"
                name={`${sectionDelimiter}Q019A025`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A026`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Applied by:"
                name={`${sectionDelimiter}Q019A027`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="Ed Physician"
                name={`${sectionDelimiter}Q019A028`}
                type="option"
              />
              <ChartRowElement
                label="MLP"
                name={`${sectionDelimiter}Q019A029`}
                type="option"
              />
              <ChartRowElement
                label="Tech"
                name={`${sectionDelimiter}Q019A030`}
                type="option"
              />
              <ChartRowElement
                label="Nurse"
                name={`${sectionDelimiter}Q019A031`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A032`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Examined post splint application:"
                name={`${sectionDelimiter}Q019A033`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="neuro"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A034`}
                type="option"
              />
              <ChartRowElement
                label="vasc"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A035`}
                type="option"
              />
              <ChartRowElement
                label="intact"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A036`}
                type="option"
              />
              <ChartRowElement
                label="Good alignment"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A037`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A038`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Foreign body removed:"
                name={`${sectionDelimiter}Q019A039`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="with forceps"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A040`}
                type="option"
              />
              <ChartRowElement
                label="with incision"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A041`}
                type="option"
              />
              <ChartRowElement
                label="with exploration"
                fontStyle="italic"
                name={`${sectionDelimiter}Q019A042`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A043`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Arthrocentesis:"
                name={`${sectionDelimiter}Q019A044`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="betadine prep:"
                name={`${sectionDelimiter}Q019A045`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A046`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="% lidocaine"
                name={`${sectionDelimiter}Q019A047`}
                type="option"
              />
              <ChartRowElement
                label="epi"
                name={`${sectionDelimiter}Q019A049`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A050`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="mL"
                name={`${sectionDelimiter}Q019A051`}
                type="option"
              />
              <ChartRowElement
                label="serous"
                name={`${sectionDelimiter}Q019A052`}
                type="option"
              />
              <ChartRowElement
                label="serosanguinous"
                name={`${sectionDelimiter}Q019A053`}
                type="option"
              />
              <ChartRowElement
                label="bloody"
                name={`${sectionDelimiter}Q019A054`}
                type="option"
              />
              <ChartRowElement
                label="fluid removed"
                name={`${sectionDelimiter}Q019A055`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q019A056`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q019A057`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S019Procedure };
