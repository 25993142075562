import { Icon } from "@chakra-ui/react";
import { EmrActions } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules";
import {
  ACTIONS_COLUMNS_GAP,
  ACTIONS_ROW_TEMPLATE_COLUMNS_3,
  ACTIONS_ROW_TEMPLATE_COLUMNS_4,
  CONTAINER_PADDING_2,
} from "modules/triage/shared/actions/constants";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import { useTriageChartActionsForm } from "../../hooks";
import { ActionsHeaderDescription } from "../../shared";

const triageChartCode = "T013x";
const sectionId = "SACT";

function N13Actions() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartActionsForm({
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:actions").isEditable;

  const { getValues } = useFormReturn;
  const initialActionsSections = getValues("initialActionsSections");
  const actionsSections = getValues("actionsSections");

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrActions} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Actions {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={false}
              encounterId={encounterId}
              chartId={triageChartCode}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Initial Actions"
            >
              Initial Actions
            </ChartSubsectionHeader>
          }
          content={<ActionsHeaderDescription />}
        />
        <ChartSubsection
          content={
            <>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[0] &&
                          initialActionsSections[0].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[0] &&
                          initialActionsSections[0].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name={`initialActionsSections[0].${triageChartCode}${sectionId}Q001A000`}
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="ID"
                      name={`initialActionsSections[0].${triageChartCode}${sectionId}Q001A001`}
                    />
                    <ChartRowElement
                      label="*"
                      type="label"
                      pl="unset"
                      ml="8px"
                      marginInline="unset"
                      color="red"
                      fontWeight="600"
                      justifyContent="flex-start"
                    />
                    <ChartRowElement
                      pl="20px"
                      type="option"
                      label="Allergy band"
                      name={`initialActionsSections[0].${triageChartCode}${sectionId}Q001A002`}
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="applied"
                      fontStyle="italic"
                      name={`initialActionsSections[0].${triageChartCode}${sectionId}Q001A003`}
                    />
                    <ChartRowElement
                      label="*"
                      type="label"
                      pl="unset"
                      ml="8px"
                      marginInline="unset"
                      color="red"
                      fontWeight="600"
                      justifyContent="flex-start"
                    />
                    <ChartRowElement
                      type="option"
                      label="verified"
                      fontStyle="italic"
                      name={`initialActionsSections[0].${triageChartCode}${sectionId}Q001A004`}
                    />
                    <ChartRowElement
                      label="*"
                      type="label"
                      pl="unset"
                      ml="8px"
                      marginInline="unset"
                      color="red"
                      fontWeight="600"
                      justifyContent="flex-start"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[5] &&
                          initialActionsSections[5].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[5] &&
                          initialActionsSections[5].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="initialActionsSections[5].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="infectious dz info discussed w/"
                      name="initialActionsSections[5].T013xSASSQ001A023"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="patient"
                      name="initialActionsSections[5].T013xSASSQ001A024"
                    />
                    <ChartRowElement
                      type="option"
                      label="family"
                      name="initialActionsSections[5].T013xSASSQ001A025"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[1] &&
                          initialActionsSections[1].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[1] &&
                          initialActionsSections[1].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="initialActionsSections[1].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="disrobed"
                      name="initialActionsSections[1].T013xSASSQ001A005"
                    />
                    <ChartRowElement
                      type="option"
                      label="gowned"
                      name="initialActionsSections[1].T013xSASSQ001A006"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="blanket provided"
                      name="initialActionsSections[1].T013xSASSQ001A007"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[2] &&
                          initialActionsSections[2].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[2] &&
                          initialActionsSections[2].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="initialActionsSections[2].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="bed low position"
                      name="initialActionsSections[2].T013xSASSQ001A008"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="side rails up"
                      name="initialActionsSections[2].T013xSASSQ001A009"
                    />
                    <ChartRowElement
                      type="option"
                      label="x 1"
                      name="initialActionsSections[2].T013xSASSQ001A010"
                    />
                    <ChartRowElement
                      type="option"
                      label="x 2"
                      name="initialActionsSections[2].T013xSASSQ001A011"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[3] &&
                          initialActionsSections[3].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[3] &&
                          initialActionsSections[3].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="initialActionsSections[3].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="call light in reach"
                      name="initialActionsSections[3].T013xSASSQ001A012"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="head of bed elevated"
                      name="initialActionsSections[3].T013xSASSQ001A013"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[4] &&
                          initialActionsSections[4].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[4] &&
                          initialActionsSections[4].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="initialActionsSections[4].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="held by parent"
                      name="initialActionsSections[4].T013xSASSQ001A014"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="caregiver"
                      name="initialActionsSections[4].T013xSASSQ001A015"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_3}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (initialActionsSections[6] &&
                          initialActionsSections[6].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (initialActionsSections[6] &&
                          initialActionsSections[6].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="initialActionsSections[6].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="narcotic speech given"
                      name="initialActionsSections[6].T013xSASSQ001A016"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="medication speech given"
                      name="initialActionsSections[6].T013xSASSQ001A017"
                    />
                    <ChartRowElement type="empty" />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={false}
              encounterId={encounterId}
              chartId={triageChartCode}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Actions"
            >
              Actions
            </ChartSubsectionHeader>
          }
          content={<ActionsHeaderDescription />}
        />

        <ChartSubsection
          content={
            <>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[0] &&
                          actionsSections[0].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[0] &&
                          actionsSections[0].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[0].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="agency notified"
                      name="actionsSections[0].T013xSASSQ002A001"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[0].T013xSASSQ002A026"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[7] &&
                          actionsSections[7].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[7] &&
                          actionsSections[7].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[7].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="hemoccult:"
                      name="actionsSections[7].T013xSASSQ002A027"
                    />
                    <ChartRowElement
                      type="option"
                      label="positive"
                      fontStyle="italic"
                      name="actionsSections[7].T013xSASSQ002A028"
                    />
                    <ChartRowElement
                      type="option"
                      label="negative"
                      fontStyle="italic"
                      name="actionsSections[7].T013xSASSQ002A029"
                    />
                    <ChartRowElement
                      type="option"
                      label="gastroccult:"
                      name="actionsSections[7].T013xSASSQ002A030"
                    />
                    <ChartRowElement
                      type="option"
                      label="positive"
                      fontStyle="italic"
                      name="actionsSections[7].T013xSASSQ002A031"
                    />
                    <ChartRowElement
                      type="option"
                      label="negative"
                      fontStyle="italic"
                      name="actionsSections[7].T013xSASSQ002A032"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[7].T013xSASSQ002A033"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[1] &&
                          actionsSections[1].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[1] &&
                          actionsSections[1].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[1].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="cardiac monitor"
                      name="actionsSections[1].T013xSASSQ002A002"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[1].T013xSASSQ002A034"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent
                padding={CONTAINER_PADDING_2}
                whiteSpace="nowrap"
              >
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[2] &&
                          actionsSections[2].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[2] &&
                          actionsSections[2].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[2].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="pulse oximeter"
                      name="actionsSections[2].T013xSASSQ002A003"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[2].T013xSASSQ002A004"
                      size="sm"
                      inputType="number"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[2].T013xSASSQ002A016"
                      label="%"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[2].T013xSASSQ002A017"
                      label="RA/"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[2].T013xSASSQ002A005"
                      size="md"
                      inputType="number"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[2].T013xSASSQ002A018"
                      label="L"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[2].T013xSASSQ002A035"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[3] &&
                          actionsSections[3].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[3] &&
                          actionsSections[3].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[3].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="O2"
                      name="actionsSections[3].T013xSASSQ002A006"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[3].T013xSASSQ002A007"
                      size="sm"
                      inputType="number"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[3].T013xSASSQ002A019"
                      label="L"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[3].T013xSASSQ002A020"
                      label="via"
                    />
                    <ChartRowElement
                      type="option"
                      label="NC"
                      name="actionsSections[3].T013xSASSQ002A008"
                    />
                    <ChartRowElement
                      type="option"
                      label="mask"
                      name="actionsSections[3].T013xSASSQ002A009"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[3].T013xSASSQ002A036"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[4] &&
                          actionsSections[4].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[4] &&
                          actionsSections[4].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[4].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="bedside glucose"
                      name="actionsSections[4].T013xSASSQ002A010"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[4].T013xSASSQ002A011"
                      size="md"
                      inputType="number"
                    />
                    <ChartRowElement
                      type="option"
                      name="actionsSections[4].T013xSASSQ002A021"
                      label="mg/dL"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[4].T013xSASSQ002A037"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[5] &&
                          actionsSections[5].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[5] &&
                          actionsSections[5].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[5].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="ready for provider eval"
                      name="actionsSections[5].T013xSASSQ002A012"
                    />
                    <ChartRowElement
                      type="option"
                      label="notified provider"
                      name="actionsSections[5].T013xSASSQ002A013"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[5].T013xSASSQ002A038"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[6] &&
                          actionsSections[6].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[6] &&
                          actionsSections[6].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[6].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="option"
                      label="restraints"
                      name="actionsSections[6].T013xSASSQ002A014"
                    />
                    <ChartRowElement
                      type="option"
                      label="see documentation"
                      name="actionsSections[6].T013xSASSQ002A015"
                    />
                    <ChartRowElement
                      type="input"
                      name="actionsSections[6].T013xSASSQ002A039"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[8] &&
                          actionsSections[8].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[8] &&
                          actionsSections[8].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[8].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="actionsSections[8].T013xSASSQ002A040"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[9] &&
                          actionsSections[9].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[9] &&
                          actionsSections[9].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[9].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="actionsSections[9].T013xSASSQ002A041"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[10] &&
                          actionsSections[10].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[10] &&
                          actionsSections[10].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[10].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="actionsSections[10].T013xSASSQ002A042"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent padding={CONTAINER_PADDING_2}>
                <ChartRow
                  templateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                  columnGap={ACTIONS_COLUMNS_GAP}
                >
                  <ChartRowGroup>
                    <StaffAvatar
                      maxWidth="32px"
                      size="xs"
                      w="32px"
                      h="32px"
                      fontSize=".25rem"
                      userName={
                        (actionsSections[11] &&
                          actionsSections[11].createdByFullName) ||
                        ""
                      }
                      profileUrl={
                        (actionsSections[11] &&
                          actionsSections[11].createdByPictureUrl) ||
                        ""
                      }
                      justifySelf="center"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="date"
                      name="actionsSections[11].date"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="input"
                      name="actionsSections[11].T013xSASSQ002A043"
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N13Actions };
