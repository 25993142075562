import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C052x";

function T52ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      getValues,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Psych"
            >
              Psych
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Acute stress reaction"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Anorexia nervosa:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A064`}
                />
                <ChartRowElement
                  label="type:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A065`}
                />
                <ChartRowElement
                  label="binge"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="purge"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="restrictive"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Anxiety reaction"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bipolar disorder:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A066`}
                />
                <ChartRowElement
                  label="currently:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A067`}
                />
                <ChartRowElement
                  label="manic:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A068`}
                />
                <ChartRowElement
                  label="depressive:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A069`}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mod"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ psychosis"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Conversion reaction:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A070`}
                />
                <ChartRowElement
                  label="hysteria:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A071`}
                />
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A072`}
                />
                <ChartRowElement
                  label="motor"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sensory"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="deficit"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Delusional:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A073`}
                />
                <ChartRowElement
                  label="paranoia"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="persecutory"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="somatic"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Depression:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A074`}
                />
                <ChartRowElement
                  label="single episode"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="recurrent"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="mild"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="severe"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ psychosis"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="postpartum"
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Grief reaction:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A075`}
                />
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A076`}
                />
                <ChartRowElement
                  label="anxiety"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="depression"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="anger"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Insomnia, idiopathic"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Intermittent explosive disorder"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Obsessive compulsive disorder"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Oppositional defiant disorder"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Panic attack"
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Oppositional defiant disorder"
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Personality disorder:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A077`}
                />
                <ChartRowElement
                  label="borderline"
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="histrionic"
                  name={`Q002.${chartCode}${sectionId}Q002A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="antisocial"
                  name={`Q002.${chartCode}${sectionId}Q002A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="paranoid"
                  name={`Q002.${chartCode}${sectionId}Q002A049`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A050`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Psychosis, acute"
                  name={`Q002.${chartCode}${sectionId}Q002A051`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A052`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Psychotic disorder, brief"
                  name={`Q002.${chartCode}${sectionId}Q002A053`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A054`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="PTSD:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A078`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A055`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A056`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Schizophrenia:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A079`}
                />
                <ChartRowElement
                  label="simple"
                  name={`Q002.${chartCode}${sectionId}Q002A057`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="paranoid"
                  name={`Q002.${chartCode}${sectionId}Q002A058`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="undifferentiated"
                  name={`Q002.${chartCode}${sectionId}Q002A059`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A060`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Schizoaffective disorder:"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A080`}
                />
                <ChartRowElement
                  label="type:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A081`}
                />
                <ChartRowElement
                  label="bipolar"
                  name={`Q002.${chartCode}${sectionId}Q002A061`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="depressive:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q002.${chartCode}${sectionId}Q002A082`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A062`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A063`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Dementia:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A025`}
                />
                <ChartRowElement
                  label="senile"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="multi-infarct"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="Alzheimer's:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A026`}
                />
                <ChartRowElement
                  label="early onset"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="late"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="Parkinson's"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="frontotemporal"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ behavioral problem"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Delirium tremens"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Meningitis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A027`}
                />
                <ChartRowElement
                  label="Encephalitis:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A028`}
                />
                <ChartRowElement
                  label="aseptic"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="viral"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="bacterial"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="organism:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A029`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Seizure:"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A030`}
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="focal"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="complex partial"
                  name={`Q003.${chartCode}${sectionId}Q003A017`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="absence"
                  name={`Q003.${chartCode}${sectionId}Q003A018`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="status epilepticus"
                  name={`Q003.${chartCode}${sectionId}Q003A019`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ hx of epilepsy:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A031`}
                />
                <ChartRowElement
                  label="idiopathic"
                  name={`Q003.${chartCode}${sectionId}Q003A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="due to drugs:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A032`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A021`}
                  w="255px"
                  type="input"
                />
                <ChartRowElement
                  label="sz control:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q003.${chartCode}${sectionId}Q003A033`}
                />
                <ChartRowElement
                  label="good"
                  name={`Q003.${chartCode}${sectionId}Q003A022`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="poor"
                  name={`Q003.${chartCode}${sectionId}Q003A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A024`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Drug abuse:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A053`}
                />
                <ChartRowElement
                  label="Drug dependence:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A054`}
                />
                <ChartRowElement
                  label="drug:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A055`}
                />
                <ChartRowElement
                  label="alcohol"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="anxiolytics"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cannabis"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="cocaine"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hallucinogens"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="inhalants"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="opioids"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sedatives"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="stimulants"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A056`}
                />
                <ChartRowElement
                  label="intoxication"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="withdrawal"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="anxiety"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="apathy"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="paranoia"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="sleep disorder"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="delirium"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="mood disorder"
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="psychosis:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A057`}
                />
                <ChartRowElement
                  label="delusion"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hallucinations"
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dystonic reaction, acute:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A058`}
                />
                <ChartRowElement
                  label="drug:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A059`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hyperthyroidism:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A060`}
                />
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A061`}
                />
                <ChartRowElement
                  label="goiter"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="nodule:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A062`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label=">1"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A063`}
                />
                <ChartRowElement
                  label="crisis"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="storm"
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hyperventilation syndrome"
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypoglycemia:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A064`}
                />
                <ChartRowElement
                  label="w/ coma"
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/ diabetes:"
                  fontStyle="italic"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A065`}
                />
                <ChartRowElement
                  label="Type 1"
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="Type 2"
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypothyroidism"
                  name={`Q004.${chartCode}${sectionId}Q004A035`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A036`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Overdose:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A066`}
                />
                <ChartRowElement
                  label="Poisoning:"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A067`}
                />
                <ChartRowElement
                  label="intentional"
                  name={`Q004.${chartCode}${sectionId}Q004A037`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="accidental"
                  name={`Q004.${chartCode}${sectionId}Q004A038`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="unknown"
                  name={`Q004.${chartCode}${sectionId}Q004A039`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="substance:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A068`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A040`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="SIRS"
                  name={`Q004.${chartCode}${sectionId}Q004A041`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="Sepsis, severe"
                  name={`Q004.${chartCode}${sectionId}Q004A042`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  name={`Q004.${chartCode}${sectionId}Q004A069`}
                />
                <ChartRowElement
                  label="hypotension"
                  name={`Q004.${chartCode}${sectionId}Q004A043`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="shock"
                  name={`Q004.${chartCode}${sectionId}Q004A044`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="DIC"
                  name={`Q004.${chartCode}${sectionId}Q004A045`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="alt. mental"
                  name={`Q004.${chartCode}${sectionId}Q004A046`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="hypoxia"
                  name={`Q004.${chartCode}${sectionId}Q004A047`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="resp. distress"
                  name={`Q004.${chartCode}${sectionId}Q004A048`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A049`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Suicide attempt"
                  name={`Q004.${chartCode}${sectionId}Q004A050`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A051`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A052`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Fever"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hallucination:"
                  type="cross-option"
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                />
                <ChartRowElement
                  label="auditory"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  label="visual"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  fontStyle="italic"
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Headache"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Homicidal ideation"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Suicidal ideation"
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  type="input"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                  type="input"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T52ClinicalImpression };
