import {
  CloseButton,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { useCreateAmbulatoryNote } from "modules/charts-shared/api";
import { Loading } from "shared";
import { ChartNotesModalFooter } from "../chart-notes";
import { AmbulatoryNote } from "./AmbulatoryNote";
import { AmbulatoryNote as AmbulatoryNoteProp } from "modules/vitals/types";

type AmbulatoryNotesModalProps = {
  encounterId: string;
  isOpen: boolean;
  onClose: () => void;
  isDisabled?: boolean;
  data?: AxiosResponse<AmbulatoryNoteProp[]>;
  isLoading?: boolean;
};

function AmbulatoryNotesModal({
  encounterId,
  isOpen,
  onClose,
  isDisabled,
  data,
  isLoading,
}: AmbulatoryNotesModalProps) {
  const { mutate: createAmbulatoryNote, isLoading: isCreatingNote } =
    useCreateAmbulatoryNote({
      encounterId,
    });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDirection="column"
        height="500px"
        width="540px"
        maxWidth="540px"
        bg="white"
      >
        <ModalHeader
          minHeight="60px"
          fontWeight="400"
          padding="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bg="gray.50"
          position="relative"
        >
          Ambulatory Notes
          <CloseButton
            position="absolute"
            top="20px"
            right="20px"
            width="20px"
            height="20px"
            bg="gray.700"
            color="white"
            borderRadius="50%"
            sx={{ svg: { width: "7px", height: "7px" } }}
            onClick={onClose}
          />
        </ModalHeader>
        <Divider />
        <ModalBody flex="1" overflow="auto" padding="0 20px" margin="22px 0">
          <VStack spacing="1.25rem" paddingBottom="1rem">
            {isLoading && <Loading />}
            {data?.data.map((note) => (
              <AmbulatoryNote
                key={note.id}
                note={note}
                encounterId={encounterId}
              />
            ))}
          </VStack>
        </ModalBody>
        <Divider />
        <ChartNotesModalFooter
          audioEnabled={false}
          isLoading={isCreatingNote}
          onCreateAudioNote={() => {}}
          onCreateNote={(note) =>
            createAmbulatoryNote({
              note: note.note,
              patientVitalCheckId: encounterId,
            })
          }
          isDisabled={isDisabled}
        />
      </ModalContent>
    </Modal>
  );
}

export { AmbulatoryNotesModal };
