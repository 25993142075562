import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";

type UseGenerateSummaryDocumentPayload = {
  addPatientDemographics?: boolean;
  addRegistrationDocuments?: boolean;
  addInsuranceInformation?: boolean;
  addNursingChartSummary?: boolean;
  addProviderChartSummary?: boolean;
  addLabReports?: boolean;
  addRadiologyReports?: boolean;
  addDischargeFile?: boolean;
  addObservationFile?: boolean;
  miscFilesToAdd?: string[];
};

function useGenerateSummaryDocument(encounterId: string) {
  return useAxiosMutation(
    ["generateSummaryDocument", { encounterId }],
    (payload: UseGenerateSummaryDocumentPayload) =>
      axiosPatientInstance.post<Blob>(
        `v1/summary/${encounterId}/call-and-generate-custom-download`,
        payload,
        { responseType: "blob" }
      )
  );
}

export type { UseGenerateSummaryDocumentPayload };
export { useGenerateSummaryDocument };
