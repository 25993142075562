import { ChartAnswer } from "types";
import {
  RadiologyChartForm,
  RadiologyChartFormPayload,
  RadSection,
} from "./types";
import {
  mapAnswersListToFormObject,
  mapFormObjectToAnswersList,
} from "modules/charts-shared";
import { UseOrderChartPayloadItem } from "modules/provider/api";

function responseToForm(payload: UseOrderChartPayloadItem[]) {
  return {
    rad: payload.map(({ order, orderId, interpretations, answers }) => ({
      id: order?.id ?? "",
      status: order?.status,
      order,
      orderId,
      interpretations: interpretations || [],
      ...mapAnswersListToFormObject(answers),
    })),
  };
}

function mapSectionToPayload({
  code,
  createdById,
  order,
  orderId,
  interpretations,
  signedBy,
  ...answers
}: RadSection): RadiologyChartFormPayload {
  const answersGrouped: ChartAnswer[] = mapFormObjectToAnswersList(answers);
  const item: RadiologyChartFormPayload = {
    interpretations: [],
    signedBy,
  };

  interpretations
    .filter((interpretation) => !interpretation.deleted)
    .forEach((interpretation) => {
      const answersFormatted: ChartAnswer[] = [];
      answersGrouped.forEach((answer) => {
        if (
          answer.qCode.includes(interpretation.sectionId) &&
          answer.qCode.includes(interpretation.code)
        ) {
          answersFormatted.push(answer);
        }
      });

      if (answersFormatted.length > 0) {
        item.interpretations.push({
          ...interpretation,
          order: order,
          orderId: order?.id,
          orderNumber: order?.orderId,
          answers: answersFormatted,
        });
      }
    });

  return item;
}

function formToPayload(form: RadiologyChartForm): RadiologyChartFormPayload[] {
  const { rad } = form;
  return rad?.map(mapSectionToPayload) || [];
}

export { responseToForm, formToPayload };
