import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Billings = createIcon({
  path: (
    <path
      d="M1.63568 34.7845C0.750313 34.7845 0 34.0492 0 33.1488C0 32.2634 0.750313 31.5281 1.63568 31.5281H2.6411V30.2226C2.6411 29.4723 3.10629 29.0221 3.85661 29.0221H4.5619V11.4948H3.31638C2.22093 11.4948 1.59066 10.6845 1.59066 9.87411C1.59066 9.27386 1.90579 8.73364 2.62609 8.31346L16.0117 0.60025C16.6569 0.225094 17.4073 0 18.1125 0C18.8178 0 19.5682 0.225094 20.2284 0.60025L33.614 8.31346C34.3193 8.73364 34.6494 9.27386 34.6494 9.87411C34.6494 10.6845 34.0042 11.4948 32.9087 11.4948H31.6782V29.0221H32.3835C33.1338 29.0221 33.599 29.4723 33.599 30.2226V31.5281H34.3643C35.2347 31.5281 36 32.2634 36 33.1488C36 34.0642 35.2347 34.7845 34.3643 34.7845H1.63568ZM18.1125 27.4764C18.3677 27.4764 18.5777 27.2664 18.5777 27.0263V25.7057C21.1438 25.5556 23.4698 24.3251 23.4698 21.639C23.4698 19.238 21.684 18.3677 19.3731 17.8424L18.5777 17.6624V14.1809C19.5081 14.286 20.2584 14.7511 20.6036 15.5915C20.9037 16.1317 21.3239 16.4469 21.9241 16.4469C22.5394 16.4469 23.1396 16.1017 23.1396 15.3814C23.1396 13.5206 20.9787 12.2151 18.5777 12.035V10.7145C18.5777 10.4594 18.3677 10.2493 18.1125 10.2493C17.8574 10.2493 17.6474 10.4594 17.6474 10.7145V12.02C15.1263 12.1701 12.8754 13.5206 12.8754 16.0117C12.8754 18.3677 14.7511 19.3581 16.882 19.8233L17.6474 19.9733V23.5748C16.5069 23.4998 15.7566 23.0646 15.3514 22.1342C15.0363 21.564 14.6911 21.2639 14.0909 21.2639C13.3706 21.2639 12.8303 21.669 12.8303 22.4193C12.8303 22.6895 12.8904 22.9296 13.0104 23.1847C13.6107 24.7303 15.4564 25.6157 17.6474 25.7207V27.0263C17.6474 27.2664 17.8574 27.4764 18.1125 27.4764ZM15.5615 15.7566C15.5615 14.7962 16.4769 14.286 17.6474 14.1809V17.4523L17.4823 17.4073C16.3118 17.1071 15.5615 16.6569 15.5615 15.7566ZM18.5777 23.5748V20.1834L18.7278 20.2284C19.8683 20.4985 20.7987 20.8737 20.7987 21.9241C20.7987 23.0046 19.8233 23.4848 18.5777 23.5748Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 36 35",
});

export { Billings };
