import {
  Box,
  Divider,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { EmrLock } from "@medstonetech/slate-icons";
import { OrderFormElementRoute } from "enums";
import { useAddCommonDoseFavorite } from "modules/charts-shared/api";
import { calculateMedicationAmount } from "modules/charts-shared/utils";
import { Concentration, OrderElementResponse } from "modules/order-list/types";
import { Fragment, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { IoHeart } from "react-icons/io5";
import {
  Button,
  Card,
  Checkbox,
  FormCalendarInput,
  Label,
  Select,
} from "shared";
import { getOptions } from "utils/enum-mapper";
import { MedicationOrderForm } from "./AddMedicationOrderModal";
import {
  VALID_CONCENTRATIONS_UNITS,
  VALID_UNITS,
  VALID_VOLUME_UNITS,
} from "./ValidCalculations";

type CommonDosesProps = {
  item: OrderElementResponse;
  showCalculation: () => void;
};

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

function CommonDoses({ item, showCalculation }: CommonDosesProps) {
  const { setValue, register, watch, getValues, control } =
    useFormContext<MedicationOrderForm>();

  const { orderFormElementCommonDoses: commonDoses } = item;

  const { mutateAsync: addFavorite, isLoading: isAddingFavorite } =
    useAddCommonDoseFavorite();

  const toast = useToast();

  const getConcentration = (concentrationId?: string) => {
    const concentration: Concentration | undefined =
      item.orderFormElementConcentrations?.find(
        (c) => c.id === concentrationId
      );

    if (
      concentration &&
      concentration.doseAmount &&
      concentration.doseUnit &&
      concentration.volumeAmount &&
      concentration.volumeUnit
    ) {
      return ` • (${concentration.doseAmount} ${concentration.doseUnit} / ${concentration.volumeAmount} ${concentration.volumeUnit})`;
    }

    return "";
  };

  const handleCalculate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    const { concentration, doseAmount, doseUnit, weight } = getValues();
    if (checked && concentration && doseAmount && doseUnit && weight) {
      const amount = calculateMedicationAmount(
        concentration,
        doseAmount,
        doseUnit,
        weight
      );
      setValue("amount", amount);
    } else {
      setValue("amount", null);
    }
  };

  const handleChangeDose = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked, value: doseId },
    } = e;
    const dose = item.orderFormElementCommonDoses.find((c) => c.id === doseId);

    const concentration: Concentration | undefined =
      item.orderFormElementConcentrations?.find(
        (c) => c.id === dose?.orderFormEelementConcentrationId
      );

    setValue("concentration", concentration || {});

    if (checked && doseId === "freeTextCommonDose") {
      setValue("doseId", doseId);
      return;
    }

    if (checked && dose) {
      setValue("doseAmount", dose.doseAmount);
      setValue("doseUnit", dose.doseUnit);
      setValue("doseId", dose.id);
    } else {
      setValue("doseId", undefined);
    }
  };

  const doseUnit = watch("doseUnit");
  const doseId = watch("doseId");
  const route = watch("route");
  const concentrationDoseUnit = watch("concentration.doseUnit");
  const volumeUnit = watch("concentration.volumeUnit");

  const onAddFavorite = useCallback(async () => {
    if (doseId) {
      await addFavorite({
        orderFormElementId: item.id,
        route: route as OrderFormElementRoute,
        orderFormElementCommonDoseId: doseId,
      });
      toast({ description: "Common dose added to favorites" });
    }
  }, [addFavorite, doseId, item.id, route, toast]);

  const validCalculation =
    VALID_UNITS.includes(doseUnit) &&
    !!concentrationDoseUnit &&
    VALID_CONCENTRATIONS_UNITS.includes(concentrationDoseUnit) &&
    !!volumeUnit &&
    VALID_VOLUME_UNITS.includes(volumeUnit);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Label {...fieldLabelStyle}>Medication Name *</Label>
      <InputGroup>
        <Input readOnly value={item?.description} />
        <InputRightElement>
          <Icon as={EmrLock} w="32px" h="32px" size="lg" color="gray.450" />
        </InputRightElement>
      </InputGroup>

      <Label {...fieldLabelStyle}>Common Doses • Concentration</Label>
      <Card variant="basic" p="15px" minH="40px">
        <Flex columnGap="0.5rem">
          <Checkbox
            isChecked={watch("doseId") === "freeTextCommonDose"}
            value="freeTextCommonDose"
            onChange={handleChangeDose}
            sx={{ flex: 1 }}
          />
          <Input
            {...register("freeTextCommonDose")}
            sx={{ backgroundColor: "transparent", border: "none", paddingX: 0 }}
            _focus={{ backgroundColor: "transparent", border: "none" }}
            placeholder="Type other dose here"
          />
        </Flex>
        {commonDoses.length > 0 && <Divider />}
        <Stack maxH="48" overflow="auto">
          {commonDoses?.map((cd, i, arr) => (
            <Fragment key={cd.id}>
              <Checkbox
                isChecked={watch("doseId") === cd.id}
                value={cd.id}
                onChange={handleChangeDose}
                sx={{ flex: 1 }}
              >
                {cd.doseAmount || 0} {cd.doseUnit}
                {getConcentration(cd.orderFormEelementConcentrationId)}
              </Checkbox>
              {i < arr.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Stack>
      </Card>

      {watch("concentration") && validCalculation && (
        <>
          <Label {...fieldLabelStyle}>Weight(kg)</Label>
          <Flex columnGap="10px">
            <Input type="number" step=".001" flex={1} {...register("weight")} />
            <Checkbox
              isDisabled={!Boolean(watch("weight"))}
              onChange={handleCalculate}
              flex={1}
            >
              Calculate from weight
            </Checkbox>
          </Flex>
        </>
      )}
      {watch("amount") && (
        <>
          <Label {...fieldLabelStyle}>Amount</Label>
          <Flex>
            <InputGroup flex={1}>
              <Input readOnly value={watch("amount") || 0} />
              <InputRightElement>mL</InputRightElement>
            </InputGroup>
            <Button
              flex={1}
              variant="label"
              color="blue"
              onClick={showCalculation}
            >
              Show Calculation
            </Button>
          </Flex>
        </>
      )}
      <Label {...fieldLabelStyle}>Route *</Label>
      <Select
        items={getOptions("orderFormElementRoute")}
        labelAccessor="label"
        valueAccessor="value"
        onSelectedItemChange={(changes) =>
          setValue("route", changes.selectedItem?.value || "")
        }
      />
      <Button
        color="blue"
        leftIcon={<Icon as={IoHeart} />}
        onClick={onAddFavorite}
        aria-label="add favorite"
        variant="label"
        disabled={!(doseId && route)}
        isLoading={isAddingFavorite}
        mt="1rem"
      >
        Add to favorites
      </Button>

      <Label {...fieldLabelStyle}>Expected Administration Time *</Label>
      <Flex maxW="240px">
        <FormCalendarInput
          name="administrationTime"
          dateFormat={"LL/dd/yyyy \t HH:mm"}
          control={control}
          showIcon={false}
          showTimeInput
          shouldCloseOnSelect={false}
          onInputClick={() => {
            const date = getValues("administrationTime");
            if (!date) {
              setValue("administrationTime", new Date());
            }
          }}
        />
      </Flex>
      <Label {...fieldLabelStyle}>Notes</Label>
      <Input {...register("notes")} type="text" />
    </Box>
  );
}

export { CommonDoses };
