import {
  Box,
  Divider,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { EmrLock } from "@medstonetech/slate-icons";
import {
  useDeleteCommonDoseFavorite,
  useDoseFavorites,
} from "modules/charts-shared/api";
import { calculateMedicationAmount } from "modules/charts-shared/utils";
import { Concentration, OrderElementResponse } from "modules/order-list/types";
import { Fragment, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IoHeartDislikeOutline } from "react-icons/io5";
import { Button, Card, Checkbox, FormCalendarInput, Label } from "shared";
import { MedicationOrderForm } from "./AddMedicationOrderModal";
import {
  VALID_CONCENTRATIONS_UNITS,
  VALID_UNITS,
  VALID_VOLUME_UNITS,
} from "./ValidCalculations";

type FavoriteDosesProps = {
  item: OrderElementResponse;
  showCalculation: () => void;
};

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

function FavoriteDoses({ item, showCalculation }: FavoriteDosesProps) {
  const { setValue, register, watch, getValues, control } =
    useFormContext<MedicationOrderForm>();

  const [selectedFavoriteId, setSelectedFavoriteId] = useState<string>("");

  const { data } = useDoseFavorites(item.id);
  const {
    mutateAsync: removeFromFavorites,
    isLoading: isRemovingFromFavorites,
  } = useDeleteCommonDoseFavorite();

  const favorites = data?.data;

  const onRemoveFavorite = async () => {
    if (selectedFavoriteId) {
      await removeFromFavorites(selectedFavoriteId);
      setSelectedFavoriteId("");
    }
  };

  const getConcentration = (concentrationId?: string) => {
    const concentration: Concentration | undefined =
      item.orderFormElementConcentrations?.find(
        (c) => c.id === concentrationId
      );

    if (
      concentration &&
      concentration.doseAmount &&
      concentration.doseUnit &&
      concentration.volumeAmount &&
      concentration.volumeUnit
    ) {
      return ` (${concentration.doseAmount} ${concentration.doseUnit} / ${concentration.volumeAmount} ${concentration.volumeUnit})`;
    }

    return "";
  };

  const handleCalculate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    const { concentration, doseAmount, doseUnit, weight } = getValues();
    if (checked && concentration && doseAmount && doseUnit && weight) {
      const amount = calculateMedicationAmount(
        concentration,
        doseAmount,
        doseUnit,
        weight
      );
      setValue("amount", amount);
    } else {
      setValue("amount", null);
    }
  };

  const handleChangeDose = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked, value: id },
    } = e;
    const favorite = favorites?.find((c) => c.id === id);

    const dose = item.orderFormElementCommonDoses.find(
      (c) => c.id === favorite?.orderFormElementCommonDoseId
    );

    const concentration: Concentration | undefined =
      item.orderFormElementConcentrations?.find(
        (c) => c.id === dose?.orderFormEelementConcentrationId
      );

    setValue("concentration", concentration || {});

    if (checked && favorite) {
      setSelectedFavoriteId(favorite.id);
      setValue("doseAmount", favorite.dose.doseAmount);
      setValue("doseUnit", favorite.dose.doseUnit);
      setValue("doseId", favorite.orderFormElementCommonDoseId);
      setValue("route", favorite.route);
    } else {
      setValue("doseId", undefined);
      setValue("concentration", {});
      setSelectedFavoriteId("");
    }
  };

  const doseUnit = watch("doseUnit");
  const concentrationDoseUnit = watch("concentration.doseUnit");
  const volumeUnit = watch("concentration.volumeUnit");

  const validCalculation =
    VALID_UNITS.includes(doseUnit) &&
    !!concentrationDoseUnit &&
    VALID_CONCENTRATIONS_UNITS.includes(concentrationDoseUnit) &&
    !!volumeUnit &&
    VALID_VOLUME_UNITS.includes(volumeUnit);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Label {...fieldLabelStyle}>Medication Name *</Label>
      <InputGroup>
        <Input readOnly value={item?.description} />
        <InputRightElement>
          <Icon as={EmrLock} w="32px" h="32px" size="lg" color="gray.450" />
        </InputRightElement>
      </InputGroup>
      <Label {...fieldLabelStyle}>Favorite Doses • Route</Label>
      <Card variant="basic" p="15px" minH="40px" mb="1rem">
        {!!favorites && favorites?.length > 0 ? (
          <Stack>
            {favorites?.map(({ id, dose, route }, i, arr) => (
              <Fragment key={id}>
                <Checkbox
                  isChecked={selectedFavoriteId === id}
                  value={id}
                  onChange={handleChangeDose}
                  sx={{ flex: 1 }}
                >
                  {dose?.doseAmount || 0} {dose?.doseUnit}
                  {getConcentration(
                    dose?.orderFormEelementConcentrationId
                  )} • {route}
                </Checkbox>
                {i < arr.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Stack>
        ) : (
          <Text flex={1} textAlign="center">
            None
          </Text>
        )}
      </Card>

      {selectedFavoriteId && (
        <Button
          color="red"
          leftIcon={<Icon as={IoHeartDislikeOutline} />}
          onClick={onRemoveFavorite}
          aria-label="remove favorite"
          variant="label"
          isLoading={isRemovingFromFavorites}
        >
          Remove from favorites
        </Button>
      )}
      {watch("concentration") && validCalculation && (
        <>
          <Label {...fieldLabelStyle}>Weight(kg)</Label>
          <Flex columnGap="10px">
            <Input type="number" step=".001" flex={1} {...register("weight")} />
            <Checkbox
              isDisabled={!Boolean(watch("weight"))}
              onChange={handleCalculate}
              flex={1}
            >
              Calculate from weight
            </Checkbox>
          </Flex>
        </>
      )}
      {watch("amount") && (
        <>
          <Label {...fieldLabelStyle}>Amount</Label>
          <Flex>
            <InputGroup flex={1}>
              <Input readOnly value={watch("amount") || 0} />
              <InputRightElement>mL</InputRightElement>
            </InputGroup>
            <Button
              flex={1}
              variant="label"
              color="blue"
              onClick={showCalculation}
            >
              Show Calculation
            </Button>
          </Flex>
        </>
      )}
      <Label {...fieldLabelStyle}>Expected Administration Time *</Label>
      <Flex maxW="240px">
        <FormCalendarInput
          name="administrationTime"
          dateFormat={"LL/dd/yyyy \t HH:mm"}
          control={control}
          showIcon={false}
          showTimeInput
          shouldCloseOnSelect={false}
          onInputClick={() => {
            const date = getValues("administrationTime");
            if (!date) {
              setValue("administrationTime", new Date());
            }
          }}
        />
      </Flex>
      <Label {...fieldLabelStyle}>Notes</Label>
      <Input {...register("notes")} type="text" />
    </Box>
  );
}

export { FavoriteDoses };
