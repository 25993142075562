import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S001Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q001.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q001`}
          subsectionLabel="Apgar Score (One Minute)"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q001`
            )
          }
        >
          Apgar Score (One Minute)
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="HR="
              fontStyle="italic"
              textDecorationLine="underline"
              type="option"
              name={`${sectionDelimiter}Q001A001`}
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q001A002`}
              w="695px"
              type="input"
            />
            <ChartRowElement
              label="RR="
              fontStyle="italic"
              textDecorationLine="underline"
              type="option"
              name={`${sectionDelimiter}Q001A003`}
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q001A004`}
              type="input"
            />
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="HR"
                name={`${sectionDelimiter}Q001A005`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="absent (0)"
                name={`${sectionDelimiter}Q001A006`}
                type="option"
              />
              <ChartRowElement
                label="less than 100 (1)"
                name={`${sectionDelimiter}Q001A007`}
                type="option"
              />
              <ChartRowElement
                label="100 or greater (2)"
                name={`${sectionDelimiter}Q001A008`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A009`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="RR"
                name={`${sectionDelimiter}Q001A010`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="absent (0)"
                name={`${sectionDelimiter}Q001A011`}
                type="option"
              />
              <ChartRowElement
                label="weak cry (1)"
                name={`${sectionDelimiter}Q001A012`}
                type="option"
              />
              <ChartRowElement
                label="strong cry (2)"
                name={`${sectionDelimiter}Q001A013`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A014`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Color"
                name={`${sectionDelimiter}Q001A015`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="blue or pale (0)"
                name={`${sectionDelimiter}Q001A016`}
                type="option"
              />
              <ChartRowElement
                label="pink blue extrem. (1)"
                name={`${sectionDelimiter}Q001A017`}
                type="option"
              />
              <ChartRowElement
                label="pink (2)"
                name={`${sectionDelimiter}Q001A018`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A019`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Muscle tone"
                name={`${sectionDelimiter}Q001A020`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="limp (0)"
                name={`${sectionDelimiter}Q001A021`}
                type="option"
              />
              <ChartRowElement
                label="some flexion (1)"
                name={`${sectionDelimiter}Q001A022`}
                type="option"
              />
              <ChartRowElement
                label="active motion (2)"
                name={`${sectionDelimiter}Q001A023`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A024`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Reflex Irrit"
                name={`${sectionDelimiter}Q001A025`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="no response (0)"
                name={`${sectionDelimiter}Q001A026`}
                type="option"
              />
              <ChartRowElement
                label="grimace (1)"
                name={`${sectionDelimiter}Q001A027`}
                type="option"
              />
              <ChartRowElement
                label="cough or sneeze (2)"
                name={`${sectionDelimiter}Q001A028`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q001A029`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="Score="
              type="option"
              name={`${sectionDelimiter}Q001A030`}
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q001A031`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q001A032`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S001Procedure };
