import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S006Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q006.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q006`}
          subsectionLabel="Chest Tube Insertion"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q006`
            )
          }
        >
          Chest Tube Insertion
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q006A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q006A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Estimated blood loss"
                name={`${sectionDelimiter}Q006A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q006A004`}
                w="100px"
                type="input"
              />
              <ChartRowElement
                label="mL"
                type="option"
                name={`${sectionDelimiter}Q006A005`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q006A006`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Prep:"
                name={`${sectionDelimiter}Q006A007`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="French"
                name={`${sectionDelimiter}Q006A008`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="Betadine prep"
                name={`${sectionDelimiter}Q006A009`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="Chlorhexidine"
                name={`${sectionDelimiter}Q006A010`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q006A011`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Anesthesia:"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q006A012`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q006A013`}
                w="100px"
                type="input"
              />
              <ChartRowElement
                label="mL"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q006A014`}
              />
              <ChartRowElement
                label="local"
                name={`${sectionDelimiter}Q006A015`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="regional (rib) block"
                name={`${sectionDelimiter}Q006A016`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="lidocaine"
                name={`${sectionDelimiter}Q006A017`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="bupivacaine"
                name={`${sectionDelimiter}Q006A018`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q006A019`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Position:"
                type="option"
                name={`${sectionDelimiter}Q006A020`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="mid"
                name={`${sectionDelimiter}Q006A021`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="anter"
                name={`${sectionDelimiter}Q006A022`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="post"
                name={`${sectionDelimiter}Q006A023`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                label="axillary line"
                name={`${sectionDelimiter}Q006A024`}
                fontStyle="italic"
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q006A025`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="interspace"
                fontStyle="italic"
                name={`${sectionDelimiter}Q006A026`}
                type="option"
              />
              <ChartRowElement
                label="sutured in place"
                fontStyle="italic"
                name={`${sectionDelimiter}Q006A027`}
                type="option"
              />
              <ChartRowElement
                label="position confirmed on CXR"
                fontStyle="italic"
                name={`${sectionDelimiter}Q006A028`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q006A029`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Return:"
                type="option"
                name={`${sectionDelimiter}Q006A030`}
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="air"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q006A031`}
              />
              <ChartRowElement
                label="blood"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q006A032`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q006A033`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="connected to suction"
                fontStyle="italic"
                type="option"
                name={`${sectionDelimiter}Q006A034`}
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q006A035`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q006A036`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S006Procedure };
