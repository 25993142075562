import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";

type WarningDialogProps = {
  onAction: () => void;
  onCancel: () => void;
  title: string;
  mainText: string | React.ReactElement;
  secondaryText?: string;
  actionsDirection?: "vertical" | "horizantal";
  cancelLabel?: string;
  actionLabel?: string;
  isActionLoading?: boolean;
  cancelButtonProps?: ButtonProps;
  actionButtonProps?: ButtonProps;
  containerProps?: ModalContentProps;
  onlyActionButton?: boolean;
  showExtraActionButton?: boolean;
  isExtraActionLoading?: boolean;
  extraActionButtonProps?: ButtonProps;
  extraActionLabel?: string;
  onExtraAction?: () => void;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose"> &
  Pick<ModalProps, "blockScrollOnMount" | "closeOnOverlayClick">;

const DEFAULT_BTN_STYLE_PROPS: ButtonProps = {
  variant: "label",
  padding: "0.75rem 0",
  fontSize: "1.0625rem",
  fontWeight: "400",
};

function WarningDialog(props: WarningDialogProps) {
  const {
    isOpen,
    onAction,
    onCancel,
    onClose,
    mainText,
    title,
    actionsDirection = "horizantal",
    secondaryText,
    actionLabel = "Remove",
    cancelLabel = "Cancel",
    cancelButtonProps,
    actionButtonProps,
    containerProps,
    isActionLoading,
    onlyActionButton,
    showExtraActionButton,
    isExtraActionLoading,
    extraActionButtonProps,
    extraActionLabel,
    onExtraAction,
    ...modalProps
  } = props;

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      isCentered
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="295px"
        width="295px"
        borderRadius="1rem"
        bg="#E5E5E5"
        {...containerProps}
      >
        <ModalHeader
          padding="16px 28px 0"
          textAlign="center"
          fontSize="1.0625rem"
          fontWeight="600"
        >
          {title}
        </ModalHeader>
        <ModalBody
          padding="0 28px 16px"
          fontSize="0.8125rem"
          lineHeight="1.125rem"
        >
          <Box textAlign="center">{mainText}</Box>
          {secondaryText && (
            <Box width="100%" textAlign="center" marginTop="1rem">
              {secondaryText}
            </Box>
          )}
        </ModalBody>
        <Divider borderColor="gray.450" opacity="unset" />
        <ModalFooter padding="0">
          {actionsDirection === "horizantal" ? (
            <Box display="flex" width="100%" height="44px">
              {!onlyActionButton && (
                <Button
                  onClick={onCancel}
                  color="blue"
                  flex={1}
                  {...DEFAULT_BTN_STYLE_PROPS}
                  {...cancelButtonProps}
                >
                  {cancelLabel}
                </Button>
              )}

              <Divider
                borderColor="gray.450"
                opacity="unset"
                orientation="vertical"
                height="100%"
              />
              <Button
                onClick={onAction}
                color="red"
                flex={1}
                isLoading={isActionLoading}
                isDisabled={isActionLoading}
                {...DEFAULT_BTN_STYLE_PROPS}
                {...actionButtonProps}
              >
                {actionLabel}
              </Button>
            </Box>
          ) : (
            <VStack width="100%" spacing="0">
              {!onlyActionButton && (
                <Button
                  width="100%"
                  onClick={onCancel}
                  {...DEFAULT_BTN_STYLE_PROPS}
                  {...cancelButtonProps}
                >
                  {cancelLabel}
                </Button>
              )}

              <Divider borderColor="gray.450" opacity="unset" />
              <Button
                width="100%"
                onClick={onAction}
                color="red"
                isLoading={isActionLoading}
                isDisabled={isActionLoading}
                {...DEFAULT_BTN_STYLE_PROPS}
                {...actionButtonProps}
              >
                {actionLabel}
              </Button>

              {showExtraActionButton && (
                <>
                  <Divider borderColor="gray.450" opacity="unset" />
                  <Button
                    width="100%"
                    onClick={onExtraAction}
                    color="blue"
                    isLoading={isExtraActionLoading}
                    {...DEFAULT_BTN_STYLE_PROPS}
                    {...extraActionButtonProps}
                  >
                    {extraActionLabel}
                  </Button>
                </>
              )}
            </VStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export type { WarningDialogProps };
export { WarningDialog };
