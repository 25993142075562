import { EncounterProfileStatus, EncounterStatus } from "enums";

import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type EncounterOverviewResponse = {
  date: string;
  profileStatus: EncounterProfileStatus;
  encounterStatus: EncounterStatus;
  supervisingProviderFirstName: string | null;
  supervisingProviderLastName: string | null;
  primaryInsuranceHasInfo?: boolean;
  secondaryInsuranceHasInfo?: boolean;
  tertiaryInsuranceHasInfo?: boolean;
  hasMergeHistory?: boolean;
  encounterNumber?: string;
  providerDisposition?: boolean;
  nurseDisposition?: boolean;
};

function useEncounterOverview(
  encounterId: string,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["encounterOverview", { encounterId }],
    () =>
      axiosPatientInstance.get<EncounterOverviewResponse>(
        `v1/encounter/${encounterId}/form/overview`
      ),
    { staleTime: 0, cacheTime: 0, ...options }
  );
}

export type { EncounterOverviewResponse };
export { useEncounterOverview };
