import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartType } from "types";

function useUpdateEncounterDisposition(encounterId: string, type: ChartType) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterDisposition", { encounterId }],
    () =>
      axiosPatientInstance.put(
        `v1/Encounter/${encounterId}/update-disposition/${type}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOverview", { encounterId }], {
          refetchInactive: true,
        });
        queryClient.invalidateQueries("encounters");
        queryClient.invalidateQueries("searchEncounters");
        queryClient.invalidateQueries("encounterSideInfo");
      },
    }
  );
}

export { useUpdateEncounterDisposition };
