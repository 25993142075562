import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";

function S022Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q022.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q022`}
          subsectionLabel="Treatment / Resuscitation"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q022`
            )
          }
        >
          Treatment / Resuscitation
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="O2"
              name={`${sectionDelimiter}Q022A001`}
              type="option"
            />
            <ChartRowElement
              label="nasal prongs"
              fontStyle="italic"
              name={`${sectionDelimiter}Q022A002`}
              type="option"
            />
            <ChartRowElement
              label="mask"
              fontStyle="italic"
              name={`${sectionDelimiter}Q022A003`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A004`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="IV(s)"
              name={`${sectionDelimiter}Q022A005`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A006`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="NG tube"
              name={`${sectionDelimiter}Q022A007`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A008`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="Foley catheter"
              name={`${sectionDelimiter}Q022A009`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A010`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="pain control"
              name={`${sectionDelimiter}Q022A011`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A012`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="tetanus toxoid"
              name={`${sectionDelimiter}Q022A013`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A014`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="bronchodilators"
              name={`${sectionDelimiter}Q022A015`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A016`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="Intubated successfully with"
              name={`${sectionDelimiter}Q022A017`}
              type="option"
            />
            <ChartRowElement
              label="#"
              name={`${sectionDelimiter}Q022A018`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A019`}
              type="input"
              w="200px"
            />
            <ChartRowElement
              label="ETT"
              name={`${sectionDelimiter}Q022A020`}
              type="option"
            />
            <ChartRowElement
              label="curved"
              name={`${sectionDelimiter}Q022A021`}
              type="option"
            />
            <ChartRowElement
              label="straight blade"
              name={`${sectionDelimiter}Q022A022`}
              type="option"
            />
            <ChartRowElement
              label="nasal"
              name={`${sectionDelimiter}Q022A023`}
              type="option"
            />
            <ChartRowElement
              label="oral"
              name={`${sectionDelimiter}Q022A024`}
              type="option"
            />
            <ChartRowElement
              label="preoxygenated"
              name={`${sectionDelimiter}Q022A025`}
              type="option"
            />
            <ChartRowElement
              label="premedicated"
              name={`${sectionDelimiter}Q022A026`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A027`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="placement confirmed by:"
              name={`${sectionDelimiter}Q022A028`}
              type="option"
            />
            <ChartRowElement
              label="auscultation"
              fontFamily="italic"
              name={`${sectionDelimiter}Q022A029`}
              type="option"
            />
            <ChartRowElement
              label="CXR"
              fontFamily="italic"
              name={`${sectionDelimiter}Q022A030`}
              type="option"
            />
            <ChartRowElement
              label="syringe aspiration"
              fontFamily="italic"
              name={`${sectionDelimiter}Q022A031`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q022A032`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q022A033`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S022Procedure };
