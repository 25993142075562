import { Icon } from "@chakra-ui/react";
import { EmrInProcess, EmrRegistration } from "@medstonetech/slate-icons";
import { OrderStatus } from "enums";
import {
  CloseCircle,
  ExclamationSquare,
  SquaredChecked,
  AuditComment,
} from "icons";

const ORDER_STATUS_COLOR: Record<OrderStatus, string> = {
  Cancelled: "gray",
  Completed: "green",
  InProcess: "blue",
  Ordered: "orange",
  NeedsApproval: "yellow",
  CancellationRequested: "red",
  Administered: "purple",
  Prepared: "cyan",
};

const ORDER_STATUS_ICON: Record<OrderStatus, JSX.Element> = {
  Cancelled: <Icon as={CloseCircle} />,
  Completed: <Icon as={SquaredChecked} />,
  InProcess: <Icon as={EmrInProcess} />,
  Ordered: <Icon as={EmrRegistration} />,
  NeedsApproval: <Icon as={ExclamationSquare} />,
  CancellationRequested: <Icon as={AuditComment} />,
  Administered: <Icon as={SquaredChecked} />,
  Prepared: <Icon as={EmrRegistration} />,
};

export { ORDER_STATUS_COLOR, ORDER_STATUS_ICON };
