import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type useUpdateOrderNotesPayload = {
  notes: string;
};

function useUpdateOrderNotes(orderId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["putNotes", { orderId }],
    (payload: useUpdateOrderNotesPayload) =>
      axiosOrderInstance.put(`v1/notes/${orderId}`, payload),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["orderNotes", { orderId }]),
    }
  );
}

export type { useUpdateOrderNotesPayload };
export { useUpdateOrderNotes };
