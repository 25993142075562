import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";
import { PROCEDURES_GRID_COLUMNS } from "modules/provider/constants";

function S012Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q001.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q012`}
          subsectionLabel="Intubation (Long)"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q012`
            )
          }
        >
          Intubation (Long)
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Date & Time:"
                name={`${sectionDelimiter}Q012A001`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A002`}
                type="date"
                w="220px"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Prep:"
                name={`${sectionDelimiter}Q012A003`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="indication for intubation:"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A004`}
                type="option"
              />
              <ChartRowElement
                label="respiratory failure"
                name={`${sectionDelimiter}Q012A005`}
                type="option"
              />
              <ChartRowElement
                label="airway protection"
                name={`${sectionDelimiter}Q012A006`}
                type="option"
              />
              <ChartRowElement
                label="arrest"
                name={`${sectionDelimiter}Q012A007`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A008`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="setup:"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A009`}
                type="option"
              />
              <ChartRowElement
                label="suction set-up"
                name={`${sectionDelimiter}Q012A010`}
                type="option"
              />
              <ChartRowElement
                label="balloon tested"
                name={`${sectionDelimiter}Q012A011`}
                type="option"
              />
              <ChartRowElement
                label="BVM setup"
                name={`${sectionDelimiter}Q012A012`}
                type="option"
              />
              <ChartRowElement
                label="difficult airway equipment acquired"
                name={`${sectionDelimiter}Q012A013`}
                type="option"
              />
              <ChartRowElement
                label="c-spine precaution"
                name={`${sectionDelimiter}Q012A014`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A015`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A016`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Airway assessment:"
                name={`${sectionDelimiter}Q012A017`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="profuse secretions"
                name={`${sectionDelimiter}Q012A018`}
                type="option"
              />
              <ChartRowElement
                label="onese"
                name={`${sectionDelimiter}Q012A019`}
                type="option"
              />
              <ChartRowElement
                label="large tongue"
                name={`${sectionDelimiter}Q012A020`}
                type="option"
              />
              <ChartRowElement
                label="large teeth"
                name={`${sectionDelimiter}Q012A021`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A022`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A023`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Pre-oxygenate patient:"
                name={`${sectionDelimiter}Q012A024`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="spontaneous breathing"
                name={`${sectionDelimiter}Q012A025`}
                type="option"
              />
              <ChartRowElement
                label="assisted via BVM"
                name={`${sectionDelimiter}Q012A026`}
                type="option"
              />
              <ChartRowElement
                label="pre-oxygenated with 100% FiO2"
                name={`${sectionDelimiter}Q012A027`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A028`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A029`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Pre-treatment:"
                name={`${sectionDelimiter}Q012A030`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="lidocaine"
                name={`${sectionDelimiter}Q012A031`}
                type="option"
              />
              <ChartRowElement
                label="fentanyl"
                name={`${sectionDelimiter}Q012A032`}
                type="option"
              />
              <ChartRowElement
                label="atropine"
                name={`${sectionDelimiter}Q012A033`}
                type="option"
              />
              <ChartRowElement
                label="defasciculating dose"
                name={`${sectionDelimiter}Q012A034`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A035`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A036`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Sedation:"
                name={`${sectionDelimiter}Q012A037`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="induction agent:"
                name={`${sectionDelimiter}Q012A038`}
                type="option"
              />
              <ChartRowElement
                label="etomidate"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A039`}
                type="option"
              />
              <ChartRowElement
                label="midazolam"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A040`}
                type="option"
              />
              <ChartRowElement
                label="propofol"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A041`}
                type="option"
              />
              <ChartRowElement
                label="other"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A042`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A043`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="paralytic:"
                name={`${sectionDelimiter}Q012A044`}
                type="option"
              />
              <ChartRowElement
                label="rocuronium"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A045`}
                type="option"
              />
              <ChartRowElement
                label="vecuronium"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A046`}
                type="option"
              />
              <ChartRowElement
                label="succinylcholine"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A047`}
                type="option"
              />
              <ChartRowElement
                label="other"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A048`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A049`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A050`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Paralytic caution:"
                name={`${sectionDelimiter}Q012A051`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="caution succinylcholine:"
                name={`${sectionDelimiter}Q012A052`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A053`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="no muscular dystrophy"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A054`}
                type="option"
              />
              <ChartRowElement
                label="paralysis"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A055`}
                type="option"
              />
              <ChartRowElement
                label="previous adverse reaction"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A056`}
                type="option"
              />
              <ChartRowElement
                label="known hyperkalemia"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A057`}
                type="option"
              />
              <ChartRowElement
                label="mialignant hyperthermia"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A058`}
                type="option"
              />
              <ChartRowElement
                label="myopathies"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A059`}
                type="option"
              />
              <ChartRowElement
                label="neuromuscular disorders"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A060`}
                type="option"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>
            <ChartRowGroup>
              <ChartRowElement
                label="burns > 48 hrs"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A061`}
                type="option"
              />
              <ChartRowElement
                label="muscle crush injury"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A062`}
                type="option"
              />
              <ChartRowElement
                label="denervation"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A063`}
                type="option"
              />
              <ChartRowElement
                label="intraabdominal sepsis"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A064`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A065`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A066`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Protection:"
                name={`${sectionDelimiter}Q012A067`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="circoid pressure applied"
                name={`${sectionDelimiter}Q012A068`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A069`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Place tube:"
                name={`${sectionDelimiter}Q012A070`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="intubation type:"
                name={`${sectionDelimiter}Q012A071`}
                type="option"
              />
              <ChartRowElement
                label="oral"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A072`}
                type="option"
              />
              <ChartRowElement
                label="nasal"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A073`}
                type="option"
              />
              <ChartRowElement
                label="surgical"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A074`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A075`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="equipment:"
                name={`${sectionDelimiter}Q012A076`}
                type="option"
              />
              <ChartRowElement
                label="ETT size"
                name={`${sectionDelimiter}Q012A077`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A078`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="mm"
                name={`${sectionDelimiter}Q012A079`}
                type="option"
              />
              <ChartRowElement
                label="blade"
                name={`${sectionDelimiter}Q012A080`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A081`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="other"
                name={`${sectionDelimiter}Q012A082`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A083`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="attempts:"
                name={`${sectionDelimiter}Q012A084`}
                type="option"
              />
              <ChartRowElement
                label="1"
                name={`${sectionDelimiter}Q012A085`}
                type="option"
              />
              <ChartRowElement
                label="2"
                name={`${sectionDelimiter}Q012A086`}
                type="option"
              />
              <ChartRowElement
                label="3"
                name={`${sectionDelimiter}Q012A087`}
                type="option"
              />
              <ChartRowElement
                label="#"
                name={`${sectionDelimiter}Q012A088`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A089`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="failed attempt"
                name={`${sectionDelimiter}Q012A090`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A091`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="complications:"
                name={`${sectionDelimiter}Q012A092`}
                type="option"
              />
              <ChartRowElement
                label="none"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A093`}
                type="option"
              />
              <ChartRowElement
                label="see notes"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A094`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A095`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A096`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement
                label="Post-intubation management:"
                name={`${sectionDelimiter}Q012A097`}
                type="option"
              />
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="tube placement:"
                name={`${sectionDelimiter}Q012A098`}
                type="option"
              />
              <ChartRowElement
                label="nml capnometry"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A099`}
                type="option"
              />
              <ChartRowElement
                label="C02 change"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A100`}
                type="option"
              />
              <ChartRowElement
                label="chest rise"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A101`}
                type="option"
              />
              <ChartRowElement
                label="breath sounds bilaterally"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A102`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A103`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="incisor level:"
                name={`${sectionDelimiter}Q012A104`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A105`}
                type="input"
                w="100px"
              />
              <ChartRowElement
                label="cm"
                name={`${sectionDelimiter}Q012A106`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A107`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="CXR:"
                name={`${sectionDelimiter}Q012A108`}
                type="option"
              />
              <ChartRowElement
                label="tube in goodd position"
                name={`${sectionDelimiter}Q012A109`}
                type="option"
              />
              <ChartRowElement
                label="other"
                name={`${sectionDelimiter}Q012A110`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A111`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement type="empty" />
              <ChartRowElement
                label="tube repositioned and confirmed"
                name={`${sectionDelimiter}Q012A112`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A113`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="Vent settings:"
                name={`${sectionDelimiter}Q012A114`}
                type="option"
              />
              <ChartRowElement
                label="Per RT"
                name={`${sectionDelimiter}Q012A115`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A116`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement type="empty" />
              <ChartRowElement
                label="mode:"
                name={`${sectionDelimiter}Q012A117`}
                type="option"
              />
              <ChartRowElement
                label="CMV"
                name={`${sectionDelimiter}Q012A118`}
                type="option"
              />
              <ChartRowElement
                label="AC"
                name={`${sectionDelimiter}Q012A119`}
                type="option"
              />
              <ChartRowElement
                label="SIMV"
                name={`${sectionDelimiter}Q012A120`}
                type="option"
              />
              <ChartRowElement
                label="PS"
                name={`${sectionDelimiter}Q012A121`}
                type="option"
              />
              <ChartRowElement
                label="CPAP"
                name={`${sectionDelimiter}Q012A122`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A123`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="TV:"
                name={`${sectionDelimiter}Q012A124`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A125`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="FIO2"
                name={`${sectionDelimiter}Q012A126`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A127`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="R"
                name={`${sectionDelimiter}Q012A128`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A129`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="PS"
                name={`${sectionDelimiter}Q012A130`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A131`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                label="PEEP"
                name={`${sectionDelimiter}Q012A132`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A133`}
                type="input"
                w="200px"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A134`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="sedation:"
                name={`${sectionDelimiter}Q012A135`}
                type="option"
              />
              <ChartRowElement
                label="propofol drip"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A136`}
                type="option"
              />
              <ChartRowElement
                label="midazolam"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A137`}
                type="option"
              />
              <ChartRowElement
                label="pentobarbital"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A138`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A139`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                label="paralytic:"
                name={`${sectionDelimiter}Q012A140`}
                type="option"
              />
              <ChartRowElement
                label="vecuronium"
                fontStyle="italic"
                name={`${sectionDelimiter}Q012A141`}
                type="option"
              />
              <ChartRowElement
                name={`${sectionDelimiter}Q012A142`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>

          <ChartRow gridTemplateColumns={PROCEDURES_GRID_COLUMNS}>
            <ChartRowGroup>
              <ChartRowElement type="empty" />
            </ChartRowGroup>

            <ChartRowGroup>
              <ChartRowElement
                name={`${sectionDelimiter}Q012A143`}
                type="input"
              />
            </ChartRowGroup>
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S012Procedure };
