import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";

function S004Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q004.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q004`}
          subsectionLabel="Cardioversion"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q004`
            )
          }
        >
          Cardioversion
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="discussed risks, benefits, options;"
              type="option"
              name={`${sectionDelimiter}Q004A001`}
            />
            <ChartRowElement
              label="patient"
              name={`${sectionDelimiter}Q004A002`}
              type="option"
            />
            <ChartRowElement
              label="parent"
              name={`${sectionDelimiter}Q004A003`}
              type="option"
            />
            <ChartRowElement
              label="guardian"
              name={`${sectionDelimiter}Q004A004`}
              type="option"
            />
            <ChartRowElement
              label="consent obtained"
              name={`${sectionDelimiter}Q004A005`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A006`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="cardioversion at"
              name={`${sectionDelimiter}Q004A007`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A008`}
              w="220px"
              type="input"
            />
            <ChartRowElement
              label="J"
              type="option"
              name={`${sectionDelimiter}Q004A009`}
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A010`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="post cardioversion rate"
              name={`${sectionDelimiter}Q004A011`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A012`}
              w="220px"
              type="input"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A013`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="post cardioversion rythm"
              name={`${sectionDelimiter}Q004A014`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A015`}
              w="220px"
              type="input"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A016`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="NSR"
              name={`${sectionDelimiter}Q004A017`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A018`}
              w="100px"
              type="input"
            />
            <ChartRowElement
              label="a-fib"
              name={`${sectionDelimiter}Q004A019`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A020`}
              w="100px"
              type="input"
            />
            <ChartRowElement
              label="a-flutter"
              name={`${sectionDelimiter}Q004A021`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A022`}
              w="100px"
              type="input"
            />
            <ChartRowElement
              label="V-tach"
              name={`${sectionDelimiter}Q004A023`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A024`}
              w="100px"
              type="input"
            />
            <ChartRowElement
              label="V-fib"
              name={`${sectionDelimiter}Q004A025`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q004A026`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q004A027`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S004Procedure };
