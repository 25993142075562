import * as React from "react";
import { useToast } from "hooks";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import {
  Badge,
  Box,
  BoxProps,
  Button,
  chakra,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tooltip,
  VStack,
  Text,
  Image,
} from "@chakra-ui/react";
import {
  EmrForm,
  EmrInProcess,
  EmrLabs,
  EmrProfileComplete,
  EmrRegistration,
  EmrSignature,
} from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapAnswersListToFormObject,
  mapFormObjectToAnswersList,
  OrderChartSectionHeader,
  useSaveAndNext,
} from "modules/charts-shared";
import { ChartRouteBaseParams } from "modules/charts-shared/types";
import { ChartForm } from "modules/charts-shared-v2";
import {
  useOrderChart,
  UseOrderChartResponseItem,
  useUpdateOrderChart,
} from "modules/provider/api";
import { BaseProviderChartProps } from "modules/provider/types";
import { FormPromptWithSaveProps } from "shared/prompt";
import {
  PdfViewer,
  PdfViewerActions,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerHeader,
  PdfViewerPageIndicator,
  PdfViewerPreview,
} from "shared/pdf-viewer";
import { Avatar, Card, Loading, StaffAvatar } from "shared";
import { useEncounterOrderFile } from "modules/orders/api";
import { formMessages } from "messages";
import { enumMapper, extractApiErrorMessage } from "utils";
import { LabsChartItem } from "types/chart/labs-chart";
import { LABS_GRID_COLUMNS } from "./constants";
import { OrderStatus } from "enums";
import { ORDER_STATUS_COLOR, OrderHeaderCard } from "modules/orders";
import {
  Collapse,
  EditSquared,
  Expand,
  RightArrow,
  SquaredChecked,
} from "icons";
import { useGetUserSignature } from "modules/onboarding/api";
import { useAuth0 } from "@auth0/auth0-react";
import { USERID_CLAIMS } from "system-constants";
import { UserType } from "modules/identity";
import { useUserRoles } from "contexts/UserRoles";

const SECTION_CODE = "SLAB";

const GRID_PROPS: BoxProps = {
  padding: "1rem 1.5rem",
  textAlign: "center",
  display: "grid",
  gridTemplateColumns: "5fr 11fr 12fr 25fr 25fr 3fr 3fr 3fr 8fr 3fr",
  gridColumnGap: "1rem",
  alignItems: "center",
};

type LabsChartForm = {
  items: LabsChartItem[];
};

type LabsChartFileProps = {
  file: Blob | undefined;
  orderNumber: number;
  isFileMode: boolean;
  toggleFileMode: () => void;
};

function LabsChartFile({
  file,
  orderNumber,
  isFileMode,
  toggleFileMode,
}: LabsChartFileProps) {
  return (
    <PdfViewer value={file}>
      <PdfViewerContainer
        height={isFileMode ? "80vh" : "40vh"}
        marginBottom="29px"
        activePage={<PdfViewerActivePage pageWidth={isFileMode ? 1200 : 800} />}
        header={
          <PdfViewerHeader
            title={`Lab Report ${!!orderNumber ? `# ${orderNumber}` : ""}`}
            leftElements={[
              <IconButton
                key="expand-collapse-button"
                aria-label="submit document"
                icon={<Icon as={isFileMode ? Collapse : Expand} />}
                color="blue"
                variant="icon"
                onClick={() => toggleFileMode()}
              />,
              <PdfViewerPageIndicator key="page-indicator" />,
            ]}
            rightElements={[
              <PdfViewerActions
                key="viewer-actions"
                showSubmit={false}
                showDelete={false}
              />,
            ]}
          />
        }
        preview={<PdfViewerPreview />}
      />
    </PdfViewer>
  );
}

type LabsCountersProps = {
  filter: OrderStatus | null;
  setFilter: (filter: OrderStatus | null) => void;
  useFormContext: UseFormReturn<LabsChartForm>;
};

function LabsCounters({
  filter,
  setFilter,
  useFormContext,
}: LabsCountersProps) {
  const { watch } = useFormContext;

  const subsections = watch("items");

  const handleSetFilter = (newFilter: OrderStatus) => {
    if (filter === newFilter) {
      setFilter(null);
    } else {
      setFilter(newFilter);
    }
  };

  const getCounter = React.useCallback(
    (status: string) => {
      let counter = 0;
      if (subsections) {
        counter = subsections?.filter(
          (subsection) => subsection?.order.status === status
        ).length;
      }
      return counter;
    },
    [subsections]
  );

  return (
    <Flex columnGap="30px">
      <OrderHeaderCard
        title="Ordered"
        icon={<Icon as={EmrRegistration} />}
        bg={filter === "Ordered" || filter === null ? "orange" : "white"}
        color={filter === "Ordered" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("Ordered")}
        counter={getCounter("Ordered")}
        flex={1}
      />
      <OrderHeaderCard
        title="In Process"
        icon={<Icon as={EmrInProcess} />}
        bg={filter === "InProcess" || filter === null ? "blue" : "white"}
        color={filter === "InProcess" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("InProcess")}
        counter={getCounter("InProcess")}
        flex={1}
      />
      <OrderHeaderCard
        title="Completed"
        icon={<Icon as={SquaredChecked} />}
        bg={filter === "Completed" || filter === null ? "green" : "white"}
        color={filter === "Completed" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("Completed")}
        counter={getCounter("Completed")}
        flex={1}
      />
    </Flex>
  );
}

function LabsHeaders() {
  return (
    <Card bg="gray.250" marginBottom="1rem" boxShadow="none" {...GRID_PROPS}>
      <chakra.span>
        <Icon
          as={EmrProfileComplete}
          w="1.25rem"
          h="1.25rem"
          color="gray.650"
        />
      </chakra.span>
      <chakra.span>Date / Time</chakra.span>
      <chakra.span>Order ID</chakra.span>
      <chakra.span textAlign="center">Order</chakra.span>
      <chakra.span textAlign="left">Order Notes</chakra.span>
      <chakra.span>Report</chakra.span>
      <chakra.span>Interpretation</chakra.span>
      <chakra.span>Signature</chakra.span>
      <chakra.span>Status</chakra.span>
      <chakra.span></chakra.span>
    </Card>
  );
}

type LabItemProps = {
  item: UseOrderChartResponseItem;
  index: number;
  encounterId: string;
  chartCode: string;
  isReadOnly?: boolean;
  useFormContext: UseFormReturn<LabsChartForm>;
  hideInterpretations?: boolean;
};

type LabItemSignatureProps = Pick<LabItemProps, "index" | "useFormContext">;

function LabItemSignature(props: LabItemSignatureProps) {
  const { index, useFormContext } = props;

  const { watch, setValue } = useFormContext;

  const signedBy = watch(`items.${index}.signedBy`);

  const { data: signature } = useGetUserSignature(signedBy || "");

  const { user } = useAuth0();

  const handleSign = () =>
    setValue(`items.${index}.signedBy`, user?.[USERID_CLAIMS], {
      shouldDirty: true,
    });

  const { roles } = useUserRoles();

  const isAllowedToSign = React.useMemo(() => {
    const rolesAllowedToViewOpenChartCounter: UserType[] = [
      "Administrator",
      "SupervisingProvider",
      "Provider",
    ];

    return roles.some((x) => rolesAllowedToViewOpenChartCounter.includes(x));
  }, [roles]);

  return (
    <Box height="100px" p={6} width="100%" bgColor="white" borderRadius={20}>
      <HStack width="100%" spacing={6}>
        <HStack display="flex" justifyContent="start" spacing={4} flex={2}>
          <Text fontWeight="600" fontSize="18px">
            Provider Signature
          </Text>
          <Text fontWeight="600" fontSize="16px" color="red">
            * Required to Complete
          </Text>
        </HStack>
        {!!signedBy ? (
          <Flex justifyContent="center" flex={1}>
            <HStack>
              <StaffAvatar
                w="40px"
                h="40px"
                size="xs"
                fontSize=".75rem"
                userName={signature?.data?.fullName}
                profileUrl={signature?.data?.pictureUrl}
              />
              <VStack spacing="0" alignItems="flex-start">
                <Box
                  fontSize="1.0625rem"
                  fontWeight="600"
                  lineHeight="1.5rem"
                  color="black"
                >
                  {signature?.data?.fullName}
                </Box>
                <Box
                  fontSize="0.875rem"
                  fontWeight="400"
                  lineHeight="1rem"
                  color="gray.700"
                >
                  {signature?.data?.teams}
                </Box>
              </VStack>
            </HStack>
          </Flex>
        ) : (
          <Text>No signature added</Text>
        )}
        <HStack
          pl={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={40}
          flex={2}
        >
          <HStack spacing={10}>
            {!!signedBy ? (
              <>
                <Text fontWeight="600" fontSize="16px" color="gray.700">
                  E-signed by:
                </Text>
                <Box minW={300} maxW={400} minH={50}>
                  <Image
                    src={signature?.data?.signature}
                    height="100%"
                    width="100%"
                  />
                </Box>
              </>
            ) : (
              <Tooltip
                isOpen={!isAllowedToSign}
                label="Only Providers are allowed to sign"
              >
                <Button
                  variant="label"
                  color="blue"
                  onClick={handleSign}
                  disabled={!isAllowedToSign}
                >
                  <Icon
                    as={EmrSignature}
                    fontSize="1.5rem"
                    color="blue"
                    m={2}
                  />
                  Add Signature
                </Button>
              </Tooltip>
            )}
          </HStack>
        </HStack>
      </HStack>
    </Box>
  );
}

function LabItem({
  chartCode,
  encounterId,
  item,
  index,
  isReadOnly,
  useFormContext,
  hideInterpretations = false,
}: LabItemProps) {
  const location = useLocation();

  const fromSummary = location.pathname.includes("medical-records");

  const [isExpanded, setIsExpanded] = React.useState(fromSummary);

  const { watch } = useFormContext;

  const [isFileMode, setFileMode] = React.useState(false);
  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const hasInterpretation = item.answers
    ? item.answers
        .filter((x) => x.qCode.includes("C001"))
        .some((x) => Boolean(x.value))
    : false;

  const { data: file } = useEncounterOrderFile(item.orderId);

  const signedBy = watch(`items.${index}.signedBy`);

  return (
    <Box mb="10px">
      <Card boxShadow="none">
        <Box {...GRID_PROPS}>
          <Avatar
            src={""}
            width="1.75rem"
            height="1.75rem"
            justifySelf="center"
          />
          <chakra.span>
            <ChartRowElement
              isDisabled={isReadOnly}
              type="date"
              name={`items[${index}].C001x${SECTION_CODE}Q001A001`}
            />
          </chakra.span>
          <chakra.span textAlign="center">{item.order.orderId}</chakra.span>
          <chakra.span textAlign="left">{item.order.description}</chakra.span>
          <chakra.span textAlign="left">{item.order.notes}</chakra.span>
          <Icon
            as={EmrForm}
            fontSize="1.5rem"
            color={Boolean(file?.data) ? "green" : "gray.400"}
            onClick={isExpanded ? undefined : toggleExpand}
            cursor="pointer"
          />
          <Icon
            as={EditSquared}
            fontSize="1.5rem"
            color={Boolean(hasInterpretation) ? "green" : "gray.400"}
            onClick={isExpanded ? undefined : toggleExpand}
            cursor="pointer"
          />
          <Icon
            as={EmrSignature}
            fontSize="1.5rem"
            color={!!signedBy ? "green" : "gray.400"}
            onClick={toggleExpand}
            cursor="pointer"
          />
          <Badge
            bgColor={ORDER_STATUS_COLOR[item.order.status || "InProcess"]}
            fontWeight="700"
            fontSize="15px"
            color="white"
            borderRadius="20px"
            padding="0.5rem 0.5rem"
            textTransform="capitalize"
            width="100%"
            textAlign="center"
          >
            {enumMapper.toDisplay(
              "orderStatus",
              item.order.status || "InProcess"
            )}
          </Badge>
          <Icon
            as={RightArrow}
            w={6}
            h={6}
            fontSize="1.5rem"
            onClick={toggleExpand}
            cursor="pointer"
            color="blue"
            transform={!isExpanded ? "rotate(90deg)" : "rotate(270deg)"}
          />
        </Box>
      </Card>

      <Box
        sx={{
          height: isExpanded ? "auto" : "0",
          overflow: "hidden",
          margin: "5px",
        }}
      >
        <LabsChartFile
          file={file?.data}
          orderNumber={item.orderNumber}
          isFileMode={isFileMode}
          toggleFileMode={() => setFileMode(!isFileMode)}
        />

        <LabItemSignature index={index} useFormContext={useFormContext} />

        {!isFileMode && !hideInterpretations && (
          <>
            <ChartSubsection
              header={
                <ChartSubsectionHeader
                  chartId={chartCode}
                  encounterId={encounterId}
                  sectionId={SECTION_CODE}
                  subsectionId="Q001"
                  subsectionLabel="CBC"
                  subsectionNote="*Only select abnormalities"
                  marginTop="1rem"
                  subsectionEndOptions={
                    <ChartRow>
                      <ChartRowGroup>
                        <ChartRowElement
                          type="switch"
                          name={`items[${index}].wbcSwitch`}
                        />
                        <ChartRowElement type="empty" />
                      </ChartRowGroup>
                    </ChartRow>
                  }
                >
                  CBC
                </ChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent
                  sx={{
                    height: watch(`items.${index}.wbcSwitch`) ? "auto" : "0",
                    overflow: "hidden",
                    p: watch(`items.${index}.wbcSwitch`) ? undefined : "0",
                  }}
                >
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="WBC"
                        name={`items[${index}].wbc`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].wbcNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Hgb"
                        name={`items[${index}].hgb`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].hgbNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Hct"
                        name={`items[${index}].hct`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].hctNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Platelets"
                        name={`items[${index}].platelets`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].plateletsNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="segs"
                        name={`items[${index}].segs`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].segsNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="bands"
                        name={`items[${index}].bands`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].bandsNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="lymphs"
                        name={`items[${index}].lymphs`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].lymphsNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].cbcExtraOne`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].cbcExtraOneNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].cbcExtraTwo`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].cbcExtraTwoNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <ChartSubsectionHeader
                  chartId={chartCode}
                  encounterId={encounterId}
                  sectionId={SECTION_CODE}
                  subsectionId="Q002"
                  subsectionLabel="Chemistries"
                  subsectionNote="*Only select abnormalities"
                  marginTop="1rem"
                  subsectionEndOptions={
                    <ChartRow>
                      <ChartRowGroup>
                        <ChartRowElement
                          type="switch"
                          name={`items[${index}].chemistriesSwitch`}
                        />
                        <ChartRowElement type="empty" />
                      </ChartRowGroup>
                    </ChartRow>
                  }
                >
                  Chemistries
                </ChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent
                  sx={{
                    height: watch(`items.${index}.chemistriesSwitch`)
                      ? "auto"
                      : "0",
                    overflow: "hidden",
                    p: watch(`items.${index}.chemistriesSwitch`)
                      ? undefined
                      : "0",
                  }}
                >
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="NA"
                        name={`items[${index}].na`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].naNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="K"
                        name={`items[${index}].k`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].kNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="CO2"
                        name={`items[${index}].co2`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].co2Notes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Gluc"
                        name={`items[${index}].gluc`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].glucNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Bun"
                        name={`items[${index}].bun`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].bunNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Creat"
                        name={`items[${index}].creat`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].creatNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].chemistriesExtraOne`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].chemistriesExtraOneNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].chemistriesExtraTwo`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].chemistriesExtraTwoNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <ChartSubsectionHeader
                  chartId={chartCode}
                  encounterId={encounterId}
                  sectionId={SECTION_CODE}
                  subsectionId="Q003"
                  subsectionLabel="UA"
                  subsectionNote="*Only select abnormalities"
                  marginTop="1rem"
                  subsectionEndOptions={
                    <ChartRow>
                      <ChartRowGroup>
                        <ChartRowElement
                          type="switch"
                          name={`items[${index}].uaSwitch`}
                        />
                        <ChartRowElement type="empty" />
                      </ChartRowGroup>
                    </ChartRow>
                  }
                >
                  UA
                </ChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent
                  sx={{
                    height: watch(`items.${index}.uaSwitch`) ? "auto" : "0",
                    overflow: "hidden",
                    p: watch(`items.${index}.uaSwitch`) ? undefined : "0",
                  }}
                >
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="WBC"
                        name={`items[${index}].uaWbc`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].uaWbcNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="RBC"
                        name={`items[${index}].uaRbc`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].uaRbcNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="bacteria"
                        name={`items[${index}].uaBacteria`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].uaBacteriaNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="dip"
                        name={`items[${index}].uaDip`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].uaDipNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].uaExtraOne`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].uaExtraOneNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].uaExtraTwo`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].uaExtraTwoNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <ChartSubsectionHeader
                  chartId={chartCode}
                  encounterId={encounterId}
                  sectionId={SECTION_CODE}
                  subsectionId="Q004"
                  subsectionLabel="Other"
                  subsectionNote="*Only select abnormalities"
                  marginTop="1rem"
                  subsectionEndOptions={
                    <ChartRow>
                      <ChartRowGroup>
                        <ChartRowElement
                          type="switch"
                          name={`items[${index}].otherSwitch`}
                        />
                        <ChartRowElement type="empty" />
                      </ChartRowGroup>
                    </ChartRow>
                  }
                >
                  Other
                </ChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent
                  sx={{
                    height: watch(`items.${index}.otherSwitch`) ? "auto" : "0",
                    overflow: "hidden",
                    p: watch(`items.${index}.otherSwitch`) ? undefined : "0",
                  }}
                >
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="acetaminophen"
                        name={`items[${index}].acetaminophen`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].acetaminophenNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Alk Phos"
                        name={`items[${index}].alkPhos`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].alkPhosNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="ammonia"
                        name={`items[${index}].ammonia`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].ammoniaNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Amylase"
                        name={`items[${index}].amylase`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].amylaseNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="aspirin"
                        name={`items[${index}].aspirin`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].aspirinNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="AST"
                        name={`items[${index}].ast`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].astNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="ALT"
                        name={`items[${index}].alt`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].altNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="bacteria"
                        name={`items[${index}].bacteria`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].bacteriaNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="BNP"
                        name={`items[${index}].bnp`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].bnpNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="CK"
                        name={`items[${index}].ck`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].ckNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="CKMB"
                        name={`items[${index}].ckmb`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].ckmbNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="COVID-19 NAA"
                        name={`items[${index}].covid19naa`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].covid19naaNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="COHb"
                        name={`items[${index}].cohb`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].cohbNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="CRP"
                        name={`items[${index}].crp`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].crpNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="D-Dimer"
                        name={`items[${index}].ddimer`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].ddimerNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="DFA / IFA"
                        name={`items[${index}].dfaIfa`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].dfaIfaNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="dip:"
                        name={`items[${index}].dip`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].dipInput`}
                      />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].dipNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="ETOH"
                        name={`items[${index}].etoh`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].etohNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="ESR"
                        name={`items[${index}].esr`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].esrNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Flu Screen"
                        name={`items[${index}].fluScreen`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].fluScreenNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="HCG"
                        name={`items[${index}].hcg`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="serum"
                        name={`items[${index}].serum`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="urine"
                        name={`items[${index}].urine`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].hcgNotesSerumUrine`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="HCG"
                        name={`items[${index}].hcg`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="pos"
                        name={`items[${index}].pos`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="neg"
                        name={`items[${index}].neg`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].hcgNotesPosNeg`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="INR"
                        name={`items[${index}].inr`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].inrNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Lactate"
                        name={`items[${index}].lactate`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].lactateNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Lipase"
                        name={`items[${index}].lipase`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].lipaseNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Mono Spot"
                        name={`items[${index}].monoSpot`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].monoSpotNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Nitrazine"
                        name={`items[${index}].nitrazine`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="pos"
                        name={`items[${index}].pos`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="neg"
                        name={`items[${index}].neg`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].nitrazineNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="prot"
                        name={`items[${index}].prot`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].protNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="PT / PTT"
                        name={`items[${index}].ptptt`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].ptpttNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="RBC"
                        name={`items[${index}].rbc`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].rbcNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Rh"
                        name={`items[${index}].rh`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].rhNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="RIDT"
                        name={`items[${index}].ridt`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].ridtNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="RMA"
                        name={`items[${index}].rma`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].rmaNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Rotavirus"
                        name={`items[${index}].rotavirus`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].rotavirusNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="RSV"
                        name={`items[${index}].rsv`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].rsvNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="RT-PCR"
                        name={`items[${index}].rtpcr`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].rtpcrNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Strep Screen"
                        name={`items[${index}].strepScreen`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].strepScreenNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="TOX"
                        name={`items[${index}].tox`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].toxNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="Troponin"
                        name={`items[${index}].troponin`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].troponinNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="TSH"
                        name={`items[${index}].tsh`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].tshNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="WBC"
                        name={`items[${index}].wbcThree`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].wbcThreeNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].otherExtraOne`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].otherExtraOneNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].otherExtraTwo`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].otherExtraTwoNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <ChartSubsectionHeader
                  chartId={chartCode}
                  encounterId={encounterId}
                  sectionId={SECTION_CODE}
                  subsectionId="Q005"
                  subsectionLabel="ABGs"
                  subsectionNote="*Only select abnormalities"
                  marginTop="1rem"
                  subsectionEndOptions={
                    <ChartRow>
                      <ChartRowGroup>
                        <ChartRowElement
                          type="switch"
                          name={`items[${index}].abgSwitch`}
                        />
                        <ChartRowElement type="empty" />
                      </ChartRowGroup>
                    </ChartRow>
                  }
                >
                  ABGs
                </ChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent
                  sx={{
                    height: watch(`items.${index}.abgSwitch`) ? "auto" : "0",
                    overflow: "hidden",
                    p: watch(`items.${index}.abgSwitch`) ? undefined : "0",
                  }}
                >
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="RA/"
                        name={`items[${index}].ra`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].raInput`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="LO2"
                        name={`items[${index}].lo2`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].raNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="pH"
                        name={`items[${index}].ph`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].phNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="pCO2"
                        name={`items[${index}].pCO2`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].pCO2Notes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="pO2"
                        name={`items[${index}].pO2`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].pO2Notes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].abgExtraOne`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].abgExtraOneNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].abgExtraTwo`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].abgExtraTwoNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <ChartSubsectionHeader
                  chartId={chartCode}
                  encounterId={encounterId}
                  sectionId={SECTION_CODE}
                  subsectionId="Q006"
                  subsectionLabel="Cultures"
                  subsectionNote="*Only select abnormalities"
                  marginTop="1rem"
                  subsectionEndOptions={
                    <ChartRow>
                      <ChartRowGroup>
                        <ChartRowElement
                          type="switch"
                          name={`items[${index}].culturesSwitch`}
                        />
                        <ChartRowElement type="empty" />
                      </ChartRowGroup>
                    </ChartRow>
                  }
                >
                  Cultures
                </ChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent
                  sx={{
                    height: watch(`items.${index}.culturesSwitch`)
                      ? "auto"
                      : "0",
                    overflow: "hidden",
                    p: watch(`items.${index}.culturesSwitch`) ? undefined : "0",
                  }}
                >
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="blood x"
                        name={`items[${index}].csBloodX`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].csBloodXInput`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].csBloodXNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="sputum"
                        name={`items[${index}].csSputum`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].csSputumNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="urine"
                        name={`items[${index}].urine`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].urineNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="cross-option"
                        label="viral"
                        name={`items[${index}].csViral`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].csViralNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].culturesExtraOne`}
                      />
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].culturesExtraOneNotes`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <ChartSubsectionHeader
                  chartId={chartCode}
                  encounterId={encounterId}
                  sectionId={SECTION_CODE}
                  subsectionId="Q007"
                  subsectionLabel="Peak Flow"
                  subsectionNote="*Only select abnormalities"
                  marginTop="1rem"
                  subsectionEndOptions={
                    <ChartRow>
                      <ChartRowGroup>
                        <ChartRowElement
                          type="switch"
                          name={`items[${index}].peakFlowSwitch`}
                        />
                        <ChartRowElement type="empty" />
                      </ChartRowGroup>
                    </ChartRow>
                  }
                >
                  Peak Flow
                </ChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent
                  sx={{
                    height: watch(`items.${index}.peakFlowSwitch`)
                      ? "auto"
                      : "0",
                    overflow: "hidden",
                    p: watch(`items.${index}.peakFlowSwitch`) ? undefined : "0",
                  }}
                >
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement type="empty" />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].peakFlowInputOne`}
                        width="100px"
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="ml"
                        name={`items[${index}].ml`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="pre treatment"
                        name={`items[${index}].preTreatment`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="on arrival"
                        name={`items[${index}].onArrival`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].peakFlowInputTwo`}
                        width="100px"
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="ml"
                        name={`items[${index}].ml`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="post treatment"
                        name={`items[${index}].postTreatment`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="at discharge"
                        name={`items[${index}].atDischarge`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].peakFlowInputThree`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <ChartSubsectionHeader
                  chartId={chartCode}
                  encounterId={encounterId}
                  sectionId={SECTION_CODE}
                  subsectionId="Q008"
                  subsectionLabel="Pulse Ox"
                  subsectionNote="*Only select abnormalities"
                  marginTop="1rem"
                  subsectionEndOptions={
                    <ChartRow>
                      <ChartRowGroup>
                        <ChartRowElement
                          type="switch"
                          name={`items[${index}].pulseOxSwitch`}
                        />
                        <ChartRowElement type="empty" />
                      </ChartRowGroup>
                    </ChartRow>
                  }
                >
                  Pulse Ox
                </ChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent
                  sx={{
                    height: watch(`items.${index}.pulseOxSwitch`)
                      ? "auto"
                      : "0",
                    overflow: "hidden",
                    p: watch(`items.${index}.pulseOxSwitch`) ? undefined : "0",
                  }}
                >
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].inputOne`}
                      />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].percentageInputOne`}
                        width="100px"
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="% on RA / "
                        name={`items[${index}].raOne`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].raInputOne`}
                        width="100px"
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="L O2"
                        name={`items[${index}].l02One`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="Interp:"
                        name={`items[${index}].interpOne`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="normal"
                        name={`items[${index}].normalOne`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="hypoxic"
                        name={`items[${index}].hypoxicOne`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].notesOne`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].inputTwo`}
                      />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].percentageInputTwo`}
                        width="100px"
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="% on RA / "
                        name={`items[${index}].raTwo`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].raInputTwo`}
                        width="100px"
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="L O2"
                        name={`items[${index}].l02Two`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="Interp:"
                        name={`items[${index}].interpTwo`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="normal"
                        name={`items[${index}].normalTwo`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="hypoxic"
                        name={`items[${index}].hypoxicTwo`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].notesTwo`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                  <ChartRow gridTemplateColumns={LABS_GRID_COLUMNS}>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].inputThree`}
                      />
                    </ChartRowGroup>
                    <ChartRowGroup>
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].percentageInputThree`}
                        width="100px"
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="% on RA / "
                        name={`items[${index}].raThree`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].raInputThree`}
                        width="100px"
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="L O2"
                        name={`items[${index}].l02Three`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="Interp:"
                        name={`items[${index}].interpThree`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="normal"
                        name={`items[${index}].normalThree`}
                      />
                      <ChartRowElement
                        type="cross-option"
                        label="hypoxic"
                        name={`items[${index}].hypoxicThree`}
                      />
                      <ChartRowElement
                        type="input"
                        name={`items[${index}].notesThree`}
                      />
                    </ChartRowGroup>
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
          </>
        )}
      </Box>
    </Box>
  );
}

type LabsChartProps = {
  hideInterpretations?: boolean;
} & BaseProviderChartProps;

function LabsChart(props: LabsChartProps) {
  const toast = useToast();
  const { chartCode, isReadOnly, hideInterpretations = false } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<LabsChartForm>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const [filter, setFilter] = React.useState<OrderStatus | null>(null);

  const { data, isLoading } = useOrderChart({
    encounterId,
    chartCode,
    orderType: "Labs",
  });
  const { mutateAsync: updateChart, isLoading: isUpdating } =
    useUpdateOrderChart({
      encounterId,
      chartCode,
      orderType: "Labs",
    });

  const saveData = React.useCallback(
    async (values: LabsChartForm) => {
      const payload: UseOrderChartResponseItem[] = values.items.map((item) => {
        const { orderId, orderNumber, ...answers } = item;
        return {
          orderId,
          orderNumber,
          answers: mapFormObjectToAnswersList({ ...answers }),
        } as UseOrderChartResponseItem;
      });
      await updateChart(payload);
    },
    [updateChart]
  );

  const onSubmit: SubmitHandler<LabsChartForm> = React.useCallback(
    async (values) => {
      try {
        if (values.items) {
          await saveData(values);
        }
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("Labs") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [saveData, reset, toast]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "labs",
    "provider"
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      await saveData(getValues());
      toast({ description: formMessages.updateSuccess("Labs") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const result = data.data.map((value) => {
        return {
          orderId: value.orderId,
          order: value.order,
          ...mapAnswersListToFormObject(value.answers),
        };
      });
      reset({ items: result });
    }
  }, [data, reset]);

  const filteredSubsections = React.useMemo(() => {
    if (!data?.data) {
      return [];
    }

    return data.data.filter((subsection) => {
      if (filter) {
        return subsection.order.status === filter;
      }
      return true;
    });
  }, [data, filter]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={isUpdating}
        disabled={isReadOnly}
      >
        <OrderChartSectionHeader
          icon={<Icon as={EmrLabs} />}
          chartId={chartCode}
          chartCode={chartCode}
          orderType="Labs"
          encounterId={encounterId}
          showNotesBtn
          showActionsBtns
          isLoading={isUpdating}
          onSaveAndNext={handleSaveAndNext}
        >
          Labs {isReadOnly ? "(Read Only)" : ""}
        </OrderChartSectionHeader>

        <LabsCounters
          filter={filter}
          setFilter={(newFilter) => setFilter(newFilter)}
          useFormContext={useFormContext}
        />

        <Box>
          <LabsHeaders />
          {filteredSubsections.map((row, index) => {
            return (
              <LabItem
                useFormContext={useFormContext}
                index={index}
                item={row}
                encounterId={encounterId}
                chartCode={chartCode}
                hideInterpretations={hideInterpretations}
              />
            );
          })}
        </Box>
      </ChartForm>
    </FormProvider>
  );
}

export { LabsChart };
