import { axiosPatientInstance } from "config";
import { Gender } from "enums";
import { useAxiosQuery } from "hooks";

type UseEncounterSideInfoResponse = {
  encounterId: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: Gender;
  ssn: string;
  mrn: string;
  collaborateReference: string;
  encounterNumber: string;
  isVip: boolean;
  isDup: boolean;
  nurseDisposition: boolean;
  providerDisposition: boolean;
};

function useEncounterSideInfo(
  encounterId: string,
  options?: { enabled: boolean }
) {
  return useAxiosQuery(
    ["encounterSideInfo", { encounterId }],
    () =>
      axiosPatientInstance.get<UseEncounterSideInfoResponse>(
        `v1/Encounter/${encounterId}/side-info`
      ),
    { ...options }
  );
}

export type { UseEncounterSideInfoResponse };
export { useEncounterSideInfo };
