import { AllergyRxType, MedicationRxType } from "enums";

const ALLERGY_RX_TYPE_OPTIONS: AllergyRxType[] = ["DRUG_AL", "OTHER_AL"];

const ALLERGY_RX_TYPE_LABEL: Record<AllergyRxType, string> = {
  DRUG_AL: "Drug Allergies",
  OTHER_AL: "Food  & Other Allergies",
};

const MEDICATION_RX_TYPE_OPTIONS: MedicationRxType[] = [
  "PRESCRIPTION_RX",
  "OTC_RX",
];

const MEDICATION_RX_TYPE_LABEL: Record<MedicationRxType, string> = {
  PRESCRIPTION_RX: "Prescription Medications",
  OTC_RX: "OTC Medications",
};

export type AllergyOption = {
  value: string;
  type: AllergyRxType;
};

export type ReactionOption = {
  value: string;
  type: AllergyRxType;
};

const ALLERGIES_OTHER_LABEL = "Other:";

const ALLERGIES_SELECT_OPTIONS: AllergyOption[] = [
  {
    value: "Penicillin G",
    type: "DRUG_AL",
  },
  {
    value: "Penicillin V",
    type: "DRUG_AL",
  },
  {
    value: "Amoxicillin",
    type: "DRUG_AL",
  },
  {
    value: "Ampicillin",
    type: "DRUG_AL",
  },
  {
    value: "Clavulanic Acid",
    type: "DRUG_AL",
  },
  {
    value: "Dicloxacillin",
    type: "DRUG_AL",
  },
  {
    value: "Nafcillin",
    type: "DRUG_AL",
  },
  {
    value: "Oxacillin",
    type: "DRUG_AL",
  },
  {
    value: "Sulfamethoxazole",
    type: "DRUG_AL",
  },
  {
    value: "Sulfadiazine",
    type: "DRUG_AL",
  },
  {
    value: "Sulfasalazine",
    type: "DRUG_AL",
  },
  {
    value: "Sulfacetamide",
    type: "DRUG_AL",
  },
  {
    value: "Trimethoprim",
    type: "DRUG_AL",
  },
  {
    value: "Sulfisoxazole",
    type: "DRUG_AL",
  },
  {
    value: "Silver sulfadiazine",
    type: "DRUG_AL",
  },
  {
    value: "Cephalexin",
    type: "DRUG_AL",
  },
  {
    value: "Cefazolin",
    type: "DRUG_AL",
  },
  {
    value: "Cefuroxime",
    type: "DRUG_AL",
  },
  {
    value: "Ceftriaxone",
    type: "DRUG_AL",
  },
  {
    value: "Cefixime",
    type: "DRUG_AL",
  },
  {
    value: "Cefaclor",
    type: "DRUG_AL",
  },
  {
    value: "Cefdinir",
    type: "DRUG_AL",
  },
  {
    value: "Cefepime",
    type: "DRUG_AL",
  },
  {
    value: "Cefotaxime",
    type: "DRUG_AL",
  },
  {
    value: "Ceftaroline",
    type: "DRUG_AL",
  },
  {
    value: "Ibuprofen",
    type: "DRUG_AL",
  },
  {
    value: "Naproxen",
    type: "DRUG_AL",
  },
  {
    value: "Aspirin",
    type: "DRUG_AL",
  },
  {
    value: "Celecoxib",
    type: "DRUG_AL",
  },
  {
    value: "Diclofenac",
    type: "DRUG_AL",
  },
  {
    value: "Indomethacin",
    type: "DRUG_AL",
  },
  {
    value: "Meloxicam",
    type: "DRUG_AL",
  },
  {
    value: "Ketorolac",
    type: "DRUG_AL",
  },
  {
    value: "Piroxicam",
    type: "DRUG_AL",
  },
  {
    value: "Etodolac",
    type: "DRUG_AL",
  },
  {
    value: "Iohexol",
    type: "DRUG_AL",
  },
  {
    value: "Iopamidol",
    type: "DRUG_AL",
  },
  {
    value: "Iodixanol",
    type: "DRUG_AL",
  },
  {
    value: "Barium sulfate",
    type: "DRUG_AL",
  },
  {
    value: "Gadopentetate",
    type: "DRUG_AL",
  },
  {
    value: "Gadobutrol",
    type: "DRUG_AL",
  },
  {
    value: "Gadoterate meglumine",
    type: "DRUG_AL",
  },
  {
    value: "Codeine",
    type: "DRUG_AL",
  },
  {
    value: "Morphine",
    type: "DRUG_AL",
  },
  {
    value: "Oxycodone",
    type: "DRUG_AL",
  },
  {
    value: "Hydrocodone",
    type: "DRUG_AL",
  },
  {
    value: "Hydromorphone",
    type: "DRUG_AL",
  },
  {
    value: "Fentanyl",
    type: "DRUG_AL",
  },
  {
    value: "Tramadol",
    type: "DRUG_AL",
  },
  {
    value: "Methadone",
    type: "DRUG_AL",
  },
  {
    value: "Buprenorphine",
    type: "DRUG_AL",
  },
  {
    value: "Meperidine",
    type: "DRUG_AL",
  },
  {
    value: "Propofol",
    type: "DRUG_AL",
  },
  {
    value: "Isoflurane",
    type: "DRUG_AL",
  },
  {
    value: "Sevoflurane",
    type: "DRUG_AL",
  },
  {
    value: "Desflurane",
    type: "DRUG_AL",
  },
  {
    value: "Ketamine",
    type: "DRUG_AL",
  },
  {
    value: "Thiopental",
    type: "DRUG_AL",
  },
  {
    value: "Bupivacaine",
    type: "DRUG_AL",
  },
  {
    value: "Mepivacaine",
    type: "DRUG_AL",
  },
  {
    value: "Procaine",
    type: "DRUG_AL",
  },
  {
    value: "Articaine",
    type: "DRUG_AL",
  },
  {
    value: "Benzocaine",
    type: "DRUG_AL",
  },
  {
    value: "Tetracaine",
    type: "DRUG_AL",
  },
  {
    value: "Lidocaine",
    type: "DRUG_AL",
  },
  {
    value: "Insulin lispro",
    type: "DRUG_AL",
  },
  {
    value: "Insulin aspart",
    type: "DRUG_AL",
  },
  {
    value: "Insulin glulisine",
    type: "DRUG_AL",
  },
  {
    value: "Insulin",
    type: "DRUG_AL",
  },
  {
    value: "Insulin detemir",
    type: "DRUG_AL",
  },
  {
    value: "Insulin glargine",
    type: "DRUG_AL",
  },
  {
    value: "Insulin degludec",
    type: "DRUG_AL",
  },
  {
    value: "Iodine Contrast",
    type: "DRUG_AL",
  },
  {
    value: "Sertraline Zoloft",
    type: "DRUG_AL",
  },
  {
    value: ALLERGIES_OTHER_LABEL,
    type: "DRUG_AL",
  },

  {
    value: "Peanut",
    type: "OTHER_AL",
  },
  {
    value: "Milk",
    type: "OTHER_AL",
  },
  {
    value: "Egg",
    type: "OTHER_AL",
  },
  {
    value: "Soy",
    type: "OTHER_AL",
  },
  {
    value: "Wheat",
    type: "OTHER_AL",
  },
  {
    value: "Tree Nut",
    type: "OTHER_AL",
  },
  {
    value: "Shellfish",
    type: "OTHER_AL",
  },
  {
    value: "Sesame",
    type: "OTHER_AL",
  },
  {
    value: ALLERGIES_OTHER_LABEL,
    type: "OTHER_AL",
  },
];

const REACTIONS_SELECT_OPTIONS: ReactionOption[] = [
  {
    value: "Nausea & vomiting",
    type: "DRUG_AL",
  },
  {
    value: "Dizziness or lightheadedness",
    type: "DRUG_AL",
  },
  {
    value: "Headache",
    type: "DRUG_AL",
  },
  {
    value: "Rash or skin reactions",
    type: "DRUG_AL",
  },
  {
    value: "Diarrhea or constipation",
    type: "DRUG_AL",
  },
  {
    value: "Abdominal pain",
    type: "DRUG_AL",
  },
  {
    value: "Fatigue",
    type: "DRUG_AL",
  },
  {
    value: "Insomnia or sleep disturbance",
    type: "DRUG_AL",
  },
  {
    value: "Itching, hives or swelling",
    type: "DRUG_AL",
  },
  {
    value: "Muscle pain or weakness",
    type: "DRUG_AL",
  },

  {
    value: "Itching or tingling in the mouth",
    type: "OTHER_AL",
  },
  {
    value: "Swelling of the lips, tongue or throat",
    type: "OTHER_AL",
  },
  {
    value: "Hives or rash",
    type: "OTHER_AL",
  },
  {
    value: "Nausea, stomach pain or diarrhea",
    type: "OTHER_AL",
  },
  {
    value: "Shortness of breath or wheezing",
    type: "OTHER_AL",
  },
  {
    value: "Anaphylaxis",
    type: "OTHER_AL",
  },
  {
    value: "Sneezing",
    type: "OTHER_AL",
  },
  {
    value: "Runny or stuffy nose",
    type: "OTHER_AL",
  },
  {
    value: "Itchy, watery eyes",
    type: "OTHER_AL",
  },
  {
    value: "Itchy throat or ears",
    type: "OTHER_AL",
  },
  {
    value: "Coughing or wheezing",
    type: "OTHER_AL",
  },
  {
    value: "Skin rash or hives",
    type: "OTHER_AL",
  },
  {
    value: "wheezing or difficulty breathing",
    type: "OTHER_AL",
  },
];

export {
  ALLERGY_RX_TYPE_OPTIONS,
  ALLERGY_RX_TYPE_LABEL,
  MEDICATION_RX_TYPE_OPTIONS,
  MEDICATION_RX_TYPE_LABEL,
  ALLERGIES_OTHER_LABEL,
  ALLERGIES_SELECT_OPTIONS,
  REACTIONS_SELECT_OPTIONS,
};
