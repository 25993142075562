import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";

function S021Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q021.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q021`}
          subsectionLabel="Treatment / Procedure"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q021`
            )
          }
        >
          Treatment / Procedure
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="date & time:"
              name={`${sectionDelimiter}Q021A001`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q021A002`}
              type="date"
              w="220px"
            />
            <ChartRowElement type="empty" />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="D50"
              name={`${sectionDelimiter}Q021A003`}
              type="option"
            />
            <ChartRowElement
              label="Narcan"
              name={`${sectionDelimiter}Q021A004`}
              type="option"
            />
            <ChartRowElement
              label="Thiamine"
              name={`${sectionDelimiter}Q021A005`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q021A006`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="Intubated"
              name={`${sectionDelimiter}Q021A007`}
              type="option"
            />
            <ChartRowElement
              label="by ED Physician"
              fontStyle="italic"
              name={`${sectionDelimiter}Q021A008`}
              type="option"
            />
            <ChartRowElement
              label="pre-oxygenated"
              name={`${sectionDelimiter}Q021A009`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q021A010`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="RSI protocol"
              name={`${sectionDelimiter}Q021A011`}
              type="option"
            />
            <ChartRowElement
              label="succinycholine"
              name={`${sectionDelimiter}Q021A012`}
              type="option"
            />
            <ChartRowElement
              label="vecuronium"
              name={`${sectionDelimiter}Q021A013`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q021A014`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="#"
              name={`${sectionDelimiter}Q021A015`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q021A016`}
              type="input"
              w="100px"
            />
            <ChartRowElement
              label="nasal"
              name={`${sectionDelimiter}Q021A017`}
              type="option"
            />
            <ChartRowElement
              label="oral"
              name={`${sectionDelimiter}Q021A018`}
              type="option"
            />
            <ChartRowElement
              label="breath sounds equal"
              name={`${sectionDelimiter}Q021A019`}
              type="option"
            />
            <ChartRowElement
              label="position confirmed on CXR"
              name={`${sectionDelimiter}Q021A020`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q021A021`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S021Procedure };
