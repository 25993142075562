import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  chakra,
} from "@chakra-ui/react";

type SingleActionDialogProps = {
  title: string;
  content: string | string[];
  actionLabel: string;
  actionStyles?: React.CSSProperties;
  isOpen: boolean;
  closeOverlayClick?: boolean;
  onClose: () => void;
  onAction?: () => void;
};

function SingleActionDialog(props: SingleActionDialogProps) {
  const {
    isOpen,
    title,
    content,
    actionLabel,
    onAction,
    closeOverlayClick = true,
    actionStyles = {
      color: "red",
    },
    onClose,
  } = props;

  const handleClose = () => {
    if (onAction) {
      onAction();
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={closeOverlayClick}
    >
      <ModalOverlay />
      <ModalContent width="275px">
        <ModalBody
          padding="0"
          margin="0"
          backgroundColor="#F7F7F7"
          borderRadius="5px"
        >
          <Box
            padding="1rem"
            display="flex"
            flexDir="column"
            textAlign="center"
            justifyContent="center"
          >
            <chakra.span fontWeight="600" fontSize="17px">
              {title}
            </chakra.span>
            {typeof content === "string" ? (
              <chakra.span fontSize="13px">{content}</chakra.span>
            ) : (
              content.map((contentLine, index) => (
                <chakra.span
                  key={index}
                  margin="1rem 0.5rem 0 0.5rem"
                  fontSize="13px"
                >
                  {contentLine}
                </chakra.span>
              ))
            )}
          </Box>
          <Box
            w="100%"
            display="flex"
            flexDir="row"
            borderTop="1px solid"
            borderColor="gray.450"
            justifyContent="space-around"
            textAlign="center"
            fontSize="17px"
          >
            <chakra.span
              style={actionStyles}
              cursor="pointer"
              p="0.45rem"
              w="100%"
              onClick={handleClose}
            >
              {actionLabel}
            </chakra.span>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { SingleActionDialog };
