import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { AmbulatoryNote } from "modules/vitals/types";

type UseAmbulatoryNotesProps = {
  encounterId: string;
};

function useAmbulatoryNotes({ encounterId }: UseAmbulatoryNotesProps) {
  return useAxiosQuery(["ambulatoryNotes", encounterId], () =>
    axiosChartInstance.get<AmbulatoryNote[]>(
      `v1/ambulatory-note/${encounterId}`
    )
  );
}

export { useAmbulatoryNotes };
export type { AmbulatoryNote };
