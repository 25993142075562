import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFormSubsectionPayload } from "modules/charts-shared";
import { useQueryClient } from "react-query";

function useUpdateMdm(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["chartApplication", { encounterId }],
    ({
      input,
      chartFavoriteId,
    }: {
      input: ChartFormSubsectionPayload[];
      chartFavoriteId?: string;
    }) =>
      axiosChartInstance.post(`v1/chart/${encounterId}/mdm`, input, {
        params: { chartFavoriteId },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(["mdm", { encounterId }]),
    }
  );
}

export { useUpdateMdm };
