import * as React from "react";
import {
  chakra,
  Icon,
  IconButton,
  Input,
  useNumberInput,
  Flex,
  useControllableState,
} from "@chakra-ui/react";
import { SiMinus, SiPlus } from "@medstonetech/slate-icons";
import { InputProps } from "shared/input";

type NumberSelectorProps = {
  value?: string | undefined;
  label?: string;
  defaultValue?: string | null;
  onChange?: (value?: string | null) => void;
} & Omit<InputProps, "onChange" | "type" | "value">;

const NumberSelector = React.forwardRef<HTMLInputElement, NumberSelectorProps>(
  (props, ref) => {
    const {
      value: valueProp,
      onChange,
      defaultValue,
      label,
      color,
      ...restInputProps
    } = props;

    const [value, setValue] = useControllableState({
      value: valueProp,
      onChange,
      defaultValue,
    });

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
      useNumberInput({
        step: 1,
        min: 0,
        max: 999,
        precision: 0,
        value: !!value ? value : 0,
        onChange: (currVal, _) => {
          setValue(currVal);
        },
      });

    const [isFieldsetDisabled, setIsFieldsetDisabled] = React.useState(false);

    const checkFieldset = React.useCallback((node: HTMLInputElement | null) => {
      if (node) {
        const fieldset = node.closest("fieldset");
        if (fieldset) {
          setIsFieldsetDisabled(fieldset.disabled);
        }
      }
    }, []);

    const input = getInputProps();
    const inc = isFieldsetDisabled ? {} : getIncrementButtonProps();
    const dec = isFieldsetDisabled ? {} : getDecrementButtonProps();

    return (
      <Flex direction="row" alignItems="center">
        <IconButton
          variant="icon"
          aria-label="decrease"
          icon={<Icon as={SiMinus} w="24px" h="24px" />}
          color="gray.700"
          w="40px"
          h="40px"
          opacity={isFieldsetDisabled ? 0.5 : 1}
          {...dec}
        />
        <Input
          bg="white"
          fontSize="1rem"
          fontWeight="600"
          textAlign="center"
          color={color}
          w="40px"
          p="0 2px"
          borderColor={color}
          {...restInputProps}
          {...input}
          ref={(node) => {
            checkFieldset(node);
            if (typeof ref === "function") ref(node);
            else if (ref) ref.current = node;
          }}
        />
        <IconButton
          variant="icon"
          aria-label="increase"
          icon={<Icon as={SiPlus} w="24px" h="24px" />}
          color="gray.700"
          w="40px"
          h="40px"
          opacity={isFieldsetDisabled ? 0.5 : 1}
          {...inc}
        />
        {!!label && (
          <chakra.span fontWeight="600" color={color} marginX="10px">
            {label}
          </chakra.span>
        )}
      </Flex>
    );
  }
);

NumberSelector.displayName = "NumberSelector";

export type { NumberSelectorProps };
export { NumberSelector };
