import * as React from "react";

import {
  BackButton,
  Button,
  NavigationButton,
  NavigationButtonContent,
  NavigationButtonIndicator,
  Sidebar,
  SidebarActions,
  SidebarBottom,
  SidebarContent,
  SidebarHeader,
  SidebarHeading,
  SidebarNavigation,
  SidebarUserView,
} from "shared";
import {
  Box,
  Icon,
  chakra,
  useControllableState,
  useDisclosure,
} from "@chakra-ui/react";
import {
  EmrBed,
  EmrDischarged,
  EmrInProcess,
  EmrPerson,
  EmrRoomCard,
  SiStar,
} from "@medstonetech/slate-icons";
import {
  InProcessOptionsRadio,
  InProcessOptionsRadioItem,
} from "../InProcessOptionsRadio";

import { FaqButton } from "modules/faq/components";
import { InProcessSortOptions } from "modules/in-process/api/queries/useInProcessEncounters";
import {
  Bus,
  FootSteps,
  Hand,
  OpenCharts,
  PersonRunning,
  RequestDisposition,
} from "icons";
import RoomManagement from "modules/room-management";
import { useInProcessCounters } from "modules/in-process/api";
import { useNavigate } from "react-router-dom";
import { useToast } from "hooks";

type InProcessSidebarProps = {
  didMainFilterChange?: (valueFilter: InProcessSortOptions) => void;
  mainFilter?: InProcessSortOptions;
  didDispositionFilterChange?: (valueFilter: InProcessSortOptions) => void;
  dispositionFilter?: InProcessSortOptions;
};

const RoomManagementButton = () => {
  const roomManagementDisclosure = useDisclosure();

  return (
    <>
      <Button
        variant="outlineSquared"
        width="100%"
        mt="53px"
        onClick={roomManagementDisclosure.onOpen}
      >
        <Icon as={EmrRoomCard} mr={2} h="24px" w="24px" color="blue" />
        Room Management
      </Button>
      <RoomManagement {...roomManagementDisclosure} />
    </>
  );
};

function InProcessSidebar(props: InProcessSidebarProps) {
  const {
    mainFilter = "room",
    didMainFilterChange,
    dispositionFilter = "createdAt",
    didDispositionFilterChange,
  } = props;
  const toast = useToast();
  const { data, error } = useInProcessCounters();
  const navigate = useNavigate();

  const [internalFilter, setInternalFilter] =
    useControllableState<InProcessSortOptions>({
      value: mainFilter,
      onChange: didMainFilterChange,
    });

  const [internalDispositionFilter, setInternalDispositionFilter] =
    useControllableState<InProcessSortOptions>({
      value: dispositionFilter,
      onChange: didDispositionFilterChange,
    });

  React.useEffect(() => {
    if (error) {
      toast({
        description: error.message,
      });
    }
  }, [toast, error]);

  const NAVIGATION_ITEMS = [
    {
      to: `/in-process/all-current-patients`,
      label: "All Current Patients",
      labelIcon: <Icon as={EmrPerson} color="blue" />,
      resultCount: data?.data.allCurrentPatient || 0,
    },
    {
      to: `/in-process/in-lobby`,
      label: "Lobby",
      labelIcon: <Icon as={SiStar} color="mint" />,
      resultCount: data?.data.lobby || 0,
      ml: "36px",
    },
    {
      to: `/in-process/in-process`,
      label: "In Process",
      labelIcon: <Icon as={EmrInProcess} color="blue" />,
      resultCount: data?.data.inProcess || 0,
      ml: "36px",
    },
    {
      to: `/in-process/observation`,
      label: "Observation",
      labelIcon: <Icon as={EmrBed} color="purple" />,
      resultCount: data?.data.observation || 0,
      ml: "36px",
    },
    {
      to: `/in-process/request-disposition`,
      label: "Request Disposition",
      labelIcon: <Icon as={RequestDisposition} color="orange" />,
      resultCount: data?.data.requestDisposition || 0,
    },
  ];

  const DISCHARGED_SUBSTATUS_ITEMS = [
    {
      to: `/in-process/dispositioned`,
      label: "Dispositioned",
      disabled: true,
      labelIcon: <Icon as={RequestDisposition} color="gray.600" />,
      resultCount: data?.data.dispositioned || 0,
    },
    {
      to: `/in-process/discharged`,
      label: "Discharged",
      resultCount: data?.data.discharged || 0,
      labelIcon: <Icon as={EmrDischarged} color="green" />,
      ml: "36px",
    },
    {
      to: `/in-process/transferred`,
      label: "Transferred",
      resultCount: data?.data.transferred || 0,
      labelIcon: <Icon as={Bus} color="yellow" />,
      ml: "36px",
    },
    {
      to: `/in-process/lpt`,
      label: "LPT",
      resultCount: data?.data.lpt || 0,
      labelIcon: <Icon as={PersonRunning} color="orange" />,
      ml: "36px",
    },
    {
      to: `/in-process/ama`,
      label: "AMA",
      resultCount: data?.data.ama || 0,
      labelIcon: <Icon as={Hand} color="red" />,
      ml: "36px",
    },
    {
      to: `/in-process/eloped`,
      label: "Eloped",
      resultCount: data?.data.eloped || 0,
      labelIcon: <Icon as={FootSteps} color="gray.600" />,
      ml: "36px",
    },
  ];

  return (
    <Sidebar>
      <SidebarActions>
        <BackButton onClick={() => navigate("/home", { replace: true })}>
          Home
        </BackButton>
      </SidebarActions>
      <SidebarHeader>
        <SidebarHeading>In Process</SidebarHeading>
        <SidebarHeading
          fontWeight="500"
          color="gray.600"
          size="sm"
          m="14px 0px 4px"
        >
          <Box>
            <InProcessOptionsRadio
              width="100%"
              h="32px"
              value={internalFilter}
              onChange={setInternalFilter as (filter: string) => void}
            >
              <InProcessOptionsRadioItem value="room" flex="1">
                Room #
              </InProcessOptionsRadioItem>
              <InProcessOptionsRadioItem value="createdAt" flex="1">
                Total Time
              </InProcessOptionsRadioItem>
            </InProcessOptionsRadio>
          </Box>
        </SidebarHeading>
      </SidebarHeader>
      <SidebarContent overflow="auto">
        <SidebarNavigation>
          {NAVIGATION_ITEMS.map(({ to, label, labelIcon, resultCount, ml }) => (
            <NavigationButton to={to} key={to} sx={{ ml }}>
              <NavigationButtonContent label={label} labelIcon={labelIcon} />
              {resultCount !== undefined ? (
                <NavigationButtonIndicator>
                  {resultCount}
                </NavigationButtonIndicator>
              ) : null}
            </NavigationButton>
          ))}
        </SidebarNavigation>

        <NavigationButton
          to="/in-process/provider-open-charts"
          my="2.5rem"
          bgColor="white"
        >
          <NavigationButtonContent
            label="My Open Charts"
            labelIcon={<Icon as={OpenCharts} color="red" />}
          />
          {data?.data.providerOpenCharts !== undefined ? (
            <NavigationButtonIndicator>
              {data?.data.providerOpenCharts}
            </NavigationButtonIndicator>
          ) : null}
        </NavigationButton>

        <SidebarNavigation>
          <chakra.span
            fontWeight={600}
            fontSize="21px"
            lineHeight="25.06px"
            mb="10px"
            mt="20px"
          >
            Last 24 hours
          </chakra.span>

          <Box>
            <InProcessOptionsRadio
              width="100%"
              h="32px"
              mb="8px"
              value={internalDispositionFilter}
              onChange={
                setInternalDispositionFilter as (filter: string) => void
              }
            >
              <InProcessOptionsRadioItem value="createdAt" flex="1">
                Encounter Time
              </InProcessOptionsRadioItem>
              <InProcessOptionsRadioItem value="dispositionTime" flex="1">
                Disposition Time
              </InProcessOptionsRadioItem>
            </InProcessOptionsRadio>
          </Box>

          {DISCHARGED_SUBSTATUS_ITEMS.map(
            ({ to, label, labelIcon, resultCount, ...rest }) => (
              <NavigationButton to={to} key={to} {...rest}>
                <NavigationButtonContent label={label} labelIcon={labelIcon} />
                {resultCount !== undefined ? (
                  <NavigationButtonIndicator>
                    {resultCount}
                  </NavigationButtonIndicator>
                ) : null}
              </NavigationButton>
            )
          )}
        </SidebarNavigation>
        <RoomManagementButton />
      </SidebarContent>
      <SidebarBottom>
        <FaqButton />
        <SidebarUserView />
      </SidebarBottom>
    </Sidebar>
  );
}

export { InProcessSidebar };
