import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared";
import { ProcedureSectionProps } from "../procedures/types";

function S014Procedure(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}_Q014.${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <ChartSubsection
      header={
        <ChartSubsectionHeader
          showNotesBtn
          showDeleteBtn
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionCode}
          showInteractions={false}
          subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q014`}
          subsectionLabel="LP"
          onDeleteSubSection={() =>
            onRemoveElement(
              `${sectionId}_${sectionCode}_${sectionSubCode}_Q014`
            )
          }
        >
          LP
        </ChartSubsectionHeader>
      }
      content={
        <ChartSubsectionContent>
          <ChartRow>
            <ChartRowElement
              label="discussed risks, benefits, options;"
              name={`${sectionDelimiter}Q014A001`}
              type="option"
            />
            <ChartRowElement
              label="patient"
              name={`${sectionDelimiter}Q014A002`}
              type="option"
            />
            <ChartRowElement
              label="parent"
              name={`${sectionDelimiter}Q014A003`}
              type="option"
            />
            <ChartRowElement
              label="guardian consents"
              name={`${sectionDelimiter}Q014A004`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A005`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="date & time:"
              name={`${sectionDelimiter}Q014A006`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A007`}
              type="input"
              w="500px"
            />
            <ChartRowElement
              label="open press ="
              fontStyle="italic"
              name={`${sectionDelimiter}Q014A008`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A009`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="sitting"
              name={`${sectionDelimiter}Q014A010`}
              type="option"
            />
            <ChartRowElement
              label="lying"
              name={`${sectionDelimiter}Q014A011`}
              type="option"
            />
            <ChartRowElement
              label="L"
              name={`${sectionDelimiter}Q014A012`}
              type="option"
            />
            <ChartRowElement
              label="R"
              name={`${sectionDelimiter}Q014A013`}
              type="option"
            />
            <ChartRowElement
              label="sterile technique"
              name={`${sectionDelimiter}Q014A014`}
              type="option"
            />
            <ChartRowElement
              label="L3-4"
              name={`${sectionDelimiter}Q014A015`}
              type="option"
            />
            <ChartRowElement
              label="L4-5"
              name={`${sectionDelimiter}Q014A016`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A017`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="fluid color"
              name={`${sectionDelimiter}Q014A018`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A019`}
              type="input"
              w="500px"
            />
            <ChartRowElement
              label="glucose"
              name={`${sectionDelimiter}Q014A020`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A021`}
              type="input"
              w="500px"
            />
            <ChartRowElement
              label="protein"
              name={`${sectionDelimiter}Q014A022`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A023`}
              type="input"
              w="500px"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A024`}
              type="input"
            />
          </ChartRow>
          <ChartRow>
            <ChartRowElement
              label="RBC"
              name={`${sectionDelimiter}Q014A025`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A026`}
              type="input"
              w="200px"
            />
            <ChartRowElement
              label="WBC"
              name={`${sectionDelimiter}Q014A027`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A028`}
              type="input"
              w="200px"
            />
            <ChartRowElement
              label="polys"
              name={`${sectionDelimiter}Q014A029`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A030`}
              type="input"
              w="200px"
            />
            <ChartRowElement
              label="monos"
              name={`${sectionDelimiter}Q014A031`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A032`}
              type="input"
              w="200px"
            />
            <ChartRowElement
              label="lymphs"
              name={`${sectionDelimiter}Q014A033`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A034`}
              type="input"
              w="200px"
            />
            <ChartRowElement
              label="gm stn"
              name={`${sectionDelimiter}Q014A035`}
              type="option"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A036`}
              type="input"
              w="200px"
            />
            <ChartRowElement
              name={`${sectionDelimiter}Q014A037`}
              type="input"
            />
          </ChartRow>

          <ChartRow>
            <ChartRowElement
              name={`${sectionDelimiter}Q014A038`}
              type="input"
            />
          </ChartRow>
        </ChartSubsectionContent>
      }
    />
  );
}

export { S014Procedure };
