import { axiosOrderInstance } from "config/axios";
import { OrderStatus } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type useChangeOrderStatusPayload = {
  status: OrderStatus;
};

function useChangeOrderStatus(orderId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateOrderStatus", { orderId, encounterId }],
    (payload: useChangeOrderStatusPayload) =>
      axiosOrderInstance.put(`v1/${orderId}/status`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries(["orderHistory", { encounterId }]);
        queryClient.invalidateQueries([
          "orderHistoryPagination",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "orderHistoryInfinite",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "ivAndMedicationCounters",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["medsAndIvCounters", { encounterId }]);
        queryClient.invalidateQueries(["treatmentsCounters", { encounterId }]);
        queryClient.invalidateQueries([
          "encounterOrderCounters",
          { encounterId },
        ]);
      },
    }
  );
}

export { useChangeOrderStatus };
