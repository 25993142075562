import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  mapFormToPayload,
  mapPayloadToFormObject,
  useSaveAndNext,
} from "modules/charts-shared";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box, Icon, useToast } from "@chakra-ui/react";
import { useMdm, useUpdateMdm } from "../api";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrAssessments } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useChartTrackingContext } from "contexts";
import { useParams } from "react-router-dom";

const sectionId = "SMDM";
const commonChartCode = "C003x";
const MDM_GRID_COLUMN = "6rem 1fr";

function MDM(props: { chartCode: string; isReadOnly?: boolean }) {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { chartCode, isReadOnly } = props;

  const { trackChartInteractions, isLoading: isTrackingInteractions } =
    useChartTrackingContext();

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useMdm(encounterId);
  const { mutateAsync: updateMdm, isLoading: updateIsLoading } =
    useUpdateMdm(encounterId);
  const toast = useToast();
  const formObject = getValues();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateMdm({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            sectionId,
            chartType: "shared",
          }
        );

        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("MDM") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateMdm,
      getValues,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "mdm",
    "provider"
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateMdm({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });

      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          sectionId,
          chartType: "shared",
        }
      );

      toast({ description: formMessages.updateSuccess("MDM") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingInteractions}
        disabled={isReadOnly}
      >
        <SharedChartSectionHeader
          icon={<Icon as={EmrAssessments} />}
          encounterId={encounterId}
          sectionId={sectionId}
          chartCode={commonChartCode}
          showActionsBtns
          isLoading={updateIsLoading || isTrackingInteractions}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          MDM {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={sectionId}
              subsectionCode="Q001"
              subsectionLabel="Testing based on Chief Complaint/HPI/ROS"
            >
              Testing based on Chief Complaint/HPI/ROS
            </SharedChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Flu"
                    name={`Q001.${commonChartCode}${sectionId}Q001A001`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Strep"
                    name={`Q001.${commonChartCode}${sectionId}Q001A002`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="RSV"
                    name={`Q001.${commonChartCode}${sectionId}Q001A003`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Covid"
                    name={`Q001.${commonChartCode}${sectionId}Q001A004`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mono"
                    name={`Q001.${commonChartCode}${sectionId}Q001A005`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Respiratory Infection Source:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A006`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A007`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A008`}
                    label="Troponin BNP to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute Myocardial Infarction"
                    name={`Q001.${commonChartCode}${sectionId}Q001A009`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Ischemia"
                    name={`Q001.${commonChartCode}${sectionId}Q001A010`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute CHF Exacerbation"
                    name={`Q001.${commonChartCode}${sectionId}Q001A011`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A012`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Complete Blood Count to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A013`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A014`}
                    label="Infection"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Sepsis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A015`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Anemia"
                    name={`Q001.${commonChartCode}${sectionId}Q001A016`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bleeding"
                    name={`Q001.${commonChartCode}${sectionId}Q001A017`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A018`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Chemistries to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A019`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A020`}
                    label="Acute Renal Failure"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dehydration"
                    name={`Q001.${commonChartCode}${sectionId}Q001A021`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Hyperkalemia/Hypokalemia"
                    name={`Q001.${commonChartCode}${sectionId}Q001A022`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Rhabdomyolysis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A023`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="DKA"
                    name={`Q001.${commonChartCode}${sectionId}Q001A024`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Hyperglycemia"
                    name={`Q001.${commonChartCode}${sectionId}Q001A025`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A026`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Liver Function Test (LFTs) to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A027`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A028`}
                    label="Liver Function"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Hepatitis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A029`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Cholecystitis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A030`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A031`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Lipase/Amylase to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A032`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A033`}
                    label="Pancreatitis"
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A034`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Urinalysis (UA) to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A035`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A036`}
                    label="Dehydration"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Infection (UTI)"
                    name={`Q001.${commonChartCode}${sectionId}Q001A037`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Proteinuria"
                    name={`Q001.${commonChartCode}${sectionId}Q001A038`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Hematuria"
                    name={`Q001.${commonChartCode}${sectionId}Q001A039`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A040`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Urine/Serum"
                    name={`Q001.${commonChartCode}${sectionId}Q001A041`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Pregnancy Test"
                    name={`Q001.${commonChartCode}${sectionId}Q001A042`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A043`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Pregnancy"
                    name={`Q001.${commonChartCode}${sectionId}Q001A214`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Ectopic Pregnancy"
                    name={`Q001.${commonChartCode}${sectionId}Q001A044`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A045`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="ESR"
                    name={`Q001.${commonChartCode}${sectionId}Q001A046`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="CRP"
                    name={`Q001.${commonChartCode}${sectionId}Q001A047`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A048`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Severe Sepsis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A049`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Shock "
                    name={`Q001.${commonChartCode}${sectionId}Q001A050`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acidemia "
                    name={`Q001.${commonChartCode}${sectionId}Q001A051`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A052`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Cultures:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A053`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Blood"
                    name={`Q001.${commonChartCode}${sectionId}Q001A054`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A055`}
                    label="Throat"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Urine"
                    name={`Q001.${commonChartCode}${sectionId}Q001A056`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Wound"
                    name={`Q001.${commonChartCode}${sectionId}Q001A057`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A058`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Sepsis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A059`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bacteremia"
                    name={`Q001.${commonChartCode}${sectionId}Q001A060`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Sensitivities/Antibiotic resistance"
                    name={`Q001.${commonChartCode}${sectionId}Q001A061`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A062`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="ProCal"
                    name={`Q001.${commonChartCode}${sectionId}Q001A063`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Lactate"
                    name={`Q001.${commonChartCode}${sectionId}Q001A064`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A065`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Severe Sepsis/Shock"
                    name={`Q001.${commonChartCode}${sectionId}Q001A066`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acidemia"
                    name={`Q001.${commonChartCode}${sectionId}Q001A067`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A068`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Fibrinogen"
                    name={`Q001.${commonChartCode}${sectionId}Q001A069`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="D-Dimer"
                    name={`Q001.${commonChartCode}${sectionId}Q001A070`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A071`}
                    label="PT/INR"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A072`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute Pulmonary Embolism"
                    name={`Q001.${commonChartCode}${sectionId}Q001A073`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Coagulopathy"
                    name={`Q001.${commonChartCode}${sectionId}Q001A074`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Disseminated Intravascular Coagulation"
                    name={`Q001.${commonChartCode}${sectionId}Q001A075`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A076`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="EKG(s) to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A077`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A078`}
                    label="Ischemia"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dysrhythmias"
                    name={`Q001.${commonChartCode}${sectionId}Q001A079`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Intervals"
                    name={`Q001.${commonChartCode}${sectionId}Q001A080`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dynamic Changes"
                    name={`Q001.${commonChartCode}${sectionId}Q001A081`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Medication Safety"
                    name={`Q001.${commonChartCode}${sectionId}Q001A082`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute PE"
                    name={`Q001.${commonChartCode}${sectionId}Q001A083`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Electrolyte Abnormalities"
                    name={`Q001.${commonChartCode}${sectionId}Q001A084`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A085`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Send Out Lab"
                    name={`Q001.${commonChartCode}${sectionId}Q001A086`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A087`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A088`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A089`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Send Out Lab"
                    name={`Q001.${commonChartCode}${sectionId}Q001A090`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A091`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A092`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A093`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Send Out Lab"
                    name={`Q001.${commonChartCode}${sectionId}Q001A094`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A095`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A096`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A097`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Send Out Lab"
                    name={`Q001.${commonChartCode}${sectionId}Q001A098`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A099`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A100`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A101`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CXR to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A102`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="PNA"
                    name={`Q001.${commonChartCode}${sectionId}Q001A103`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="PTX"
                    name={`Q001.${commonChartCode}${sectionId}Q001A104`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="CM"
                    name={`Q001.${commonChartCode}${sectionId}Q001A105`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Effusion"
                    name={`Q001.${commonChartCode}${sectionId}Q001A106`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bronchitis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A107`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Fractures"
                    name={`Q001.${commonChartCode}${sectionId}Q001A108`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="ARDS"
                    name={`Q001.${commonChartCode}${sectionId}Q001A109`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Severe Asthma Exacerbation"
                    name={`Q001.${commonChartCode}${sectionId}Q001A110`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A111`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="X-Ray"
                    name={`Q001.${commonChartCode}${sectionId}Q001A112`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A113`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A114`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Fracture"
                    name={`Q001.${commonChartCode}${sectionId}Q001A115`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dislocation"
                    name={`Q001.${commonChartCode}${sectionId}Q001A116`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Foreign Body"
                    name={`Q001.${commonChartCode}${sectionId}Q001A117`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Complicated Fractures (Open/Compound)"
                    name={`Q001.${commonChartCode}${sectionId}Q001A118`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A119`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT Head to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A120`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Stroke (CVA)"
                    name={`Q001.${commonChartCode}${sectionId}Q001A121`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A122`}
                    label="Traumatic Brain Injury"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Meningitis/Encephalitis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A123`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mass"
                    name={`Q001.${commonChartCode}${sectionId}Q001A124`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bleeding"
                    name={`Q001.${commonChartCode}${sectionId}Q001A125`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A126`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT"
                    name={`Q001.${commonChartCode}${sectionId}Q001A127`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A128`}
                    label="C"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="T"
                    name={`Q001.${commonChartCode}${sectionId}Q001A129`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="L"
                    name={`Q001.${commonChartCode}${sectionId}Q001A130`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Spine"
                    name={`Q001.${commonChartCode}${sectionId}Q001A131`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A132`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Fracture"
                    name={`Q001.${commonChartCode}${sectionId}Q001A133`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute Spinal Stenosis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A134`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Herniated Disc"
                    name={`Q001.${commonChartCode}${sectionId}Q001A135`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mass"
                    name={`Q001.${commonChartCode}${sectionId}Q001A136`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A137`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT"
                    name={`Q001.${commonChartCode}${sectionId}Q001A138`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Chest"
                    name={`Q001.${commonChartCode}${sectionId}Q001A139`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A140`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="PE"
                    name={`Q001.${commonChartCode}${sectionId}Q001A141`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="COVID PNA"
                    name={`Q001.${commonChartCode}${sectionId}Q001A142`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A143`}
                    label="Effusion"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Aortic Pathology"
                    name={`Q001.${commonChartCode}${sectionId}Q001A144`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A145`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT Abdomen/Pelvis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A146`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A147`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A148`}
                    label="Appendicitis"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abscess"
                    name={`Q001.${commonChartCode}${sectionId}Q001A149`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Perforation"
                    name={`Q001.${commonChartCode}${sectionId}Q001A150`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Obstruction"
                    name={`Q001.${commonChartCode}${sectionId}Q001A151`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Kidney Stone"
                    name={`Q001.${commonChartCode}${sectionId}Q001A152`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Gallstones"
                    name={`Q001.${commonChartCode}${sectionId}Q001A153`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Diverticulitis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A154`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="GI Bleeding"
                    name={`Q001.${commonChartCode}${sectionId}Q001A155`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Acute Abdomen"
                    name={`Q001.${commonChartCode}${sectionId}Q001A156`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A157`}
                    w="158px"
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="CT"
                    name={`Q001.${commonChartCode}${sectionId}Q001A158`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A159`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A160`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A161`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="US EXtremity"
                    name={`Q001.${commonChartCode}${sectionId}Q001A162`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bilateral"
                    name={`Q001.${commonChartCode}${sectionId}Q001A163`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A164`}
                    label="L"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="R"
                    name={`Q001.${commonChartCode}${sectionId}Q001A165`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Upper"
                    name={`Q001.${commonChartCode}${sectionId}Q001A166`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Lower"
                    name={`Q001.${commonChartCode}${sectionId}Q001A167`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A168`}
                    w="200px"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A169`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="DVT"
                    name={`Q001.${commonChartCode}${sectionId}Q001A171`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Peripheral Vascular Disease"
                    name={`Q001.${commonChartCode}${sectionId}Q001A172`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Foreign Body"
                    name={`Q001.${commonChartCode}${sectionId}Q001A173`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abscess"
                    name={`Q001.${commonChartCode}${sectionId}Q001A174`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A175`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="US"
                    name={`Q001.${commonChartCode}${sectionId}Q001A176`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abdomen"
                    name={`Q001.${commonChartCode}${sectionId}Q001A177`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Pelvic"
                    name={`Q001.${commonChartCode}${sectionId}Q001A178`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="RUQ"
                    name={`Q001.${commonChartCode}${sectionId}Q001A179`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="RLQ"
                    name={`Q001.${commonChartCode}${sectionId}Q001A180`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A181`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Gallstones"
                    name={`Q001.${commonChartCode}${sectionId}Q001A182`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Appendicitis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A183`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="IUP"
                    name={`Q001.${commonChartCode}${sectionId}Q001A184`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abscess"
                    name={`Q001.${commonChartCode}${sectionId}Q001A185`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Intussusception"
                    name={`Q001.${commonChartCode}${sectionId}Q001A186`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Torsion"
                    name={`Q001.${commonChartCode}${sectionId}Q001A187`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Pyloric Stenosis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A188`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Kidney Stone"
                    name={`Q001.${commonChartCode}${sectionId}Q001A189`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A190`}
                    w="208px"
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="MRI"
                    name={`Q001.${commonChartCode}${sectionId}Q001A191`}
                  />
                  <ChartRowElement
                    type="input"
                    size="md"
                    name={`Q001.${commonChartCode}${sectionId}Q001A192`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    name={`Q001.${commonChartCode}${sectionId}Q001A193`}
                    label="to evaluate for:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Fracture"
                    name={`Q001.${commonChartCode}${sectionId}Q001A194`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Dislocation"
                    name={`Q001.${commonChartCode}${sectionId}Q001A195`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Osteomyelitis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A196`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Septic Arthritis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A197`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Avascular Necrosis"
                    name={`Q001.${commonChartCode}${sectionId}Q001A198`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mass"
                    name={`Q001.${commonChartCode}${sectionId}Q001A199`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A200`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="MRI"
                    name={`Q001.${commonChartCode}${sectionId}Q001A201`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Brain"
                    name={`Q001.${commonChartCode}${sectionId}Q001A202`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Spine"
                    name={`Q001.${commonChartCode}${sectionId}Q001A203`}
                  />
                  <ChartRowElement
                    type="input"
                    w="214px"
                    name={`Q001.${commonChartCode}${sectionId}Q001A204`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="to evaluate for:"
                    name={`Q001.${commonChartCode}${sectionId}Q001A205`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="CVA"
                    name={`Q001.${commonChartCode}${sectionId}Q001A206`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Abscess"
                    name={`Q001.${commonChartCode}${sectionId}Q001A207`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Cord Compression"
                    name={`Q001.${commonChartCode}${sectionId}Q001A208`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Cauda Equina Syndrome"
                    name={`Q001.${commonChartCode}${sectionId}Q001A209`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Traumatic Brain Injury"
                    name={`Q001.${commonChartCode}${sectionId}Q001A210`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mass"
                    name={`Q001.${commonChartCode}${sectionId}Q001A211`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="SCIWORA"
                    name={`Q001.${commonChartCode}${sectionId}Q001A212`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q001.${commonChartCode}${sectionId}Q001A213`}
                    w="70px"
                  />
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={sectionId}
              subsectionCode="Q002"
              subsectionLabel="Treatments"
            >
              Treatments
            </SharedChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="IV / IVF for:"
                    name={`Q002.${commonChartCode}${sectionId}Q002A001`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q002.${commonChartCode}${sectionId}Q002A002`}
                  />
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="SIRS"
                      name={`Q002.${commonChartCode}${sectionId}Q002A003`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="SEPSIS"
                      name={`Q002.${commonChartCode}${sectionId}Q002A004`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Tachycardia"
                      name={`Q002.${commonChartCode}${sectionId}Q002A005`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Septic Shock"
                      name={`Q002.${commonChartCode}${sectionId}Q002A006`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Hypertension"
                      name={`Q002.${commonChartCode}${sectionId}Q002A007`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Fever Myalgias"
                      name={`Q002.${commonChartCode}${sectionId}Q002A008`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A009`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Rhabdomyolysis"
                      name={`Q002.${commonChartCode}${sectionId}Q002A010`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Severe Nausea"
                      name={`Q002.${commonChartCode}${sectionId}Q002A011`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Continued Vomiting"
                      name={`Q002.${commonChartCode}${sectionId}Q002A012`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="PO Intolerant"
                      name={`Q002.${commonChartCode}${sectionId}Q002A013`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A014`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Profound Diarrhea"
                      name={`Q002.${commonChartCode}${sectionId}Q002A015`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Severe Dehydration"
                      name={`Q002.${commonChartCode}${sectionId}Q002A016`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Electrolyte Imbalance"
                      name={`Q002.${commonChartCode}${sectionId}Q002A017`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Elevated BUN Creatinine"
                      name={`Q002.${commonChartCode}${sectionId}Q002A018`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Acute Renal Insufficiency"
                      name={`Q002.${commonChartCode}${sectionId}Q002A019`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A020`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Hyperglycemia DKA"
                      name={`Q002.${commonChartCode}${sectionId}Q002A021`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Dizziness/Weakness"
                      name={`Q002.${commonChartCode}${sectionId}Q002A022`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Clinically Dehydrated"
                      name={`Q002.${commonChartCode}${sectionId}Q002A023`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Dry Mucous Membranes"
                      name={`Q002.${commonChartCode}${sectionId}Q002A024`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Oliguria"
                      name={`Q002.${commonChartCode}${sectionId}Q002A025`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Concentrated Urine"
                      name={`Q002.${commonChartCode}${sectionId}Q002A026`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Increased Specific Gravity"
                      name={`Q002.${commonChartCode}${sectionId}Q002A027`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A028`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="CT Imaging with contrast"
                      name={`Q002.${commonChartCode}${sectionId}Q002A029`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Abnormal lab"
                      name={`Q002.${commonChartCode}${sectionId}Q002A030`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Hyperkalemia"
                      name={`Q002.${commonChartCode}${sectionId}Q002A031`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A032`}
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="IM / IV for aggressive treatment of:"
                    name={`Q002.${commonChartCode}${sectionId}Q002A033`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q002.${commonChartCode}${sectionId}Q002A034`}
                  />
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Severe painful conditions"
                      name={`Q002.${commonChartCode}${sectionId}Q002A035`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="SEPSIS"
                      name={`Q002.${commonChartCode}${sectionId}Q002A036`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Tachycardia"
                      name={`Q002.${commonChartCode}${sectionId}Q002A037`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Septic Shock"
                      name={`Q002.${commonChartCode}${sectionId}Q002A038`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Hypotension"
                      name={`Q002.${commonChartCode}${sectionId}Q002A039`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Fever Myalgias"
                      name={`Q002.${commonChartCode}${sectionId}Q002A040`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Infection Source:"
                      name={`Q002.${commonChartCode}${sectionId}Q002A041`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A042`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Inflammation"
                      name={`Q002.${commonChartCode}${sectionId}Q002A043`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Edema"
                      name={`Q002.${commonChartCode}${sectionId}Q002A044`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="CVA"
                      name={`Q002.${commonChartCode}${sectionId}Q002A045`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A046`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Rhabdomyolysis"
                      name={`Q002.${commonChartCode}${sectionId}Q002A047`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Severe Nausea"
                      name={`Q002.${commonChartCode}${sectionId}Q002A048`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Continued Vomiting"
                      name={`Q002.${commonChartCode}${sectionId}Q002A049`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="PO Intolerant"
                      name={`Q002.${commonChartCode}${sectionId}Q002A050`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A051`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Wheezing"
                      name={`Q002.${commonChartCode}${sectionId}Q002A052`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Severe Asthma"
                      name={`Q002.${commonChartCode}${sectionId}Q002A053`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Exacerbation"
                      name={`Q002.${commonChartCode}${sectionId}Q002A054`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Severe Allergic Reaction"
                      name={`Q002.${commonChartCode}${sectionId}Q002A055`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Anaphylaxis"
                      name={`Q002.${commonChartCode}${sectionId}Q002A056`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A057`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Hyperglycemia DKA"
                      name={`Q002.${commonChartCode}${sectionId}Q002A058`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Immunization"
                      name={`Q002.${commonChartCode}${sectionId}Q002A059`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q002.${commonChartCode}${sectionId}Q002A060`}
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={sectionId}
              subsectionCode="Q003"
              subsectionLabel="Rationale for Medical Decision Making - Risk for Mortality and Complexity"
            >
              Rationale for Medical Decision Making - Risk for Mortality and
              Complexity
            </SharedChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Each Nebulizer medication given for:"
                    name={`Q003.${commonChartCode}${sectionId}Q003A001`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Wheezing"
                    name={`Q003.${commonChartCode}${sectionId}Q003A002`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Bronchospasm"
                    name={`Q003.${commonChartCode}${sectionId}Q003A003`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Severe Cough"
                    name={`Q003.${commonChartCode}${sectionId}Q003A004`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Throat Pain"
                    name={`Q003.${commonChartCode}${sectionId}Q003A005`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="SOB"
                    name={`Q003.${commonChartCode}${sectionId}Q003A006`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Inflammation"
                    name={`Q003.${commonChartCode}${sectionId}Q003A007`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="Mucous Plugging"
                    name={`Q003.${commonChartCode}${sectionId}Q003A008`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A009`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Narcotic Pain Medication Needed for Severe Pain Control"
                    name={`Q003.${commonChartCode}${sectionId}Q003A010`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A011`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Outpatient Prescription Drug Management Prescribed"
                    name={`Q003.${commonChartCode}${sectionId}Q003A012`}
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="see discharge summary"
                    name={`Q003.${commonChartCode}${sectionId}Q003A013`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A014`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="State of Texas PMP Database Reviewed prior to prescriptions sent to pharmacy. No previous overdose documented."
                    name={`Q003.${commonChartCode}${sectionId}Q003A015`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A016`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <Box display="flex" justifyContent="space-between" gap={2}>
                    <ChartRowElement
                      type="cross-option"
                      label="Reviewed Prior Medical Records to Find:"
                      name={`Q003.${commonChartCode}${sectionId}Q003A017`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q003.${commonChartCode}${sectionId}Q003A018`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Medical records from other facility"
                      name={`Q003.${commonChartCode}${sectionId}Q003A019`}
                    />
                  </Box>
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Consideration for Observation or Admission Patient at increased risk and requires monitoring (e.g., Cardiac monitor/continuous pulse ox, serial testing, serial examinations, and abnormal side effects)."
                    name={`Q003.${commonChartCode}${sectionId}Q003A020`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A021`}
                    w="130px"
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    label="*"
                    type="label"
                    pr="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    pl="0px"
                    type="cross-option"
                    label="Some of the Differential Diagnosis that were ruled out or considered are back-slashed/crossed off impressions - DDX, testing and treatments reflect the Risk of Complications:"
                    name={`Q003.${commonChartCode}${sectionId}Q003A022`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A023`}
                  />
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Low"
                      name={`Q003.${commonChartCode}${sectionId}Q003A024`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Moderate"
                      name={`Q003.${commonChartCode}${sectionId}Q003A025`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="High"
                      name={`Q003.${commonChartCode}${sectionId}Q003A026`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q003.${commonChartCode}${sectionId}Q003A027`}
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Specialist Consultation:"
                    name={`Q003.${commonChartCode}${sectionId}Q003A028`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A029`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Diagnosis or Treatment Significantly Limited or Altered by SDOH (Social Determinants of Health):"
                    name={`Q003.${commonChartCode}${sectionId}Q003A030`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A031`}
                  />
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="No 24-hour pharmacy"
                      name={`Q003.${commonChartCode}${sectionId}Q003A032`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="PCP/Specialist or outpatient access limited"
                      name={`Q003.${commonChartCode}${sectionId}Q003A033`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Food or housing insecurity"
                      name={`Q003.${commonChartCode}${sectionId}Q003A034`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Medical or English literacy"
                      name={`Q003.${commonChartCode}${sectionId}Q003A035`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Access to fair insurance"
                      name={`Q003.${commonChartCode}${sectionId}Q003A036`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q003.${commonChartCode}${sectionId}Q003A037`}
                    />
                  </ChartRowGroup>
                </ChartRow>
                <ChartRow gridTemplateColumns={MDM_GRID_COLUMN}>
                  <ChartRowGroup />
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="Unemployment"
                      name={`Q003.${commonChartCode}${sectionId}Q003A038`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Mental health/substance abuse limitations"
                      name={`Q003.${commonChartCode}${sectionId}Q003A039`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Poverty"
                      name={`Q003.${commonChartCode}${sectionId}Q003A040`}
                    />
                    <ChartRowElement
                      type="cross-option"
                      label="Other:"
                      name={`Q003.${commonChartCode}${sectionId}Q003A041`}
                    />
                    <ChartRowElement
                      type="input"
                      name={`Q003.${commonChartCode}${sectionId}Q003A042`}
                    />
                  </ChartRowGroup>
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="Severe Exacerbation of Chronic Disease Requires admission/observation/close follow up"
                    name={`Q003.${commonChartCode}${sectionId}Q003A043`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A044`}
                  />
                </ChartRow>
              </ChartSubsectionContent>

              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="I spent"
                    name={`Q003.${commonChartCode}${sectionId}Q003A045`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A046`}
                    w="120px"
                  />
                  <ChartRowElement
                    type="cross-option"
                    label="minutes of Critical Care Time: In combined documentation, direct care, consultation, and speaking with the family in regards to this patient's critical emergency exclusive of procedure."
                    name={`Q003.${commonChartCode}${sectionId}Q003A047`}
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    label="See Progress Notes for details."
                    name={`Q003.${commonChartCode}${sectionId}Q003A048`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`Q003.${commonChartCode}${sectionId}Q003A049`}
                  />
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { MDM };
